/* eslint-disable no-unused-vars */
import React, {
  useState, useContext
} from "react";
import { Link, NavLink } from "react-router-dom";
import { ReactComponent as SvgLogo } from "../assets/BooksPie-short.svg";
import { ReactComponent as Seller } from "../assets/icons/noun-shop-owner-3004489.svg";
import { MdOutlineAddComment, MdOutlineCancel } from "react-icons/md";
import { IoMdArrowDropright, IoMdArrowDropdown } from "react-icons/io";

import {
  BsBoxSeamFill,
  BsArrowReturnRight,
  BsCashCoin,
} from "react-icons/bs";
import { BiCategoryAlt } from "react-icons/bi";
import { RiMailSendLine, RiCustomerService2Fill } from "react-icons/ri";
import { TfiBlackboard } from "react-icons/tfi";
import { GiWhiteBook, GiBookCover } from "react-icons/gi";
import {
  MdOutlineLocalOffer,
  MdOutlineStarRate,
  MdOutlineSupervisorAccount,
} from "react-icons/md";
import { AiOutlineNotification, AiOutlineShoppingCart, AiOutlineUsergroupDelete } from "react-icons/ai";
import { TbLayoutDashboard, TbReportMoney, TbTruckDelivery } from "react-icons/tb";

// import { links } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import { AuthContext } from "../contexts/Auth.Context";
import { FaFileInvoiceDollar, FaUsers } from "react-icons/fa";
import { GrCatalog, GrTransaction } from "react-icons/gr";
const links = [
  {
    title: "Dashboard",
    links: [
      {
        name: "dashboard",
        icon: <TbLayoutDashboard />,
      },
    ],
  },
  
  {
    title: "Pages",
    links: [
      {
        name: "orders",
        icon: <AiOutlineShoppingCart />,
        submenu: [
          {
            submenuName: "pending orders",
            submenuIcon: <BsArrowReturnRight />,
          },
          {
            submenuName: "delivered orders",
            submenuIcon: <BsArrowReturnRight />,
          },
          {
            submenuName: "processing orders",
            submenuIcon: <BsArrowReturnRight />,
          },
          {
            submenuName: "shipped orders",
            submenuIcon: <BsArrowReturnRight />,
          },
          {
            submenuName: "cancelled orders",
            submenuIcon: <BsArrowReturnRight />,
          },
          {
            submenuName: "not confirmed orders",
            submenuIcon: <BsArrowReturnRight />,
          },
        ],
      },
      {
        name: "new books",
        icon: <GiWhiteBook />,
      },
      {
        name: "old books",
        icon: <GiBookCover />,
      },
      {
        name: "customers support",
        icon: <RiCustomerService2Fill />,
      },
      {
        name: "request book",
        icon: <MdOutlineAddComment />,
      },
      {
        name: "flashSale",
        icon: <MdOutlineLocalOffer />,
      },
      {
        name: "top rated",
        icon: <MdOutlineStarRate />,
      },
      {
        name: "notification",
        icon: <AiOutlineNotification />,
      },
      {
        name: "revenue report",
        icon: <TbReportMoney />,
      },
      {
        name: "profit report",
        icon: <FaFileInvoiceDollar />,
      },
      {
        name: "coupons",
        icon: <BsCashCoin />,
      },
      {
        name: "send email",
        icon: <RiMailSendLine />,
      },
      {
        name: "banners",
        icon: <TfiBlackboard />,
      },
       {
         name: "delivery charges",
         icon: <TbTruckDelivery />,
       },
       {
         name: "sellers",
         icon: <Seller />,
        },
       {
         name: "customers",
         icon: <FaUsers />,
        },
       {
         name: "deleted customers",
         icon: <AiOutlineUsergroupDelete />,
        },
        {
          name: "categories",
          icon: <BiCategoryAlt />,
        },
        {
          name: "book catalog",
          icon: <GrCatalog />,
        },
        {
          name: "api logs",
          icon: <GrTransaction />,
        },   
      
    ],
  },
];

const Sidebar = () => {
  const { currentColor, activeMenu, setActiveMenu, screenSize } =
    useStateContext();
    const { state: ContextState, logout } = useContext(AuthContext);

    const {  user } = ContextState;
    const signOut = () => {
      // console.log("signout called");
      logout(user);
    }
  const [isOpen, setOpen] = useState(false);

  const toggleDropdown = () => setOpen(!isOpen);

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";

  const activeLinkSubMenu =
    "flex items-center gap-5 pl-8 pt-3 pb-2.5 rounded-lg  text-white  text-sm m-1";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";
  const subLink =
    "flex items-center gap-5 pl-8 pt-3 pb-2.5 rounded-lg text-sm text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-1";

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-0">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/"
              onClick={handleCloseSideBar}
              className="items-center gap-3 ml-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <div className="flex justify-center items-center gap-2">
                <div>
                  <SvgLogo width={45} height={45} className="m-0" />
                  {/*<img fill={currentColor} src={Logo} width={30} alt="..." />*/}
                </div>
                <div
                  style={{
                    color: currentColor,
                  }}
                  className="font-extrabold text-2xl"
                >
                  BooksPie
                </div>
              </div>
            </Link>
           
              <button
                type="button"
                onClick={() => setActiveMenu(!activeMenu)}
                style={{ color: currentColor }}
                className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
              >
                <MdOutlineCancel />
              </button>
            
          </div>
          <div className="mt-5 flex flex-col justify-between">
            <div className="overflow-y-auto h-[calc(100vh_-_137px)]">
              {links.map((item, index) => (
                <div key={index}>
                  <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                    {item.title}
                  </p>

                  {item.links.map((link, index) => {
                    const linkUrl = link.name.replace(/ /g, "");
                    return link.submenu ? (
                      <div
                        key={index}
                        className={`m-2 rounded-md ${
                          isOpen ? " bg-slate-300 dark:bg-gray-700" : ""
                        }`}
                      >
                        <button
                          className="w-full"
                          onClick={() => toggleDropdown()}
                        >
                          <NavLink
                            to={
                              link.name === "dashboard" ? `${link.name}` : `${link.name}`
                            }
                            onClick={handleCloseSideBar}
                            style={({ isActive }) => ({
                              backgroundColor: isActive ? currentColor : "",
                              margin: "0px",
                            })}
                            className={({ isActive }) =>
                              isActive ? activeLink : normalLink
                            }
                          >
                            <span className="text-2xl">{link.icon}</span>
                            <span className="capitalize">{link.name}</span>
                            <span className="capitalize text-lg ms-auto me-2">
                              {!isOpen ? (
                                <IoMdArrowDropright />
                              ) : (
                                <IoMdArrowDropdown />
                              )}
                            </span>
                          </NavLink>
                        </button>
                        <div
                          className={`rounded-md ${
                            isOpen ? "py-1 bg-slate-200 dark:bg-gray-600" : ""
                          }`}
                        >
                          {link?.submenu?.map((link, index) => {
                            const subMenuUrl = link.submenuName.replace(
                              / /g,
                              ""
                            );
                            return (
                              isOpen && (
                                <NavLink
                                  to={subMenuUrl}
                                  key={index}
                                  onClick={handleCloseSideBar}
                                  style={({ isActive }) => ({
                                    backgroundColor: isActive
                                      ? currentColor
                                      : "",
                                  })}
                                  className={({ isActive }) =>
                                    isActive ? activeLinkSubMenu : subLink
                                  }
                                >
                                  <span className="text-2xl">
                                    {link.submenuIcon}
                                  </span>
                                  <span className="capitalize ">
                                    {link.submenuName}
                                  </span>
                                </NavLink>
                              )
                            );
                          })}
                        </div>
                      </div>
                    ) : (
                      <NavLink
                        to={link.name === "dashboard" ? `${linkUrl}` : `${linkUrl}`}
                        onClick={handleCloseSideBar}
                        key={index}
                        style={({ isActive }) => ({
                          backgroundColor: isActive ? currentColor : "",
                        })}
                        className={({ isActive }) =>
                          isActive ? activeLink : normalLink
                        }
                      >
                        <span className="text-2xl">{link.icon}</span>
                        <span className="capitalize ">{link.name}</span>
                      </NavLink>
                    );
                  })}
                </div>
              ))}
            </div>
            <div className="p-2 px-5 ">
              <button
                style={{
                  background: currentColor,
                }}
                onClick={signOut}
                className="w-full hover:opacity-80 p-2 rounded-lg text-white"
              >
                LogOut
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
/*










<ul className="space-y-2 font-medium">
                  <li>
                    <a
                      href="/"
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <svg
                        aria-hidden="true"
                        className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                        <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                      </svg>
                      <span className="ml-3">Dashboard</span>
                    </a>
                  </li>
                  <li>
                    <button
                      type="button"
                      className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                      aria-controls="dropdown-example"
                      data-collapse-toggle="dropdown-example"
                    >
                      <svg
                        aria-hidden="true"
                        className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span
                        className="flex-1 ml-3 text-left whitespace-nowrap"
                        sidebar-toggle-item
                      >
                        E-commerce
                      </span>
                      <svg
                        sidebar-toggle-item
                        className="w-6 h-6"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                    <ul id="dropdown-example" className="hidden py-2 space-y-2">
                      <li>
                        <a
                          href="/"
                          className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        >
                          Products
                        </a>
                      </li>
                      <li>
                        <a
                          href="/"
                          className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        >
                          Billing
                        </a>
                      </li>
                      <li>
                        <a
                          href="/"
                          className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-11 group hover:bg-gray-100 dark:text-white dark:hover:bg-gray-700"
                        >
                          Invoice
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <svg
                        aria-hidden="true"
                        className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                      </svg>
                      <span className="flex-1 ml-3 whitespace-nowrap">Kanban</span>
                      <span className="inline-flex items-center justify-center px-2 ml-3 text-sm font-medium text-gray-800 bg-gray-200 rounded-full dark:bg-gray-700 dark:text-gray-300">
                        Pro
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <svg
                        aria-hidden="true"
                        className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
                        <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
                      </svg>
                      <span className="flex-1 ml-3 whitespace-nowrap">Inbox</span>
                      <span className="inline-flex items-center justify-center w-3 h-3 p-3 ml-3 text-sm font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300">
                        3
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <svg
                        aria-hidden="true"
                        className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="flex-1 ml-3 whitespace-nowrap">Users</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <svg
                        aria-hidden="true"
                        className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="flex-1 ml-3 whitespace-nowrap">
                        Products
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <svg
                        aria-hidden="true"
                        className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="flex-1 ml-3 whitespace-nowrap">Sign In</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="/"
                      className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-gray-100 dark:hover:bg-gray-700"
                    >
                      <svg
                        aria-hidden="true"
                        className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5 4a3 3 0 00-3 3v6a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H5zm-1 9v-1h5v2H5a1 1 0 01-1-1zm7 1h4a1 1 0 001-1v-1h-5v2zm0-4h5V8h-5v2zM9 8H4v2h5V8z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                      <span className="flex-1 ml-3 whitespace-nowrap">Sign Up</span>
                    </a>
                  </li>
                </ul>










{item.links.map((link) => (
  <div>
    <NavLink
      to={link.name === "dashboard" ? "/" : `/${link.name}`}
      key={link.name}
      onClick={handleCloseSideBar}
      style={({ isActive }) => ({
        backgroundColor: isActive ? currentColor : "",
      })}
      className={({ isActive }) =>
        isActive ? activeLink : normalLink
      }
    >
      <span className="text-2xl">{link.icon}</span>
      <span className="capitalize ">{link.name}</span>
    </NavLink>
    {link?.submenu?.map((link) => {
      console.log("submenu", link);
      return (
        <NavLink
          to={
            link.submenuName === "dashboard"
              ? "/"
              : `/${link.submenuName}`
          }
          key={link.submenuName}
          onClick={handleCloseSideBar}
          style={({ isActive }) => ({
            backgroundColor: isActive ? currentColor : "",
          })}
          className={({ isActive }) =>
            isActive ? activeLink : subLink
          }
        >
          <span className="text-2xl">{link.submenuIcon}</span>
          <span className="capitalize ">
            {link.submenuName}
          </span>
        </NavLink>
      );
    })}
  </div>
))}

*/
