/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";

import { AuthContext } from "../contexts/Auth.Context";
import { ApisContext } from "../contexts/ApiConterxt";

import axios from "axios";
import { toast } from "react-toastify";

import {
  MdOutlineCurrencyRupee,
  MdOutlinePublishedWithChanges,
} from "react-icons/md";
import { FaFileDownload, FaRupeeSign } from "react-icons/fa";

const PDFDownloadButton = ({ htmlContent }) => {
  const handleDownload = async () => {
    try {
      const iframe = document.createElement("iframe");
      document.body.appendChild(iframe);

      // Get the document object of the iframe's content
      const iframedoc = iframe.contentDocument || iframe.contentWindow.document;

      // Set the HTML content in the iframe
      iframedoc.open();
      iframedoc.write(
        "<!DOCTYPE html><html><head><style>body{margin:0;padding:0;font-family:Poppiens, serif;}</style></head><body>" +
          htmlContent +
          "</body></html>"
      );
      iframedoc.close();

      // Wait for the content to load before printing
      iframedoc.defaultView.addEventListener("load", () => {
        // Trigger the browser's print dialog for the content in the iframe
        iframedoc.defaultView.print();
      });

      // Clean up by removing the iframe after printing
      iframedoc.defaultView.onafterprint = () => {
        document.body.removeChild(iframe);
      };
    } catch (error) {
      console.error("Error printing HTML content:", error);
    }
  };

  const { currentColor } = useStateContext();
  return (
    <div>
      <button
        onClick={handleDownload}
        style={{
          background: currentColor,
        }}
        className="w-full my-2 flex items-center justify-center gap-1 focus:outline-none text-white font-medium rounded-lg text-sm px-3 py-3 hover:opacity-80"
      >
        <FaFileDownload className="text-xl" /> Download Invoice
      </button>
    </div>
  );
};

const ViewOrderDetails = () => {
  let { orderId } = useParams();
  orderId = parseInt(orderId);
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);

  const { setChnageStatus, currentColor } = useStateContext();
  const BASE_URL = window.config.API_URL;
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { state: ContextState, downLoadInvoice: downLoadInvoiceApi } =
    useContext(ApisContext);
  const { invoiceDoc } = ContextState;
  const { user } = AuthContextState;

  const navigate = useNavigate();

  const fetchOrder = async (orderId, user) => {
    setLoading(true);
    try {
      const tokenStr = user.token;

      const response = await axios.get(BASE_URL + `orders/${orderId}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (response.status === 200) {
        if (response.data.Success === true) {
          setOrder(response.data.data);
          // console.log("order successfully getted", response.data.data);
          setLoading(false);
          return response.data;
        } else if (response.data.status === "error") {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.error("Request Error: order not found");
      } else if (response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  function hexToRgb(hex) {
    // Remove the # character from the beginning of the hex value
    hex = hex.replace(/^#/, "");

    // Convert the hex value to a 6-digit string if it's a 3-digit string
    if (hex.length === 3) {
      hex = hex.replace(/(.)/g, "$1$1");
    }

    // Convert the hex value to RGB values
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    // Return the RGB values as an object
    return `${r}, ${g}, ${b}`;
  }

  const handleDownloadInvoice = async () => {
    const response = await fetch(
      `${window.config.API_URL}downloadInvoice/IN-${
        parseInt(orderId) - 23
      }`
    );
    const blob = await response.blob();

    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "invoice.pdf"; // Set the file name with .pdf extension

    // Append the link to the document body
    document.body.appendChild(link);

    link.click();

    // Remove the link from the document body
    document.body.removeChild(link);

    // Clean up the object URL after the download
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    fetchOrder(orderId, user);
  }, []);
  
  useEffect(() => {
    if (order && order?.user) {
      downLoadInvoiceApi(orderId, user);
    }
  }, [order])

  const chnageStatusHandler = (id) => {
    setChnageStatus(id);
  };

  return (
    <div className="mt-24 sm:mt-8 md:mt-5 px-5 sm:px-8 md:px-14">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-5">
        Order Details
      </div>

      {loading && (
        <div role="status" className="space-y-8 animate-pulse">
          <div className="flex flex-col md:flex-row w-full gap-4 ">
            <div className="w-full md:w-[65%] p-3 py-1.5 border dark:border-gray-600 rounded  bg-slate-50 dark:text-gray-50 dark:bg-gray-800 overscroll-y-auto">
              <div className="flex flex-col gap-4">
                <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-1.5 md:p-3 rounded-md flex items-center gap-1.5 md:gap-3">
                  <div className="border rounded-md min-w-fit">
                    <div className="h-9 w-9 md:h-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md object-cover"></div>
                  </div>
                  <div className="flex gap-3 justify-between items-center w-full md:pr-2">
                    <div className="w-full">
                      <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700  mb-2.5"></div>
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
                    </div>
                    <div className="text-right">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-14 mb-4"></div>
                      <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[56px] mb-2.5"></div>
                    </div>
                  </div>
                </div>
                <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-1.5 md:p-3 rounded-md flex items-center gap-1.5 md:gap-3">
                  <div className="border rounded-md min-w-fit">
                    <div className="h-9 w-9 md:h-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md object-cover"></div>
                  </div>
                  <div className="flex gap-3 justify-between items-center w-full md:pr-2">
                    <div className="w-full">
                      <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700  mb-2.5"></div>
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
                    </div>
                    <div className="text-right">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-14 mb-4"></div>
                      <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[56px] mb-2.5"></div>
                    </div>
                  </div>
                </div>
                <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-1.5 md:p-3 rounded-md flex items-center gap-1.5 md:gap-3">
                  <div className="border rounded-md min-w-fit">
                    <div className="h-9 w-9 md:h-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md object-cover"></div>
                  </div>
                  <div className="flex gap-3 justify-between items-center w-full md:pr-2">
                    <div className="w-full">
                      <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700  mb-2.5"></div>
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
                    </div>
                    <div className="text-right">
                      <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-14 mb-4"></div>
                      <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[56px] mb-2.5"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-[35%] flex flex-col gap-1">
              <div className="p-2 px-4 bg-slate-100 rounded-t dark:bg-slate-800 dark:text-white border dark:border-gray-600">
                <div className="w-full">
                  <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 mb-2.5"></div>
                  <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                  <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                  <div className="h-3 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                </div>
              </div>
              <div className="p-3 px-4 bg-slate-100 dark:bg-slate-800 dark:text-white border dark:border-gray-600">
                <div className="w-full">
                  <div className="h-6 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
                  <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
                  <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 mb-2.5"></div>
                  <div className="h-4 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
                  <div className="h-2 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
                  <div className="h-3 bg-gray-300 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
                </div>
              </div>
              <div className="p-5 px-4 bg-slate-100 border flex justify-center rounded-b dark:bg-slate-800 dark:text-white dark:border-gray-600">
                <div className="h-14 bg-gray-400 rounded-lg dark:bg-gray-700 w-full mb-2.5"></div>
              </div>
            </div>
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {!loading && (
        <>
          {!order?.user ? <div className="dark:border-gray-600 rounded  bg-slate-50 p-2 mb-4 border border-red-600">
            <p className="text-lg font-bold text-red-500">
              Account deleted!
              {/* User not exist(account deleted)! */}
            </p>
          </div> : null}
          <div className="flex flex-col md:flex-row gap-4 ">
            <div className="w-full md:w-[65%] p-3 py-1.5 border dark:border-gray-600 rounded  bg-slate-50 dark:text-gray-50 dark:bg-gray-800 overscroll-y-auto">
              <h5 className="font-bold text-lg mb-1.5">Order Items</h5>
              <div className="flex flex-col gap-4">
                {order?.cart_items?.map((item, i) => {
                  return (
                    <Link to={`/books/viewBook/${item?.product_id}`} key={i}>
                      <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-1.5 md:p-3 rounded-md flex items-center gap-1.5 md:gap-3">
                        <div className="border rounded-md min-w-fit">
                          <img
                            src={item?.product_image?.[0]?.image}
                            className="h-11 w-9 md:h-16 md:w-14 rounded-md object-cover"
                            alt="..."
                          />
                        </div>
                        <div className="flex justify-between items-center w-full md:pr-2">
                          <div>
                            <p className="font-semibold text-sm md:text-base">
                              {item?.title?.length > 45
                                ? `${item?.title?.substr(0, 45)} ...`
                                : item?.title}
                            </p>
                            <p className="text-xs md:text-sm capitalize">
                              Author:
                              {item?.author?.length > 45
                                ? `${item?.author?.substr(0, 45)} ...`
                                : item?.author}
                            </p>
                          </div>
                          <div className="text-right">
                            <p className="font-bold text-sm md:text-base flex items-center justify-end">
                              <FaRupeeSign />
                              {item?.price}
                            </p>
                            <p className="font-semibold leading-none text-sm md:text-base flex items-center">
                              <span className="text-xs hidden xl:block leading-none md:text-sm font-normal">
                                Quantity: &nbsp;
                              </span>
                              <span className="text-xs block xl:hidden leading-none md:text-sm font-normal">
                                Qty: &nbsp;
                              </span>
                              {item?.quantity}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
            <div className="w-full md:w-[35%] flex flex-col gap-1">
              <div className="p-2 px-4 bg-slate-100 rounded-t dark:bg-slate-800 dark:text-white border dark:border-gray-600">
                <h5 className="font-bold text-base md:text-lg">Order Details</h5>
                <div className="pl-1 mt-1 flex flex-col gap-0.5">
                  <div className="flex gap-1 text-sm md:text-base">
                    <p>Status</p>
                    <p className="text-right w-full">{order?.status}</p>
                  </div>
                  {order?.status === "Cancel" && (
                    <div className="flex gap-1 text-sm md:text-base">
                      <p className="whitespace-nowrap">Cancel reason</p>
                      <p className="text-right w-full">{order?.cancel_reason}</p>
                    </div>
                  )}
                  <div className="flex gap-1 text-sm md:text-base whitespace-nowrap">
                    <p>Order Id</p>
                    <p className="text-right w-full capitalize">#BOOK{orderId}</p>
                  </div>
                {order?.awb_number ? <div className="flex gap-1 text-sm md:text-base whitespace-nowrap">
                    <p>AWB Number</p>
                    <p className="text-right w-full capitalize">{order?.awb_number}</p>
                  </div> : null}
                  <div className="flex gap-1 text-sm md:text-base">
                    <p>User</p>
                    <p className="text-right w-full capitalize">{order?.name}</p>
                  </div>
                  <div className="flex gap-1 whitespace-nowrap text-sm md:text-base">
                    <p>Total Products</p>
                    <p className="text-right w-full capitalize">
                      {order?.cart_items?.length}
                    </p>
                  </div>
                  <hr className="my-2 dark:border-gray-400" />
                  <div className="flex gap-1 text-sm md:text-base whitespace-nowrap">
                    <p className="font-bold">
                      Total ({order?.cart_items?.length} item)
                    </p>
                    <p className="text-right w-full font-bold capitalize flex items-center justify-end">
                      {order?.sub_total}
                      <MdOutlineCurrencyRupee />
                    </p>
                  </div>
                  <div className="flex gap-1 text-sm md:text-base whitespace-nowrap">
                    <p>Discount</p>
                    <p className="text-right w-full font-bold capitalize flex items-center text-green-500 justify-end">
                      -
                      {/* {order?.is_free_delivery === "1"
                        ? (parseInt(order?.sub_total) -
                          (parseInt(order?.total_amount) - parseInt(order?.coupon_discount)))
                        : (parseInt(order?.sub_total) -
                          (parseInt(order?.total_amount) -
                            (parseInt(order?.coupon_discount)+ parseInt(order?.delivery_charges))))} */}
                      {(((Number(order?.sub_total) - Number(order?.total_amount)) + Number(order?.delivery_charges) + Number(order?.cod_fee)) - (Number(order?.coupon_discount) + Number(order?.coin)) )}
                      <MdOutlineCurrencyRupee />
                    </p>
                  </div>
                  <div className="flex gap-1 text-sm md:text-base whitespace-nowrap">
                    <p>Shipping Charges</p>
                    <p className="text-right w-full font-bold capitalize flex items-center justify-end">
                      {order?.delivery_charges}
                      <MdOutlineCurrencyRupee />
                    </p>
                  </div>
                  {order?.cod_fee ? (
                    <div className="flex gap-1 text-sm md:text-base whitespace-nowrap">
                      <p>COD Amount</p>
                      <p className="text-right w-full font-bold capitalize flex items-center justify-end">
                        {order?.cod_fee}
                        <MdOutlineCurrencyRupee />
                      </p>
                    </div>
                  ) : null}
                  {order?.coupon_discount && order?.coupon_discount !== "0" && (
                    <div className="flex gap-1 text-sm md:text-base whitespace-nowrap">
                      <p>Coupon Discount</p>
                      <p className="text-right w-full font-bold capitalize flex items-center text-green-500 justify-end">
                        -{order?.coupon_discount}
                        <MdOutlineCurrencyRupee />
                      </p>
                    </div>
                  )}
                  {Number(order?.coin) ? (
                    <div className="flex gap-1 text-sm md:text-base whitespace-nowrap">
                      <p>Coin Used</p>
                      <p className="text-right w-full font-bold capitalize flex items-center text-green-500 justify-end">
                        -{order?.coin}
                        {/* <MdOutlineCurrencyRupee /> text-green-500 */}
                      </p>
                    </div>
                  ): null}
                  <hr className="my-0.5" />
                  <div className="flex gap-1 text-sm md:text-base whitespace-nowrap mb-2">
                    <p className="font-bold">Total Amount</p>
                    <p className="text-right w-full font-bold capitalize flex items-center justify-end">
                      {order?.total_amount}
                      <MdOutlineCurrencyRupee />
                    </p>
                  </div>
                  {order?.user ? <PDFDownloadButton htmlContent={invoiceDoc} /> : null}
                  
                </div>
              </div>

              {order?.coupon && (
                <div className="p-3 px-4 bg-slate-100 dark:bg-slate-800 dark:text-white border dark:border-gray-600">
                  <p className="text-base md:text-md font-semibold">Coupon :-</p>
                  <div className="text-sm md:text-base pl-1 mt-0.5 grid grid-cols-2">
                    <p className="capitalize ">Coupon Name</p>
                    <p className="capitalize text-right">{order?.coupon?.name}</p>
                    <p className="capitalize ">Coupon Code</p>
                    <p className="capitalize text-right">
                      {order?.coupon?.coupon_code}
                    </p>
                    <p className="capitalize ">Discount</p>
                    <p className="capitalize text-right">
                      {order?.coupon?.discount}%
                    </p>
                    <p className="capitalize">Coin Used</p>
                    <p className="capitalize text-right">
                      {order?.coupon?.coinUsed}
                    </p>
                  </div>
                </div>
              )}
              {order?.payment_object ? (
                <div className="p-3 px-4 bg-slate-100 dark:bg-slate-800 dark:text-white border dark:border-gray-600">
                  <p className="text-base md:text-md font-semibold">
                    Payment Details :-
                  </p>
                  <div className="text-sm md:text-base pl-1 mt-0.5 flex flex-col">
                    <p className="capitalize ">
                      Transaction ID: {JSON.parse(order?.payment_object)?.txnid}
                    </p>
                    <p className="capitalize">
                      Mode: {JSON.parse(order?.payment_object)?.mode}
                    </p>
                    <p className="capitalize ">
                      Status:{" "}
                      <span className="uppercase">
                        {JSON.parse(order?.payment_object)?.status}
                      </span>
                    </p>
                  </div>
                </div>
              ) : null}
          
              {order?.shipped_image?.length ? (order?.shipped_image?.map((image, i) => {
                return (
                <div key={i} className="p-3 px-4 bg-slate-100 dark:bg-slate-800 dark:text-white border dark:border-gray-600">
                  <p className="text-base md:text-md font-semibold mb-1">Shipped Image :-</p>
                  <img src={image} alt="" className="h-44 w-full object-contain" />
                </div>
                )
              })
              ) : null }
              <div className="p-3 px-4 bg-slate-100 dark:bg-slate-800 dark:text-white border dark:border-gray-600">
                <p className="text-base md:text-md font-semibold">Address :-</p>
                <div className="text-sm md:text-base pl-1 mt-0.5 flex flex-col">
                <p className="capitalize ">Name: {order?.address?.name}</p>
                <p className="capitalize ">Mobile: {order?.address?.mobile}</p>{ order?.address?.alternative_mobile &&
                  <p className="capitalize ">
                  Alternative  Mobile: {order?.address?.alternative_mobile}
                  </p>}
                  <p className="capitalize mt-2">Address: {order?.address?.address}</p>
                  <p className="capitalize ">City: {order?.address?.city}</p>
                  <p className="capitalize">State: {order?.address?.state}</p>
                  <p className="capitalize ">Pin: {order?.address?.pincode}</p>
                </div>
              </div>
              <div className="p-3 px-4 bg-slate-100 dark:bg-slate-800 dark:text-white border dark:border-gray-600">
                <p className="text-base md:text-md font-semibold">
                  User Details :-
                </p>
                <div className="text-sm md:text-base pl-1 mt-0.5 flex flex-col">
                  <p className="capitalize ">Name: {order?.user?.name}</p>
                  <p className="capitalize">Email: {order?.user?.email}</p>
                  <p className="capitalize ">
                    Mobile: {order?.user?.phone_number}
                  </p>
                </div>
              </div>

              <div className="p-5 px-4 bg-slate-100 border flex justify-center rounded-b dark:bg-slate-800 dark:text-white dark:border-gray-600">
                <button
                  style={{
                    background: currentColor,
                  }}
                  onClick={() => chnageStatusHandler(orderId)}
                  className="w-full flex items-center justify-center gap-1 focus:outline-none text-white font-medium rounded-lg text-sm px-3 py-3 hover:opacity-80"
                >
                  <MdOutlinePublishedWithChanges className="text-xl" /> Change
                  Status
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ViewOrderDetails;
