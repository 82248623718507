/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
// import { BiCloudDownload } from "react-icons/bi";

// import Datepicker from "react-tailwindcss-datepicker";

import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useStateContext } from "../contexts/ContextProvider";

import { MdOutlinePublishedWithChanges } from "react-icons/md";
import moment from "moment/moment";

import ReactPaginate from "react-paginate";
import NotFoundImg from "../assets/No data-pana.png";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BsFillEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const Paging = ({ users, currentPage, handlePageClick, parPageCount, deletedCustomersAll }) => {
  const BASE_URL = window.config.API_URL;
  const { setChnageStatus, currentColor } = useStateContext();


  const { state: ContextState } = useContext(ApisContext);
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;

  const { fetchDeletedCustomersPending } = ContextState;

  // console.log(Math.ceil(users?.length / parPageCount), 'math.ceil(users?.length / pageCount', users?.length)
  // const handlePageClick = (data) => {
  //   const selectedPage = data.selected;
  //   setCurrentPage(selectedPage);
  // };

  // useEffect(() => {
  //   const startIndex = parseInt(currentPage) * parseInt(parPageCount);
  //   const endIndex = parseInt(startIndex) + parseInt(parPageCount);
  //   console.log("users -------------------------->", users, parPageCount, startIndex, endIndex);
  //   setFilterData(users?.slice(startIndex, endIndex));
  // }, [currentPage, users, parPageCount]);

  return (
    <>
      <div className="-my-2 py-2 sm:-mx-6 sm:px-3 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
          <table className="min-w-full px-4">
            <thead>
              <tr className="bg-gray-300 dark:bg-gray-600 dark:text-gray-50 border-b border-gray-200 dark:border-gray-700 text-sm leading-4 uppercase tracking-wider whitespace-nowrap">
                <th className="ps-6 px-3 py-4 text-left font-semibold">ID.</th>
                <th className="px-3 py-4 text-left font-semibold">
                  Delete Reason
                </th>
                {/* <th className="px-3 py-4 text-left font-semibold">
                  CUSTOMER NAME
                </th>
                <th className="px-3 py-4 text-left font-semibold">Email</th>
                <th className="px-3 py-4 text-left font-semibold">Phone no.</th> */}
                <th className="px-3 py-4 text-left font-semibold">Deleted on</th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-500">
              {!fetchDeletedCustomersPending &&
                users?.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className="border-b border-gray-200 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                    >
                      <td className="ps-6 px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 ">{item?.id}</div>
                      </td>
                      <td className="px-3 py-2">
                        <div className="text-sm leading-5 capitalize">
                          {item?.delete_reason}
                        </div>
                      </td>
                      
                      {/*
                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 capitalize">
                          {item?.name}
                        </div>
                      </td>

                      <td className="px-3 py-4 text-xl ">
                        <div className="text-sm leading-5 capitalize">
                          {item?.email}
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm ">
                        <div className="text-sm leading-5 capitalize">
                          {item?.mobile}
                        </div>
                      </td> */}
                      <td className="px-3 py-2 whitespace-nowrap text-sm ">
                        <div className="text-sm leading-5 capitalize">
                          {moment(item?.deleted_at).format("DD MMM YYYY")}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {fetchDeletedCustomersPending && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    colSpan={8}
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
              {!fetchDeletedCustomersPending && !users?.length && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td colSpan={8} className="p-4 py-2 ">
                    <div className="flex justify-center items-left md:items-center flex-col md:flex-row">
                      <img
                        className="max-h-56 w-56 sm:max-w-[450px] sm:max-h-[450px]"
                        src={NotFoundImg}
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
              )}
              {!fetchDeletedCustomersPending && users?.length ? (
                <tr>
                  <td colSpan={8}>
                    <div className="flex justify-between m-4 my-2 items-left md:items-center flex-col md:flex-row">
                      <div className="whitespace-nowrap">
                        <span className="text-sm text-gray-700 dark:text-gray-400">
                          Showing{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {deletedCustomersAll?.from || ""}
                          </span>{" "}
                          to{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {deletedCustomersAll?.to || ""}
                          </span>{" "}
                          of{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {deletedCustomersAll?.total || ""}
                          </span>{" "}
                          Entries
                        </span>
                      </div>
                      <ReactPaginate
                        previousLabel={<GrFormPrevious />}
                        nextLabel={<GrFormNext />}
                        breakLabel={"..."}
                        initialPage={currentPage-1}
                        pageCount={Math.ceil(deletedCustomersAll?.total / deletedCustomersAll?.per_page)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "items-center inline-flex space-x-2"
                        }
                        pageClassName={
                          "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
                        previousLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        disabledLinkClassName={"text-gray-400 opacity-50"}
                        nextLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
                        activeLinkClassName={
                          "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
                        }
                      />
                    </div>
                  </td>
                </tr>
              ):null}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const DeletedCustomers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuerry, setSearchQuerry] = useState("");

  const { state: ContextState, fetchDeletedCustomers } = useContext(ApisContext);
  const { deletedCustomers, deletedCustomersAll, fetchDeletedCustomersPending } = ContextState;
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;

  useEffect(() => {
    fetchDeletedCustomers(user, currentPage);
  }, []);
  
  useEffect(() => {
    fetchDeletedCustomers(user, currentPage, searchQuerry);
  }, [searchQuerry, currentPage]);

  const handlePageClick = (data) => {
      const selectedPage = data.selected;
      setCurrentPage(selectedPage+1);
    };

  return (
    <div className="px-5  sm:px-10 sm:mt-8 mt-24">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-3">
        Deleted Customers
      </div>
      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-5 border ">
        <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input autoComplete="off"
              type="text"
              onChange={(e) => setSearchQuerry(e.target.value)}
              id="simple-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
            />
          </div>
          {/* <select
            id="limit"
            onChange={(e) => {
              setParPageCount(e.target.value);
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option defaultValue disabled>
              Customers limits
            </option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select> */}
        </div>
      </div>
      <Paging users={deletedCustomers} deletedCustomersAll={deletedCustomersAll} parPageCount={deletedCustomersAll?.per_page} handlePageClick={handlePageClick} currentPage={deletedCustomersAll?.current_page} />
    </div>
  );
};

export default DeletedCustomers