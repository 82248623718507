import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useStateContext } from "../contexts/ContextProvider";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { IoCloseCircle } from "react-icons/io5";

const SetTimeModal = () => {
  const BASE_URL = window.config.API_URL;
  const navigate = useNavigate();
  const { chnageStatus, setChnageStatus, currentColor } = useStateContext();
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;
  const [orderDetailsPendig, setOrderDetailsPendig] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const style = {
    backdropFilter: "blur(1px)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  };
  const { setPendingFun, fetchOrders, fetchUsersOrders, fetchDashboard } =
    useContext(ApisContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm();
  const [files, setFiles] = useState([]);
  useEffect(() => {
    // console.log(chnageStatus, "STATUS");
    getOrderDetails(chnageStatus, user);
    // console.log(orderDetails, "ORDER", orderDetails?.status);
  }, [chnageStatus]);
  // const [date, setDate] = useState({
  //   startDate: new Date(),
  //   endDate: new Date(),
  // });
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const timezoneOffsetInMinutes = date.getTimezoneOffset(); // Get the timezone offset in minutes
    const timezoneOffsetInMilliseconds = timezoneOffsetInMinutes * 60 * 1000; // Convert minutes to milliseconds
    const adjustedDate = new Date(
      date.getTime() - timezoneOffsetInMilliseconds
    ); // Apply the timezone offset (note the subtraction here)
    const formattedDate = adjustedDate.toISOString().slice(0, 16); // Format the adjusted date
    return formattedDate;
  };

  const getOrderDetails = async (id, user) => {
    setOrderDetailsPendig(true);
    try {
      const tokenStr = user.token;

      const response = await axios.get(BASE_URL + `orders/${id}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setOrderDetailsPendig(false);
      if (response.status === 200) {
        if (response.data.Success === true) {
          setOrderDetails(response.data.data);
          setValue("changeStatus", response.data.data.status);
          setValue("cancel_reason", response.data.data.cancel_reason);
          setValue("delivery_service_cost", response?.data?.data?.delivery_service_cost);
          if (
            response.data.data?.delivery_time?.cancel_time &&
            response.data.data?.delivery_time?.cancel_time !== undefined &&
            response.data.data?.delivery_time?.cancel_time !== null &&
            response.data.data?.delivery_time?.cancel_time !== "null"
          ) {
            const cancel_time = new Date(
              response.data.data?.delivery_time?.cancel_time
            );
            setValue("cancel_time", formatDate(cancel_time));
            // console.log(
            //   "cancel_time",
            //   cancel_time.toISOString().substring(0, 16)
            // );
          }
          // console.log(response.data.data?.delivery_time?.delivery_time, "delivery_time response");
          if (
            response.data.data?.delivery_time?.shipped_time &&
            response.data.data?.delivery_time?.shipped_time !== undefined &&
            response.data.data?.delivery_time?.shipped_time !== null &&
            response.data.data?.delivery_time?.shipped_time !== "null"
          ) {
            const shipped_time = new Date(
              response.data.data?.delivery_time?.shipped_time
            );
            setValue("shipped_time", formatDate(shipped_time));

            setValue("awb_number", response?.data?.data?.awb_number);
          }

          if (
            response.data.data?.delivery_time?.delivered_time &&
            response.data.data?.delivery_time?.delivered_time !== undefined &&
            response.data.data?.delivery_time?.delivered_time !== null &&
            response.data.data?.delivery_time?.delivered_time !== "null"
          ) {
            const delivered_time = new Date(
              response.data.data?.delivery_time?.delivered_time
            );
            setValue("delivered_time", formatDate(delivered_time));
            // console.log(
            //   "delivered_time",
            //   delivered_time
            // );
          }

          if (
            response.data.data?.delivery_time?.processing_time &&
            response.data.data?.delivery_time?.processing_time !== undefined &&
            response.data.data?.delivery_time?.processing_time !== null &&
            response.data.data?.delivery_time?.processing_time !== "null"
          ) {
            const processing_time = new Date(
              response.data.data?.delivery_time?.processing_time
            );
            setValue("processing_time", formatDate(processing_time));
            // console.log(
            //   "processing_time",
            //   formatDate(processing_time)
            // );
          }

          let delivery_time;
          // console.log(response.data.data?.delivery_time?.delivery_time, "delivery_time response");
          if (
            response.data.data?.delivery_time?.delivery_time ===
              "1970-01-01 00:00:00" &&
            response.data.data?.delivery_time?.delivery_time !== undefined &&
            response.data.data?.delivery_time?.delivery_time !== null &&
            response.data.data?.delivery_time?.delivery_time !== "null"
          ) {
            delivery_time = new Date();
          } else {
            delivery_time = new Date(
              response.data.data?.delivery_time?.delivery_time
            );
          }
          setValue("delivery_time", formatDate(delivery_time));
          // console.log(
          //   "deliveryTime",
          //   delivery_time,
          //   delivery_time
          // );
          return response.data;
        } else {
        }
      } else if (response.status === 404 || response === null) {
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setOrderDetailsPendig(false);
      if (error?.response?.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        // console.log("Error- ", error.response, error);
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  const changeStatus = watch("changeStatus");

  const onSubmit = async (data) => {
    // console.log(data);

    const newData = new FormData();
    newData.append("status", data.changeStatus);
    
    newData.append("delivery_service_cost", data?.delivery_service_cost);
    newData.append(
      "delivery_time",
      moment(data.delivery_time).format("YYYY-MM-DD HH:mm:ss")
    );
    if (!orderDetails?.delivery_time?.pending_time) {
      newData.append(
        "pending_time",
        moment(orderDetails?.created_at).format("YYYY-MM-DD HH:mm:ss")
      );
    } else {
      newData.append(
        "pending_time",
        moment(orderDetails?.delivery_time?.pending_time).format(
          "YYYY-MM-DD HH:mm:ss"
        )
      );
    }
    if (data.changeStatus === "Cancel") {
      newData.append("cancel_reason", data.cancel_reason);
    } else {
      newData.append("cancel_reason", null);
    }
    if (data.cancel_time) {
      newData.append(
        "cancel_time",
        moment(data.cancel_time).format("YYYY-MM-DD HH:mm:ss")
      );
    } else {
      newData.append("cancel_time", null);
    }

    if (data.changeStatus === "Shipped") {
      newData.append("awb_number", data.awb_number);
      files.map((file) => {
        newData.append("shipped_images[]", file);
      });
    }

    if (data.shipped_time) {
      newData.append(
        "shipped_time",
        moment(data.shipped_time).format("YYYY-MM-DD HH:mm:ss")
      );
    } else {
      newData.append("shipped_time", null);
    }
    if (data.delivered_time) {
      newData.append(
        "delivered_time",
        moment(data.delivered_time).format("YYYY-MM-DD HH:mm:ss")
      );
    } else {
      newData.append("delivered_time", null);
    }
    if (data.processing_time) {
      newData.append(
        "processing_time",
        moment(data.processing_time).format("YYYY-MM-DD HH:mm:ss")
      );
    } else {
      newData.append("processing_time", null);
    }

    // console.log(newData, "submit");
    // return;
    setPendingFun(true);
    // const params = new URLSearchParams(newData);
    // const encodedData = params.toString();

    try {
      const tokenStr = user.token;

      const response = await axios.post(
        BASE_URL + `orders/${chnageStatus}`,
        newData,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      // setPending(false);
      setPendingFun(false);
      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.success("Order status successfully Updated!");
          setChnageStatus(false);
          fetchOrders(user);
          fetchDashboard(user);
          fetchUsersOrders(orderDetails?.user_id, user);
          return response.data;
        } else if (response.data.status === "error") {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.error("Request Error: Order not Updated");
      } else if (response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPendingFun(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        // console.log("Error- ", error.response);
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  const onChangeImage = (e) => {
    [...e.target.files].forEach((file) => {
      setFiles((prevFiles) => [...prevFiles, file]);
    });
  };

  const removeFile = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  return (
    <div
      id="setTimeModal"
      style={style}
      className="fixed flex justify-center items-center top-1 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[100vh] max-h-full"
    >
      <div className="relative w-full max-w-md max-h-full">
        <div className="bg-white rounded-lg shadow dark:bg-gray-700">
          {orderDetailsPendig && (
            <div className="flex py-20 h-full w-full justify-center items-center">
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!orderDetailsPendig && (
            <>
              {" "}
              <button
                type="button"
                onClick={() => setChnageStatus(false)}
                className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
              <div className="px-6 py-6 lg:px-8">
                <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
                  Change Status
                </h3>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                  {errors.changeStatus && (
                    <small className="text-sm text-red-600 dark:text-red-500">
                      Status is required
                    </small>
                  )}

                  <div className="px-4 grid grid-cols-2 gap-2">
                    <div className="flex items-center">
                      <input
                        autoComplete="off"
                        id="pending"
                        type="radio"
                        value="Pending"
                        {...register("changeStatus", { required: true })}
                        className="w-4 h-4 text-gary-600 bg-gray-100 border-gray-300 focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="pending"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Pending
                      </label>
                    </div>
                    <div>
                      <input
                        autoComplete="off"
                        type="text"
                        value={
                          orderDetails?.delivery_time?.pending_time ||
                          orderDetails?.created_at
                        }
                        readOnly={true}
                        className={`border text-sm rounded block w-full p-1 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white focus:outline-none `}
                      />
                    </div>

                    <div className="flex items-center">
                      <input
                        autoComplete="off"
                        id="processing"
                        type="radio"
                        value="Processing"
                        {...register("changeStatus", { required: true })}
                        className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 focus:ring-gray-500 dark:focus:gary-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="processing"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Processing
                      </label>
                    </div>
                    <div>
                      <input
                        autoComplete="off"
                        type="datetime-local"
                        {...register("processing_time", {
                          required: {
                            value: changeStatus === "Processing" ? true : false,
                            message: "processing time required",
                          },
                        })}
                        className={`border text-sm rounded block w-full p-1 ${
                          errors.processing_time
                            ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                            : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        } `}
                      />
                      <small className="text-sm text-red-600 dark:text-red-500">
                        {errors.processing_time?.message}
                      </small>
                    </div>

                    <div className="flex items-center">
                      <input
                        autoComplete="off"
                        id="shipped"
                        type="radio"
                        value="Shipped"
                        {...register("changeStatus", { required: true })}
                        className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="shipped"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Shipped
                      </label>
                    </div>
                    <div>
                      <input
                        autoComplete="off"
                        type="datetime-local"
                        {...register("shipped_time", {
                          required: {
                            value: changeStatus === "shipped" ? true : false,
                            message: "Shipped time required",
                          },
                        })}
                        className={`border text-sm rounded block w-full p-1 ${
                          errors.shipped_time
                            ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                            : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        } `}
                      />
                      <small className="text-sm text-red-600 dark:text-red-500">
                        {errors.shipped_time?.message}
                      </small>
                    </div>

                    <div className="flex items-center">
                      <input
                        autoComplete="off"
                        id="delivered"
                        type="radio"
                        value="Delivered"
                        {...register("changeStatus", { required: true })}
                        className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="delivered"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Delivered
                      </label>
                    </div>
                    <div>
                      <input
                        autoComplete="off"
                        type="datetime-local"
                        {...register("delivered_time", {
                          required: {
                            value: changeStatus === "Delivered" ? true : false,
                            message: "delivered time required",
                          },
                        })}
                        className={`border text-sm rounded block w-full p-1 ${
                          errors.delivered_time
                            ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                            : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        } `}
                      />
                      <small className="text-sm text-red-600 dark:text-red-500">
                        {errors.delivered_time?.message}
                      </small>
                    </div>

                    <div className="flex items-center">
                      <input
                        autoComplete="off"
                        id="cancel"
                        type="radio"
                        value="Cancel"
                        {...register("changeStatus", { required: true })}
                        className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="cancel"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Cancel
                      </label>
                    </div>
                    <div>
                      <input
                        autoComplete="off"
                        type="datetime-local"
                        {...register("cancel_time", {
                          required: {
                            value: changeStatus === "Cancel" ? true : false,
                            message: "canceled time required",
                          },
                        })}
                        className={`border text-sm rounded block w-full p-1 ${
                          errors.cancel_time
                            ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                            : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        } `}
                      />
                      <small className="text-sm text-red-600 dark:text-red-500">
                        {errors.cancel_time?.message}
                      </small>
                    </div>
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <div>
                      <label
                        htmlFor="delivery_time"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Delivery time
                      </label>

                      <input
                        autoComplete="off"
                        type="datetime-local"
                        {...register("delivery_time", {
                          required: {
                            value: true,
                            message: "delivery time required",
                          },
                        })}
                        // onChange={handleDateChange}
                        className={`border text-sm rounded block w-full p-1.5 ${
                          errors.delivery_time
                            ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                            : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        } `}
                      />

                      {errors.delivery_time && (
                        <small className="text-sm text-red-600 dark:text-red-500">
                          {errors.delivery_time?.message}
                        </small>
                      )}
                    </div>
                    {changeStatus === "Cancel" && (
                      <div>
                        <label
                          htmlFor="cancel_reason"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Cancel Reason
                        </label>

                        <input
                          autoComplete="off"
                          type="text"
                          {...register("cancel_reason", {
                            required: {
                              value: changeStatus === "Cancel" ? true : false,
                              message: "cancel reason required",
                            },
                          })}
                          className={`border text-sm rounded block w-full p-1.5 ${
                            errors.cancel_reason
                              ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                              : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          } `}
                          placeholder="Enter cancel reason"
                        />

                        <small className="text-sm text-red-600 dark:text-red-500">
                          {errors.cancel_reason?.message}
                        </small>
                      </div>
                    )}
                    {changeStatus === "Shipped" && (
                      <div>
                        <label
                          htmlFor="awb_number"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          AWB Number
                        </label>

                        <input
                          autoComplete="off"
                          type="text"
                          {...register("awb_number", {
                            required: {
                              value: changeStatus === "Shipped" ? true : false,
                              message: "awb number reason required",
                            },
                          })}
                          className={`border text-sm rounded block w-full p-1.5 ${
                            errors.awb_number
                              ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                              : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          } `}
                          placeholder="Enter awb number"
                        />

                        <small className="text-sm text-red-600 dark:text-red-500">
                          {errors.awb_number?.message}
                        </small>
                      </div>
                    )}
                    {changeStatus === "Shipped" && (
                      <div>
                        <label
                          htmlFor="delivery_image"
                          className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Delivery Images
                        </label>

                        <input
                          autoComplete="off"
                          type="file"
                          multiple
                          onChange={(event) => onChangeImage(event)}
                          className={`border text-sm rounded block w-full p-1.5 ${
                            errors?.delivery_image
                              ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                              : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          } `}
                          placeholder="Choose Delivery Images"
                        />
                        {files?.length ? (
                          <div className="flex gap-2 flex-wrap mt-2">
                            {files?.map((image, i) => {
                              return (
                                <div key={i} className="h-24 w-24 relative">
                                  <div
                                    onClick={() => removeFile(image)}
                                    className="absolute -top-0.5 -right-0.5"
                                  >
                                    <IoCloseCircle className="text-red-600 cursor-pointer" />
                                  </div>
                                  <img
                                    className="h-24 w-24 object-contain"
                                    src={URL.createObjectURL(image)}
                                    alt=""
                                  />
                                </div>
                              );
                            })}
                          </div>
                        ) : null}

                        <small className="text-sm text-red-600 dark:text-red-500">
                          {errors?.delivery_image?.message}
                        </small>
                      </div>
                    )}
                    {changeStatus !== 'Pending' && changeStatus !== 'Processing' && (<div>
                      <label
                        htmlFor="delivery_service_cost"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Delivery Service Cost
                      </label>

                      <input
                        autoComplete="off"
                        type="number"
                        {...register("delivery_service_cost", {
                          required: {
                            value: true,
                            message: "delivery service cost required",
                          },
                        })}
                        className={`border text-sm rounded block w-full p-1.5 ${
                          errors.delivery_service_cost
                            ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                            : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                        } `}
                        placeholder="Enter delivery service cost"
                      />

                      <small className="text-sm text-red-600 dark:text-red-500">
                        {errors?.delivery_service_cost?.message}
                      </small>
                    </div>)}
                  </div>
                  <button
                    type="submit"
                    style={{
                      background: currentColor,
                    }}
                    className="w-full text-white focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                  >
                    Save
                  </button>
                </form>
              </div>{" "}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SetTimeModal;
/* 
<form>
            <label
              htmlFor="productTags"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Set Time
            </label>
            <input
              type="text"
              placeholder="Enter Estimate Time"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-gray-500 focus:border-gray-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-gray-500 dark:focus:border-gray-500"
            />
            <div className="text-center pt-2">
              <button
                type="submit"
                onClick={() =>{ setModal(false)}}
                className=" text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
              >
                Save
              </button>
            </div>
          </form>
*/
