import React, { useContext, useEffect, useState } from "react";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useStateContext } from "../contexts/ContextProvider";
import moment from "moment/moment";

import ReactPaginate from "react-paginate";
import {
  MdDone,
  MdOutlineCancel,
  MdOutlinePublishedWithChanges,
  MdTaskAlt,
} from "react-icons/md";
import NotFoundImg from "../assets/No data-pana.png";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BsFillEyeFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

const BASE_URL = window.config.API_URL;

// import Paging from "../components/dashboard/PagingTable";
const Paging = ({ orders, parPageCount }) => {
  const navigate = useNavigate();
  const { setChnageStatus, currentColor } = useStateContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const {
    state: ContextState, fetchNotConfiredOrders
  } = useContext(ApisContext);
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;

  const { fetchOrdersPending } = ContextState;

  // console.log(Math.ceil(orders?.length / parPageCount), 'math.ceil(orders?.length / pageCount', orders?.length)
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    // console.log("orders====>", orders);
    const startIndex = parseInt(currentPage) * parseInt(parPageCount);
    const endIndex = parseInt(startIndex) + parseInt(parPageCount);
    setFilterData(orders?.slice(startIndex, endIndex));
  }, [orders]);
  useEffect(() => {
    const startIndex = parseInt(currentPage) * parseInt(parPageCount);
    const endIndex = parseInt(startIndex) + parseInt(parPageCount);
    setFilterData(orders?.slice(startIndex, endIndex));
  }, [currentPage, orders, parPageCount]);

  const [rejectModal, setRejectModal] = useState(null);
  const [acceptModalForm, setAcceptModalForm] = useState(false);

  const rejectOrder = async () => {
    const tokenStr = user.token;
    const data = {};
    data.payment_object = rejectModal?.payment_object || null;
    data.payment_mode = "online";
    data.payment_status = "fail";
    data.user_id = rejectModal?.user_id || null;
    try {
      const response = await axios.post(
        BASE_URL + `PaymentConfirmation/${rejectModal.id}`,
        data,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Rejected successfully");
          fetchNotConfiredOrders(user);
          reset();
          setRejectModal(null);
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Api not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      if (error.response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
    setRejectModal(null);
  };
  const confirmOrder = async (formData) => {
    const tokenStr = user.token;
    const payment_mode = {
      id: acceptModalForm.id,
      mode: formData.mode,
      status: formData.status,
      txnid: formData.txnid,
      email: formData.email,
      firstName: formData.firstName,
    };
    const data = {};
    data.payment_object = JSON.stringify(payment_mode);
    data.payment_mode = (formData.mode).toLowerCase();
    data.payment_status = formData.status;
    data.user_id = acceptModalForm?.user_id || null;
    console.log(data, 'payment');
    // return
    try {
      const response = await axios.post(
        BASE_URL + `PaymentConfirmation/${acceptModalForm.id}`,
        data,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Confirm successfully");
          fetchNotConfiredOrders(user);
          reset();
          setAcceptModalForm(null);
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Api not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      if (error.response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
    reset();
    setAcceptModalForm(null);
  };

  return (
    <>
      {acceptModalForm && (
        <div
          style={{ zIndex: "999999999" }}
          className="fixed inset-0 bg-black bg-opacity-60"
        >
          <div className="h-full w-full flex justify-center items-center">
            <div className="rounded-lg min-w-[280px] bg-white dark:bg-gray-600 p-5 relative">
              <div
                onClick={() => setAcceptModalForm(false)}
                className="absolute cursor-pointer bg-white dark:bg-gray-600 rounded-full p-0.5 shadow-xl -top-2 -right-2 flex justify-center items-center"
              >
                <MdOutlineCancel className="text-2xl text-gray-900 dark:text-gray-50" />
              </div>
              <form onSubmit={handleSubmit((data) => confirmOrder(data))}>
                <div className="flex items-center gap-2">
                  <label
                    htmlFor="payment_mode"
                    className="block mb-1.5 w-full text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Payment mode:
                  </label>
                  <select
                    className={`border text-sm rounded-md block w-full p-1 ${
                      errors.mode
                        ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                        : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    } `}
                    placeholder="Select Mode"
                    {...register("mode", {
                      required: {
                        value: true,
                        message: "Payment mode required",
                      },
                    })}
                  >
                    <option defaultValue="online" key="online">
                      ONLINE
                    </option>
                    <option value="cod" key="cod">
                      COD
                    </option>
                  </select>
                </div>
                <div className="flex items-center gap-2 mt-2">
                  <label
                    htmlFor="status"
                    className="block mb-1.5 w-full text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Payment status:
                  </label>
                  <select
                    id="status"
                    className={`border text-sm rounded-md block w-full p-1 ${
                      errors.status
                        ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                        : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    } `}
                    placeholder="Select status"
                    {...register("status", {
                      required: {
                        value: true,
                        message: "Status required",
                      },
                    })}
                  >
                    <option value="success" key="success">
                      Success
                    </option>
                    <option value="fail" key="fail">
                      Fail
                    </option>
                  </select>
                </div>
                <div className="flex items-center gap-2 mt-2">
                  <label
                    htmlFor="txnid"
                    className="block mb-1.5 w-full text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Txn Id:
                  </label>
                  <input autoComplete="off"
                    id="txnid"
                    className={`border text-sm rounded-md block w-full p-1 ${
                      errors.txnid
                        ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                        : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    } `}
                    placeholder="Enter txnid"
                    {...register("txnid", {
                      required: {
                        value: true,
                        message: "Status required",
                      },
                    })}
                  ></input>
                </div>
                <div className="flex items-center gap-2 mt-2">
                  <label
                    htmlFor="firstName"
                    className="block mb-1.5 w-full text-sm font-medium text-gray-900 dark:text-white"
                  >
                    First Name:
                  </label>
                  <input autoComplete="off"
                    id="firstName"
                    className={`border text-sm rounded-md block w-full p-1 ${
                      errors.firstName
                        ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                        : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    } `}
                    placeholder="Enter First Name"
                    {...register("firstName", {
                      required: {
                        value: true,
                        message: "First Name required",
                      },
                    })}
                  ></input>
                </div>
                <div className="flex items-center gap-2 mt-2">
                  <label
                    htmlFor="email"
                    className="block mb-1.5 w-full text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Email:
                  </label>
                  <input autoComplete="off"
                    id="email"
                    className={`border text-sm rounded-md block w-full p-1 ${
                      errors.email
                        ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                        : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    } `}
                    placeholder="Enter email"
                    {...register("email", {
                      required: {
                        value: true,
                        message: "Email required",
                      },
                    })}
                  ></input>
                </div>
                <div className="text-end mt-5">
                  <button
                    type="submit"
                    className="px-4 py-1.5 bg-green-500 text-white text-md rounded-lg"
                  >
                    Confirm
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {rejectModal && (
        <div
          style={{ zIndex: "999999999" }}
          className="fixed inset-0 bg-black bg-opacity-60"
        >
          <div className="h-full w-full flex justify-center items-center">
            <div className="rounded-lg min-w-[280px] bg-white dark:bg-gray-600 p-5 relative">
              <div
                onClick={() => setRejectModal(false)}
                className="absolute cursor-pointer bg-white dark:bg-gray-600 rounded-full p-0.5 shadow-xl -top-2 -right-2 flex justify-center items-center"
              >
                <MdOutlineCancel className="text-2xl text-gray-900 dark:text-gray-50" />
              </div>
              <h3 className="text-gray-900 dark:text-gray-50">Are you sure?</h3>
              <div className="text-end mt-5">
                <button
                  onClick={() => rejectOrder()}
                  className="px-4 py-1.5 bg-red-500 text-white text-md rounded-lg"
                >
                  YES
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="-my-2 py-2 sm:-mx-6 sm:px-3 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
          <table className="min-w-full px-4">
            <thead>
              <tr className="bg-gray-300 dark:bg-gray-600 dark:text-gray-50 border-b border-gray-200 dark:border-gray-700 text-sm leading-4 uppercase tracking-wider whitespace-nowrap">
                <th className="ps-6 px-3 py-4 text-left font-semibold">
                  INVOICE NO.
                </th>
                <th className="px-3 py-4 text-left font-semibold">
                  ORDER TIME
                </th>
                <th className="px-3 py-4 text-left font-semibold">
                  CUSTOMER NAME
                </th>
                <th className="px-3 py-4 text-left font-semibold">METHOD</th>
                <th className="px-3 py-4 text-left font-semibold">AMOUNT</th>
                <th className="px-3 py-4 text-center font-semibold">View</th>
                {/* <th className="px-3 py-4 text-left font-semibold">Status</th> */}
                <th className="px-3 py-4 text-center pe-6 font-semibold">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-500">
              {!fetchOrdersPending &&
                filterData?.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className="border-b border-gray-200 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                    >
                      <td className="ps-6 px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 ">{item?.id}</div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5">
                          {moment(item?.created_at).format(
                            "MMM D, YYYY h:mm A"
                          )}
                          <br />
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 capitalize">
                          {item?.name}
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap uppercase">
                        <div className="text-sm leading-5">
                          {item?.payment_mode}
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 ">
                          &#8377;{item?.total_amount}
                        </div>
                      </td>
                      <td className="px-3 py-4 text-xl text-wk-align">
                        <Link to={`/orders/viewOrder/${item?.id}`}>
                          <BsFillEyeFill />
                        </Link>
                      </td>
                      {/* <td className="px-3 py-2 whitespace-nowrap text-sm ">
                          {item?.status === "Pending" && (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                              Pending
                            </span>
                          )}
                          {item?.status === "Processing" && (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                              Processing
                            </span>
                          )}
                          {item?.status === "Cancel" && (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 mr-2 py-0.5 dark:bg-red-900 dark:text-red-300">
                              Canceled
                            </span>
                          )}
                          {item?.status === "Delivered" && (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 mr-2 py-0.5 dark:bg-green-900 dark:text-green-300">
                              Delivered
                            </span>
                          )}
                          {item?.status === "Shipped" && (
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 mr-2 py-0.5 dark:bg-green-900 dark:text-green-300">
                              Shipped
                            </span>
                          )}
                        </td> */}
                      <td className="pe-1 sm:pe-6 px-1 sm:px-3 py-2 whitespace-nowrap">
                        {/* <button
                            onClick={() => chnageStatusHandler(item?.id)}
                            type="button"
                            style={{
                              background: currentColor,
                            }}
                            className="flex items-center gap-1 focus:outline-none text-white font-medium rounded-lg text-sm px-3 py-2 hover:opacity-80"
                          >
                            <MdOutlinePublishedWithChanges className="text-xl" />{" "}
                            Change Status
                          </button> */}

                        <div className="flex gap-2 justify-center">
                          <MdTaskAlt
                            onClick={() => setAcceptModalForm(item)}
                            className="text-green-500 text-2xl cursor-pointer"
                          />
                          <MdOutlineCancel
                            onClick={() => setRejectModal(item)}
                            className="text-red-500 text-2xl cursor-pointer"
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {fetchOrdersPending && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    colSpan={8}
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
              {!fetchOrdersPending && !filterData?.length && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td colSpan={8} className="p-4 py-2 ">
                    <div className="flex justify-center items-left md:items-center flex-col md:flex-row">
                      <img
                        className="max-h-56 w-56 sm:max-w-[450px] sm:max-h-[450px]"
                        src={NotFoundImg}
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
              )}
              {!fetchOrdersPending && filterData?.length ? (
                <tr>
                  <td colSpan={8}>
                    <div className="flex justify-between px-4 my-2 items-left md:items-center flex-col md:flex-row">
                      <div className="whitespace-nowrap">
                        <span className="text-sm text-gray-700 dark:text-gray-400">
                          Showing{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {filterData?.length > 0
                              ? currentPage * parseInt(parPageCount) + 1
                              : null}
                          </span>{" "}
                          to{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {Math.min(
                              (currentPage + 1) * parseInt(parPageCount),
                              orders?.length
                            ) || null}
                          </span>{" "}
                          of{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {orders?.length || null}
                          </span>{" "}
                          Entries
                        </span>
                      </div>
                      <ReactPaginate
                        previousLabel={<GrFormPrevious />}
                        nextLabel={<GrFormNext />}
                        breakLabel={"..."}
                        initialPage={currentPage}
                        pageCount={Math.ceil(orders?.length / parPageCount)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "items-center inline-flex space-x-2"
                        }
                        pageClassName={
                          "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
                        previousLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        disabledLinkClassName={"text-gray-400 opacity-50"}
                        nextLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
                        activeLinkClassName={
                          "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
                        }
                      />
                    </div>
                  </td>
                </tr>
              ): null}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
const NotConfiremedOrders = () => {
  const [parPageCount, setParPageCount] = useState(5);
  const [searchQuerry, setSearchQuerry] = useState("");
  // const [orders, setOrders] = useState([]);
  const [filtered, setFiltered] = useState([]);

  const { state: ContextState, fetchNotConfiredOrders } =
    useContext(ApisContext);
  // const { orders, fetchOrdersPending } = ContextState;
  const { notConfiredOrders } = ContextState;
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;

  useEffect(() => {
    fetchNotConfiredOrders(user);
  }, []);

  // useEffect(() => {
  //   if (notConfiredOrders) {
  //     // const filter = notConfiredOrders.filter((order) => order.status === "Pending");
  //     setOrders([...notConfiredOrders]);
  //   } else {
  //     setOrders([]);
  //   }
  // }, [notConfiredOrders]);
  useEffect(() => {
    filterOrders();
  }, [notConfiredOrders]);
  useEffect(() => {
    filterOrders();
  }, [searchQuerry, notConfiredOrders]);

  const filterOrders = () => {

    if (notConfiredOrders && searchQuerry !== "") {
      const filteredOrders = notConfiredOrders?.filter(
        (order) =>
          order.name.toLowerCase().includes(searchQuerry.toLowerCase()) ||
          order.id.toString().toLowerCase().includes(searchQuerry.toLowerCase())
      );
      setFiltered([...filteredOrders]);
    } else {
      setFiltered([...notConfiredOrders]);
    }
  };
  return (
    <div className="px-5  sm:px-10 sm:mt-8 mt-24">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-3">
        Not Confirmed Orders
      </div>

      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-5 border ">
        <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input autoComplete="off"
              type="text"
              onChange={(e) => setSearchQuerry(e.target.value)}
              id="simple-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
            />
          </div>
          {/*<select
            id="status"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option>Status</option>
            <option value="US">Pending</option>
            <option value="CA">Delivered</option>
            <option value="FR">Processing</option>
            <option value="DE">Cancel</option>
  </select> */}
          <select
            id="limit"
            onChange={(e) => {
              setParPageCount(e.target.value);
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option defaultValue disabled>
              Order limits
            </option>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        {/*<div className="mt-3 flex flex-wrap sm:flex-nowrap justify-between items-center gap-2">
          <div className="w-full">
            <small className="text-gray-900 dark:text-gray-200 text-sm">
              Select From to End Date
            </small>
            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ">
              <Datepicker value={value} onChange={handleValueChange} />
            </div>
          </div>
          <button
            type="button"
            className="focus:outline-none whitespace-nowrap w-full sm:w-fit sm:mt-5 justify-center text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-base px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 flex items-center gap-2"
          >
            <div className="text-2xl">
              <BiCloudDownload />
            </div>
            Download All Orders
          </button>
  </div> */}
      </div>
      <Paging orders={filtered} parPageCount={parPageCount} />
    </div>
  );
};

export default NotConfiremedOrders;
