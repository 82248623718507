import React, { useContext, useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { HiOutlineChevronRight } from "react-icons/hi";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useStateContext } from "../contexts/ContextProvider";

import { GrFormNext, GrFormPrevious } from "react-icons/gr";

import ReactPaginate from "react-paginate";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";

const CardMain = ({ item }) => {
  const { setChangeStatusBookRequests } =
    useStateContext();
  return (
    <>
      <div
        onClick={() => setChangeStatusBookRequests(item)}
        className="relative cursor-pointer shadow-lg bg-white dark:bg-gray-600 p-2 rounded-lg"
      >
        <div className="flex gap-2 items-center">
          {item?.image ? (
            <div>
              <img
                className="h-[80px] w-[70px] min-h-[80px] min-w-[70px] rounded object-cover"
                src={item?.image_url}
                alt=""
              />
            </div>
          ) : null}
          <div>
            <p className="text-sm capitalize">
              <span className="font-semibold">Name: </span> {item?.name}
            </p>
            <p className="text-sm capitalize">
              <span className="font-semibold">Title: </span> {item?.title}
            </p>
            <p className="text-sm capitalize">
              <span className="font-semibold">Status: </span> {item?.status}
            </p>
            <p className="text-sm">
            <span className="font-semibold">Description: </span> {item?.description}
          </p>
          </div>
        </div>
        <div className="mt-1">
          <p className="text-sm">
            <span className="font-semibold">Note: </span> {item?.message}
          </p>
        </div>
      </div>
    </>
  );
};

const BooksRequests = () => {
  const { currentColor } = useStateContext();
  const [selectedStatus, setSelectedStatus] = useState("pending");
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { state: ContextState, fetchBookRequests } = useContext(ApisContext);
  const { fetchBookRequestsPending, allBookRequests, bookRequests } =
    ContextState;
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;

  useEffect(() => {
    if (allBookRequests) {
      const total = allBookRequests?.total;
      const limit = allBookRequests?.per_page;
      setpageCount(Math.ceil(total / limit));
      setCurrentPage(parseInt(bookRequests?.current_page) + 1);
      
    }
  }, [allBookRequests, bookRequests]);


  const handlePageClick = (data) => {
    let page;
    if (data.selected >= 0) {
      page = data.selected + 1;
      fetchBookRequests(user, page, selectedStatus);
    }
  };

  useEffect(() => {
    fetchBookRequests(user, null, selectedStatus);
  }, [selectedStatus]);

  return (
    <div className="mt-24 sm:mt-24 md:mt-5 px-5 sm:px-8 md:px-14">
      <div className="relative border border-gray-400 bg-white dark:bg-gray-600 rounded p-2  flex flex-col justify-between leading-normal">
        <div className="flex gap-2 items-left md:items-center flex-col md:flex-row">
          <select
            id="status"
            defaultChecked={selectedStatus}
            onChange={(e) => {
              setSelectedStatus(e.target.value);
            }}
            className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option value="pending">Pending</option>
            <option value="approved">Approved</option>
            <option value="rejected">Rejected</option>
          </select>
        </div>
      </div>
      {(allBookRequests !== null || bookRequests !== null) && (
        <div className="flex justify-between items-left md:items-center flex-col md:flex-row my-3 px-2">
          <div className="whitespace-nowrap">
            <span className="text-sm text-gray-700 dark:text-gray-400">
              Showing{" "}
              <span className="font-semibold text-gray-900 dark:text-white">
                {allBookRequests?.from}
              </span>{" "}
              to{" "}
              <span className="font-semibold text-gray-900 dark:text-white">
                {allBookRequests?.to}
              </span>{" "}
              of{" "}
              <span className="font-semibold text-gray-900 dark:text-white">
                {allBookRequests?.total}
              </span>{" "}
              Entries
            </span>
          </div>
          <div className="hidden md:flex justify-center md:justify-end">
            <ReactPaginate
              previousLabel={<GrFormPrevious />}
              nextLabel={<GrFormNext />}
              breakLabel={"..."}
              initialPage={currentPage}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={" items-center inline-flex space-x-2"}
              pageClassName={
                "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
              }
              pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
              previousLinkClassName={
                "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
              }
              disabledLinkClassName={"text-gray-400 opacity-50"}
              nextLinkClassName={
                "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
              }
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
              activeLinkClassName={
                "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
              }
            />
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mb-3">
        {!fetchBookRequestsPending &&
          bookRequests?.map((item, i) => {
            // console.log(item, "request");
            return <CardMain key={i} item={item} />;
          })}
      </div>
      {fetchBookRequestsPending && (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mb-3">
          <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
              <div className="rounded bg-slate-300 dark:bg-slate-700 h-[80px] w-[70px]"></div>
              <div className="flex-1 space-y-2 py-1">
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="animate-pulse">
            <div className="flex-1 space-y-2 py-1">
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
              <div className="rounded bg-slate-300 dark:bg-slate-700 h-[80px] w-[70px]"></div>
              <div className="flex-1 space-y-2 py-1">
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="animate-pulse">
            <div className="flex-1 space-y-2 py-1">
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
              <div className="rounded bg-slate-300 dark:bg-slate-700 h-[80px] w-[70px]"></div>
              <div className="flex-1 space-y-2 py-1">
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="animate-pulse">
            <div className="flex-1 space-y-2 py-1">
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
              <div className="rounded bg-slate-300 dark:bg-slate-700 h-[80px] w-[70px]"></div>
              <div className="flex-1 space-y-2 py-1">
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="animate-pulse">
            <div className="flex-1 space-y-2 py-1">
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
              <div className="rounded bg-slate-300 dark:bg-slate-700 h-[80px] w-[70px]"></div>
              <div className="flex-1 space-y-2 py-1">
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="animate-pulse">
            <div className="flex-1 space-y-2 py-1">
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
            <div className="animate-pulse flex space-x-4">
              <div className="rounded bg-slate-300 dark:bg-slate-700 h-[80px] w-[70px]"></div>
              <div className="flex-1 space-y-2 py-1">
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="animate-pulse">
            <div className="flex-1 space-y-2 py-1">
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                <div className="space-y-3">
                  <div className="grid grid-cols-3 gap-4">
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                    <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
       
        
        </div>
      )}

      {(allBookRequests !== null || bookRequests !== null) && (
        <div className="flex md:hidden mt-3 justify-between items-left md:items-center flex-col md:flex-row my-3 px-2">
          <div className="whitespace-nowrap">
            <span className="hidden text-sm text-gray-700 dark:text-gray-400">
              Showing{" "}
              <span className="font-semibold text-gray-900 dark:text-white">
                {allBookRequests?.from}
              </span>{" "}
              to{" "}
              <span className="font-semibold text-gray-900 dark:text-white">
                {allBookRequests?.to}
              </span>{" "}
              of{" "}
              <span className="font-semibold text-gray-900 dark:text-white">
                {allBookRequests?.total}
              </span>{" "}
              Entries
            </span>
          </div>
          <div className="flex justify-center md:justify-end">
            <ReactPaginate
              previousLabel={<GrFormPrevious />}
              nextLabel={<GrFormNext />}
              breakLabel={"..."}
              initialPage={currentPage}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={" items-center inline-flex space-x-2"}
              pageClassName={
                "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
              }
              pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
              previousLinkClassName={
                "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
              }
              disabledLinkClassName={"text-gray-400 opacity-50"}
              nextLinkClassName={
                "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
              }
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
              activeLinkClassName={
                "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default BooksRequests;
