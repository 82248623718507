import React, { useContext, useEffect, useState } from "react";
import { FaUserCircle, FaWindowClose } from "react-icons/fa";
import { HiOutlineChevronRight } from "react-icons/hi";
import { MdDeleteForever } from "react-icons/md";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useStateContext } from "../contexts/ContextProvider";

import { GrFormNext, GrFormPrevious } from "react-icons/gr";

import ReactPaginate from "react-paginate";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import NotFoundImg from "../assets/No data-pana.png";
import { Button } from "../components";
import { toast } from "react-toastify";
import axios from "axios";

import { useForm } from "react-hook-form";

const BASE_URL = window.config.API_URL;

const CardMain = ({ item }) => {
  const { setSuppoertChatId } = useStateContext();

  const navigate = useNavigate();
  const redirectHandler = (item) => {
    // suppoertChatId,
    // console.log(item ,"navigate successfully to chat")
    setSuppoertChatId(item);
    navigate(`/customersSupport/replyToCustomer/${item?.id}`);
  };
  return (
    <div
      onClick={() => redirectHandler(item)}
      className="relative cursor-pointer shadow-lg bg-white dark:bg-gray-600 p-2 rounded-lg  leading-normal"
    >
      {/* <div className="absolute h-3 w-3 rounded-full border right-3 bottom-3 flex items-center justify-center text-xl text-green-700 border-green-700">
          <button onClick={() => chnageStatusHandler(item)}>
            <MdOutlinePublishedWithChanges />
          </button>
        </div> */}
      {item?.support_chat?.length ? (
        item?.support_chat?.filter((chat) => chat.status === "unread")
          .length ? (
          <div
            className={`text-white bg-blue-500 absolute  rounded-full flex justify-center items-center text-center ${
              item?.support_chat?.length &&
              item?.support_chat?.filter((chat) => chat.status === "unread")
                .length
                ? "h-5 w-5 -right-2.5 -top-2.5"
                : " h-2.5 w-2.5 -right-1 -top-1"
            }`}
          >
            <p className="leading-none text-xs">
              {" "}
              {item?.support_chat?.length &&
              item?.support_chat?.filter((chat) => chat.status === "unread")
                .length
                ? item?.support_chat?.filter((chat) => chat.status === "unread")
                    .length
                : null}
            </p>
          </div>
        ) : null
      ) : item?.status === "Pending" ? (
        <div
          className={`text-white bg-blue-500 absolute  rounded-full flex justify-center items-center text-center ${
            item?.support_chat?.length &&
            item?.support_chat?.filter((chat) => chat.status === "unread")
              .length
              ? "h-5 w-5 -right-2.5 -top-2.5"
              : " h-2.5 w-2.5 -right-1 -top-1"
          }`}
        >
          <p className="leading-none text-xs">
            {" "}
            {item?.support_chat?.length &&
            item?.support_chat?.filter((chat) => chat.status === "unread")
              .length
              ? item?.support_chat?.filter((chat) => chat.status === "unread")
                  .length
              : null}
          </p>
        </div>
      ) : null}
      <div className="w-full">
        <div className="flex justify-between items-center">
          <div className="w-full flex items-center gap-3 ">
            <div className="flex flex-col justify-center text-center items-center gap-1">
              <div className="text-4xl dark:text-gray-300">
                <FaUserCircle />
              </div>
            </div>
            <div className="text-gray-900 dark:text-gray-50  leading-none text-base">
              <h6 className="font-bold">
                {(item?.title).length > 20
                  ? (item?.title).slice(0, 19) + "..."
                  : item?.title}
              </h6>
              <p className="text-gray-700 dark:text-gray-200 text-sm leading-tight overflow-hidden">
                {(item?.description).length > 20
                  ? (item?.description).slice(0, 20) + "..."
                  : item?.description}
              </p>
            </div>
          </div>
          <div className="flex flex-col items-end dark:text-gray-50">
            <HiOutlineChevronRight className="text-2xl" />
          </div>
        </div>
        <div className=" flex justify-between mt-1 px-2">
          <p className="text-gray-900 text-xs dark:text-gray-50 leading-none capitalize">
            {item?.name}
          </p>
          <p className="text-gray-600 text-xs whitespace-nowrap dark:text-gray-300">
            {moment(item.created_at).format("HH:mm | DD MMM")}
          </p>
        </div>
      </div>
    </div>
  );
};

const Paging = ({ users, parPageCount }) => {
  const BASE_URL = window.config.API_URL;

  const { setSuppoertChatId } = useStateContext();
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const redirectHandler = (item) => {
    // suppoertChatId,
    // console.log(item ,"navigate successfully to chat")
    setSuppoertChatId(item);
    navigate(`/customersSupport/replyToCustomer/${item?.id}`);
  };
  const { setChnageStatus, currentColor } = useStateContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState([]);

  const { state: ContextState, setPendingFun } = useContext(ApisContext);
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;

  const { fetchCustomersPending, isPending } = ContextState;

  // console.log(Math.ceil(users?.length / parPageCount), 'math.ceil(users?.length / pageCount', users?.length)
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    const startIndex = parseInt(currentPage) * parseInt(parPageCount);
    const endIndex = parseInt(startIndex) + parseInt(parPageCount);
    setFilterData(users?.slice(startIndex, endIndex));
  }, [currentPage, users, parPageCount]);

  const startChat = async (userData) => {
    try {
      const tokenStr = user.token;
      setPendingFun(true);
      const data = {};
      data.title = userData.title;
      data.description = userData.description;
      data.name = selectedUser?.name;
      data.email = selectedUser?.email;
      data.user_id = selectedUser?.id;
      const response = await axios.post(
        BASE_URL + "admin_request_support",
        data,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      if (response.status === 200) {
        setPendingFun(false);
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          redirectHandler({
            ...response.data.data,
            status: "Pending",
            support_chat: [],
          });
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        setPendingFun(false);
        toast.dismiss();
        toast.error("Request Error: Chat not started");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
      setPendingFun(false);
    } catch (error) {
      setPendingFun(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        // console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  return (
    <>
      {selectedUser ? (
        <div
          style={{ zIndex: 999999999 }}
          className="fixed flex justify-center items-center inset-0 bg-black bg-opacity-50 p-2"
        >
          <div className="bg-white rounded-md h-full w-full sm:w-3/4 md:w-1/2 p-3 md:p-5 ">
            <div className="flex justify-end p-1">
              <FaWindowClose
                onClick={() => setSelectedUser(null)}
                className="text-xl cursor-pointer text-gray-800"
              />
            </div>
            <form
              onSubmit={handleSubmit(startChat)}
              className="h-[90%] flex flex-col justify-between"
            >
              <div>
                <div className="mb-4">
                  <label
                    htmlFor="title"
                    className="mb-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Title
                  </label>

                  <input
                    autoComplete="off"
                    type="text"
                    {...register("title", {
                      required: {
                        value: true,
                        message: "title required",
                      },
                    })}
                    placeholder="Enter title"
                    // onChange={handleDateChange}
                    className={`border text-sm rounded block w-full p-1.5 ${
                      errors.title
                        ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                        : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    } `}
                  />

                  {errors.title && (
                    <small className="text-sm text-red-600 dark:text-red-500">
                      {errors.title?.message}
                    </small>
                  )}
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="description"
                    className="mb-3 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Description
                  </label>

                  <input
                    autoComplete="off"
                    type="text"
                    {...register("description", {
                      required: {
                        value: true,
                        message: "Description required",
                      },
                    })}
                    placeholder="Enter description"
                    // onChange={handleDateChange}
                    className={`border text-sm rounded block w-full p-1.5 ${
                      errors?.description
                        ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                        : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    } `}
                  />

                  {errors?.description && (
                    <small className="text-sm text-red-600 dark:text-red-500">
                      {errors?.description?.message}
                    </small>
                  )}
                </div>
              </div>
              <button
                type="submit"
                disabled={isPending}
                style={{
                  background: currentColor,
                }}
                className="w-full disabled:opacity-60 text-white focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              >
                Save
              </button>
            </form>
          </div>
        </div>
      ) : null}
      <div className="">
        <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
          <table className="min-w-full px-4">
            <thead>
              <tr className="bg-gray-300 dark:bg-gray-600 dark:text-gray-50 border-b border-gray-200 dark:border-gray-700 text-sm leading-4 uppercase tracking-wider whitespace-nowrap">
                <th className="ps-6 px-3 py-4 text-left font-semibold">ID.</th>
                <th className="px-3 py-4 text-left font-semibold">
                  CUSTOMER NAME
                </th>
                <th className="px-3 py-4 text-left font-semibold">Email</th>
                <th className="px-3 py-4 text-left font-semibold">Phone no.</th>
                <th className="px-3 py-4 text-left font-semibold">
                  Registered on
                </th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-500">
              {!fetchCustomersPending &&
                filterData?.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      onClick={() => setSelectedUser(item)}
                      className="border-b cursor-pointer border-gray-200 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                    >
                      <td className="ps-6 px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 ">{item?.id}</div>
                      </td>

                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 capitalize">
                          {item?.name}
                        </div>
                      </td>

                      <td className="px-3 py-4 text-xl ">
                        <div className="text-sm leading-5 capitalize">
                          {item?.email}
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm ">
                        <div className="text-sm leading-5 capitalize">
                          {item?.phone_number}
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm ">
                        <div className="text-sm leading-5 capitalize">
                          {moment(item?.created_at).format("DD MMM YYYY")}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {fetchCustomersPending && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    colSpan={8}
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
              {!fetchCustomersPending && !filterData?.length && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td colSpan={8} className="p-4 py-2 ">
                    <div className="flex justify-center items-left md:items-center flex-col md:flex-row">
                      <img
                        className="max-h-56 w-56 sm:max-w-[450px] sm:max-h-[450px]"
                        src={NotFoundImg}
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
              )}
              {!fetchCustomersPending && filterData?.length ? (
                <tr>
                  <td colSpan={8}>
                    <div className="flex justify-between m-4 my-2 items-left md:items-center flex-col md:flex-row">
                      <div className="whitespace-nowrap">
                        <span className="text-sm text-gray-700 dark:text-gray-400">
                          Showing{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {filterData?.length > 0
                              ? currentPage * parPageCount + 1
                              : 0}
                          </span>{" "}
                          to{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {Math.min(
                              (currentPage + 1) * parPageCount,
                              users?.length
                            )
                              ? Math.min(
                                  (currentPage + 1) * parPageCount,
                                  users?.length
                                )
                              : ""}
                          </span>{" "}
                          of{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {users?.length ? users?.length : ""}
                          </span>{" "}
                          Entries
                        </span>
                      </div>
                      <ReactPaginate
                        previousLabel={<GrFormPrevious />}
                        nextLabel={<GrFormNext />}
                        breakLabel={"..."}
                        initialPage={currentPage}
                        pageCount={Math.ceil(users?.length / parPageCount)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "items-center inline-flex space-x-2"
                        }
                        pageClassName={
                          "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
                        previousLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        disabledLinkClassName={"text-gray-400 opacity-50"}
                        nextLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
                        activeLinkClassName={
                          "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
                        }
                      />
                    </div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const CustomerSupport = () => {
  const { currentColor } = useStateContext();
  const [selectedStatus, setSelectedStatus] = useState("Pending");
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [parPageCount, setParPageCount] = useState(10);
  const [searchQuerry, setSearchQuerry] = useState("");
  const [filtered, setFiltered] = useState([]);

  const [chooseCustomerModel, setChooseCustomerModel] = useState(false);
  const {
    state: ContextState,
    fetchRequests,
    fetchCustomers,
  } = useContext(ApisContext);
  const { fetchRequestsPending, allRequests, requests, customers } =
    ContextState;
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;

  useEffect(() => {
    fetchCustomers(user);
  }, []);

  useEffect(() => {
    if (chooseCustomerModel) {
      fetchCustomers(user);
    }
  }, [chooseCustomerModel]);

  useEffect(() => {
    filterCustomers();
  }, [searchQuerry, customers, parPageCount]);

  const filterCustomers = () => {
    if (searchQuerry !== "") {
      // Filter orders based on selected status and search query
      const filteredOrders = customers?.filter(
        (customer) =>
          customer.name.toLowerCase().includes(searchQuerry.toLowerCase()) ||
          customer.email.toLowerCase().includes(searchQuerry.toLowerCase()) ||
          customer.phone_number
            .toString()
            .toLowerCase()
            .includes(searchQuerry.toLowerCase()) ||
          customer.id
            .toString()
            .toLowerCase()
            .includes(searchQuerry.toLowerCase())
      );
      if (filteredOrders) {
        setFiltered([...filteredOrders]);
      } else {
        setFiltered([]);
      }
    } else {
      setFiltered(customers);
    }
  };

  useEffect(() => {
    if (allRequests) {
      const total = allRequests?.total;
      const limit = allRequests?.per_page;
      setpageCount(Math.ceil(total / limit));
      setCurrentPage(parseInt(requests?.current_page) + 1);
      // console.log(allRequests?.current_page, allRequests, requests, "allRequests, requests", parseInt(requests?.current_page)+1)
    }
  }, [allRequests, requests]);

  const handlePageClick = (data) => {
    let page;
    if (data.selected >= 0) {
      page = data.selected + 1;
      // console.log(page, "page");
      fetchRequests(user, page, selectedStatus);
    }
  };

  useEffect(() => {
    if (selectedStatus === "All") {
      fetchRequests(user, null, null);
      // console.log(selectedStatus);
    } else {
      fetchRequests(user, null, selectedStatus);
    }
  }, [selectedStatus]);

  return (
    <>
      {" "}
      {chooseCustomerModel && (
        <div
          style={{ zIndex: 99999 }}
          className="fixed inset-0 bg-black bg-opacity-30 p-5 flex justify-center items-center"
        >
          <div className="bg-white rounded-md h-full w-full sm:w-3/4 md:w-1/2">
            <div className="flex p-3 justify-between items-center border-b">
              <p className="text-gray-700 dark:text-gray-50">Choose Customer</p>
              <div>
                <FaWindowClose
                  onClick={() => setChooseCustomerModel(false)}
                  className="text-xl cursor-pointer text-gray-800"
                />
              </div>
            </div>
            <div className="h-[90.1%] overflow-y-auto">
              <div className="px-3">
                <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-1 border ">
                  <div className="h-full flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
                    <div className="relative w-full">
                      <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5 text-gray-500 dark:text-gray-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                            clipRule="evenodd"
                          ></path>
                        </svg>
                      </div>
                      <input
                        autoComplete="off"
                        type="text"
                        onChange={(e) => setSearchQuerry(e.target.value)}
                        id="simple-search"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Search"
                      />
                    </div>
                    <select
                      id="limit"
                      onChange={(e) => {
                        setParPageCount(e.target.value);
                      }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option defaultValue disabled>
                        Customers limits
                      </option>
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                </div>
                <Paging users={filtered} parPageCount={parPageCount} />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-24 sm:mt-24 md:mt-5 px-5 sm:px-8 md:px-14">
        <div className="relative border border-gray-400 bg-white dark:bg-gray-600 rounded p-2  flex flex-col justify-between leading-normal">
          <div className="flex gap-2 items-left md:items-center flex-col md:flex-row ">
            <select
              id="status"
              defaultChecked={selectedStatus}
              onChange={(e) => {
                setSelectedStatus(e.target.value);
              }}
              className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option defaultValue="Pending">Pending</option>
              <option value={"All"}>All</option>
              <option value="Complete">Complete</option>
            </select>
            <div>
              {" "}
              <Button
                onClick={() => setChooseCustomerModel(true)}
                color="white"
                bgColor={currentColor}
                text="New Chat"
                classes={"px-4 py-2 whitespace-nowrap"}
                borderRadius="10px"
                width="full"
              />
            </div>
          </div>
        </div>
        {(allRequests !== null || requests !== null) && (
          <div className="flex justify-between items-left md:items-center flex-col md:flex-row my-3 px-2">
            <div className="whitespace-nowrap">
              <span className="text-sm text-gray-700 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {allRequests?.from}
                </span>{" "}
                to{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {allRequests?.to}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {allRequests?.total}
                </span>{" "}
                Entries
              </span>
            </div>
            <div className="hidden md:flex justify-center md:justify-end">
              <ReactPaginate
                previousLabel={<GrFormPrevious />}
                nextLabel={<GrFormNext />}
                breakLabel={"..."}
                initialPage={currentPage}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={" items-center inline-flex space-x-2"}
                pageClassName={
                  "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                }
                pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
                previousLinkClassName={
                  "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                }
                disabledLinkClassName={"text-gray-400 opacity-50"}
                nextLinkClassName={
                  "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                }
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
                activeLinkClassName={
                  "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
                }
              />
            </div>
          </div>
        )}

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mb-3">
          {!fetchRequestsPending &&
            requests?.map((item, i) => {
              // console.log(item, "request");
              return <CardMain key={i} item={item} />;
            })}
        </div>
        {fetchRequestsPending && (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 mb-3">
            <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
              <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-300 dark:bg-slate-700 h-10 w-10"></div>
                <div className="flex-1 space-y-2 py-1">
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
              <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-300 dark:bg-slate-700 h-10 w-10"></div>
                <div className="flex-1 space-y-2 py-1">
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
              <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-300 dark:bg-slate-700 h-10 w-10"></div>
                <div className="flex-1 space-y-2 py-1">
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
              <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-300 dark:bg-slate-700 h-10 w-10"></div>
                <div className="flex-1 space-y-2 py-1">
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
              <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-300 dark:bg-slate-700 h-10 w-10"></div>
                <div className="flex-1 space-y-2 py-1">
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
              <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-300 dark:bg-slate-700 h-10 w-10"></div>
                <div className="flex-1 space-y-2 py-1">
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
              <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-300 dark:bg-slate-700 h-10 w-10"></div>
                <div className="flex-1 space-y-2 py-1">
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
              <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-300 dark:bg-slate-700 h-10 w-10"></div>
                <div className="flex-1 space-y-2 py-1">
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-500 dark:bg-gray-800  shadow rounded-md p-4 max-w-sm w-full mx-auto">
              <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-slate-300 dark:bg-slate-700 h-10 w-10"></div>
                <div className="flex-1 space-y-2 py-1">
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded"></div>
                  <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                  <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-2"></div>
                      <div className="h-2 bg-slate-300 dark:bg-slate-700 rounded col-span-1"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {(allRequests !== null || requests !== null) && (
          <div className="flex md:hidden mt-3 justify-between items-left md:items-center flex-col md:flex-row my-3 px-2">
            <div className="whitespace-nowrap">
              <span className="hidden text-sm text-gray-700 dark:text-gray-400">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {allRequests?.from}
                </span>{" "}
                to{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {allRequests?.to}
                </span>{" "}
                of{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                  {allRequests?.total}
                </span>{" "}
                Entries
              </span>
            </div>
            <div className="flex justify-center md:justify-end">
              <ReactPaginate
                previousLabel={<GrFormPrevious />}
                nextLabel={<GrFormNext />}
                breakLabel={"..."}
                initialPage={currentPage}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={" items-center inline-flex space-x-2"}
                pageClassName={
                  "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                }
                pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
                previousLinkClassName={
                  "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                }
                disabledLinkClassName={"text-gray-400 opacity-50"}
                nextLinkClassName={
                  "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                }
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
                activeLinkClassName={
                  "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
                }
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomerSupport;
