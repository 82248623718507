import { createRoot } from "react-dom/client";
import React from "react";
import "./index.css";
import App from "./App";
import { ContextProvider } from "./contexts/ContextProvider";
import { AuthContextProvider } from "./contexts/Auth.Context";
import { ApiContextProvider } from "./contexts/ApiConterxt";
import { BrowserRouter } from "react-router-dom";


const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <BrowserRouter>
  <ContextProvider>
    <AuthContextProvider>
      <ApiContextProvider>
        <App tab="home" />
      </ApiContextProvider>
    </AuthContextProvider>
  </ContextProvider>
  </BrowserRouter>
);
