/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { useSetState } from "react-use";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useStateContext } from "../contexts/ContextProvider";

import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const AddCuppons = () => {
  const { currentColor } = useStateContext();

  const BASE_URL = window.config.API_URL;
  const navigate = useNavigate();

  const {
    register,
    handleSubmit, watch,
    formState: { errors },
  } = useForm();

  const { state: ContextState, setPendingFun } = useContext(ApisContext);
  const { products } = ContextState;
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;
  const [value, setEditorValue] = useState('');

  const [typeValue, setTypeValue] = useState(watch("type"));

  useEffect(() => {
    setTypeValue(watch("type"));
  }, [watch('type')])

  const submitHandler = async (data) => {
    setPendingFun(true);
    // console.log(data, "submit");
    const formData = new FormData();
    // formData.append("name", data.name);
    formData.append("name", data.name);
    formData.append("coupon_code", data.code);
    formData.append("discount", data.discount);
    formData.append("required_coin", data.required_coin);
    formData.append("is_active", data.status === "Active" ? 1 : 0);
    formData.append("type", data.type);
    formData.append("description", value);
    if (data.type === "special_coupon") {
      formData.append("min_value", data.min_value || null);
      formData.append("up_to_discount", data.up_to_discount || null);
    }

   

    try {
      const tokenStr = user.token;

      const response = await axios.post(BASE_URL + "coupons", formData, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      // setPending(false);
      setPendingFun(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.success("Coupon successfully added!");
          navigate("/coupons");
          return response.data;
        } else if (response.data.status === "error") {
          setPendingFun(false);

          toast.error(response.data.message);
        } else {
          setPendingFun(false);
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        setPendingFun(false);

        toast.error("Request Error: coupon not added");
      } else if (response.status === 401) {
        setPendingFun(false);

        toast.error("Unauthorized: Please log in again");
      } else {
        setPendingFun(false);

        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPendingFun(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.error(error.response.data.data);
      }
    }
  };

  return (
    <div className="mt-24 sm:mt-8 md:mt-5 px-5 sm:px-8 md:px-14">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-5">
        Add Coupon
      </div>
      <form onSubmit={handleSubmit((data) => submitHandler(data))} noValidate>
        <div className="grid gap-4 mb-6 md:grid-cols-2">
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Name
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="text"
              id="name"
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.name
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Name"
              {...register("name", {
                required: {
                  value: true,
                  message: "Name required",
                },
              })}
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.name?.message}
            </small>
          </div>
          <div className="mb-3">
            <label
              htmlFor="code"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Coupon Code
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="text"
              id="code"
              rows={5}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.code
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Coupon Code"
              {...register("code", {
                required: {
                  value: true,
                  message: "Code required",
                },
              })}
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.code?.message}
            </small>
          </div>
          <div className="mb-3">
            <label
              htmlFor="discount"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Discount
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="number"
              id="discount"
              min={0}
              max={100}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.discount
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Coupon Discount"
              {...register("discount", {
                required: {
                  value: true,
                  message: "discount required",
                },
              })}
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.discount?.message}
            </small>
          </div>
          <div className="mb-3">
            <label
              htmlFor="status"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Status
              <span className="text-red-600 text-xs">*</span>
            </label>
            <select
              id="status"
              {...register("status", {
                required: {
                  value: true,
                  message: "Status required",
                },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.status
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
            >
              <option defaultValue={"Active"}>Active</option>
              <option value={"Inactive"}>Inactive</option>
            </select>
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.status?.message}
            </small>
          </div>
          <div className="mb-3">
            <label
              htmlFor="required_coin"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Coin
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="number"
              id="required_coin"
              min={0}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.required_coin
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Coupon Coin"
              {...register("required_coin", {
                required: {
                  value: true,
                  message: "Coin required",
                },
              })}
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.coin?.message}
            </small>
          </div>
          <div className="mb-3">
            <label
              htmlFor="status"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Type
              <span className="text-red-600 text-xs">*</span>
            </label>
            <select
              id="type"
              {...register("type", {
                required: {
                  value: true,
                  message: "Type required",
                },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.type
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
            >
              <option value={"lifetime"}>Lifetime</option>
              <option value={"first_order"}>First Order</option>
              <option value={"refferral"}>Refferral</option>
              <option value={"special_coupon"}>Special Coupon</option>
            </select>
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.type?.message}
            </small>
          </div>
          {typeValue === 'special_coupon' ? <div className="mb-3">
            <label
              htmlFor="up_to_discount"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Up to discount
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="number"
              id="up_to_discount"
              min={0}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.up_to_discount
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Coupon up to discount"
              {...register("up_to_discount", {
                required: {
                  value: true,
                  message: "up to discount required",
                },
              })}
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.up_to_discount?.message}
            </small>
          </div> :null }
          {typeValue === 'special_coupon' ? <div className="mb-3">
            <label
              htmlFor="min_value"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              min_value
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="number"
              id="min_value"
              min={0}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.min_value
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Coupon min value"
              {...register("min_value", {
                required: {
                  value: true,
                  message: "min value required",
                },
              })}
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.min_value?.message}
            </small>
          </div> :null }
        </div>
        <div className="mb-3">
          <label
            htmlFor="required_coin"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Description
            <span className="text-red-600 text-xs">*</span>
          </label>
          <div className="relative">
            <ReactQuill
              className={
                "dark:text-slate-50 rounded-2xl dark:placeholder:text-slate-50 h-[200px]"
              }
              theme="snow"
              placeholder={"Enter Description here"}
              value={value} onChange={setEditorValue}
              modules={modules}
              formats={formats}
            />
          </div>
          <small className="text-sm text-red-600 dark:text-red-500">
            {errors.description?.message}
          </small>
        </div>
        <div className="text-right mt-24 md:mt-16">
          <button
            style={{
              background: currentColor,
            }}
            type="submit"
            className=" text-white  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:opacity-80"
          >
            Add Coupon
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddCuppons;
