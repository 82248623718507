/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";

import { AuthContext } from "../contexts/Auth.Context";

import axios from "axios";
import { toast } from "react-toastify";

const ViewProduct = () => {
  let { productId } = useParams();
  productId = parseInt(productId);
  const [product, setProduct] = useState();
  const [loading, setLoading] = useState(false);
  const [fullImage, setFullImage] = useState(null);

  const { currentColor } = useStateContext();
  const BASE_URL = window.config.API_URL;
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);

  const { user } = AuthContextState;

  const navigate = useNavigate();

  const fetchProduct = async (productId, user) => {
    setLoading(true);
    try {
      const tokenStr = user.token;

      const response = await axios.get(BASE_URL + `products/${productId}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (response.status === 200) {
        if (response.data.Success === true) {
          // toast.success("Product successfully deleted!");
          setProduct(response.data.data);
          // console.log("product successfully getted", response.data.data);
          setLoading(false);
          return response.data;
        } else if (response.data.status === "error") {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.error("Request Error: Product not found");
      } else if (response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  function hexToRgb(hex) {
    // Remove the # character from the beginning of the hex value
    hex = hex.replace(/^#/, "");

    // Convert the hex value to a 6-digit string if it's a 3-digit string
    if (hex.length === 3) {
      hex = hex.replace(/(.)/g, "$1$1");
    }

    // Convert the hex value to RGB values
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);

    // Return the RGB values as an object
    return `${r}, ${g}, ${b}`;
  }

  useEffect(() => {
    fetchProduct(productId, user);
  }, []);

  return (
    <div className="mt-24 sm:mt-8 md:mt-5 px-5 sm:px-8 md:px-14">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-5">
        Book Details
      </div>

      {loading && (
        <div
          role="status"
          className="space-y-8 animate-pulse grid grid-cols-1 md:grid-cols-2 md:space-x-8 md:items-center"
        >
          <div className="flex items-center justify-center w-full h-56 bg-gray-300 rounded sm:w-full gap-8 dark:bg-gray-700">
            <svg
              className="w-12 h-12 text-gray-200"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 640 512"
            >
              <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
            </svg>
          </div>
          <div className="w-full">
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
            <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
          </div>

          <div className="w-full">
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
            <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
          </div>
          <div className="w-full">
            <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4"></div>
            <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[480px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 mb-2.5"></div>
            <div className="h-6 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[440px] mb-2.5"></div>
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[460px] mb-2.5"></div>
            <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700 max-w-[360px]"></div>
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {!loading && (
        <div className="grid gap-3 gap-x-6 mb-6 md:grid-cols-2">
          <div>
            <div>
              {!fullImage &&
                product?.product_image?.map(
                  (file, index) => index === 0 && setFullImage(file.image)
                )}
              <div className="max-h-56 flex justify-center relative">
                <img
                  src={fullImage}
                  className="object-contain min-h-[175px] h-full max-h-56 rounded-md"
                  alt=""
                />
              </div>
              <div className="flex gap-1.5 pb-0.5 pt-2 overflow-x-scroll ">
                {product?.product_image?.map((file, index) => {
                  return (
                    <img
                      key={index}
                      onClick={() => setFullImage(file.image)}
                      className="object-cover h-[4rem] max-h-16 border rounded-md"
                      src={file.image}
                      alt=""
                    />
                  );
                })}
              </div>
            </div>
          </div>
          <div>
            <div className="mb-4">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Book Name/Title
              </label>
              <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                {product?.title}
              </div>
            </div>
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Book Description
              </label>
              <textarea
                type="text"
                id="description"
                rows={5}
                className="border focus:outline-none text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                defaultValue={product?.description}
                readOnly={true}
              ></textarea>
            </div>
          </div>
          <div>
            <div className="mb-3">
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Categories
              </label>
              <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
                {product?.categories?.map((category, index, array) => (
                  <React.Fragment key={category.category_id}>
                    <p className="inline-block">{category.category_name}</p>
                    {index < array.length - 1 && <span>, </span>}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Book Price
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.price}
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Sale Price
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {Math.round(
                product?.price -
                  (product?.price * product?.discount_percent) / 100
              )}
            </div>
          </div>

          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Book Quantity
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.stock}
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Book Author
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.author}
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Number of Pages
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.page_count}
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Publishing Date
            </label>

            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.published_year}
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Discount %
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.discount_percent}
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Publisher
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.publisher}
            </div>
          </div>

          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Edition
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.edition}
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Language
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.language}
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Tax percent
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.tax_percent}
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Binding
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.binding ? product?.binding : "Not Defined"}
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              In flash sale
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.in_flash_sale ? "Yes" : "No"}
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              In top rated
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.in_top_rated ? "Yes" : "No"}
            </div>
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Average rating
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.avg_rating || "0"}
            </div>
          </div>
          {product?.delivery_charge ? <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Delivery Charge
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.delivery_charge || "0"}
            </div>
          </div> : null}
          {product?.measurements ? <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Measurements
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.measurements || "none"}
            </div>
          </div> : null}
          {product?.cost_price ? <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Cost Price
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.cost_price || "0"}
            </div>
          </div> : null}
          {product?.weight ? <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
            Weight
            </label>
            <div className="border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white">
              {product?.weight || "0"}
            </div>
          </div> : null}

          <div>
            <div className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Book Type
            </div>
            <div className="flex gap-4">
              {product?.is_old === "0" ? (
                <div className="relative flex flex-col bg-white dark:bg-gray-600 p-2 px-3 pe-9 rounded-full shadow-md cursor-pointer">
                  <span className="font-semibold text-gray-500 dark:text-gray-50 leading-tight uppercase ">
                    New
                  </span>
                  <span
                    style={{
                      border: `2px solid ${currentColor}`,
                    }}
                    className=" absolute inset-0 rounded-full"
                  >
                    <span
                      style={{
                        background: currentColor,
                        backgroundColor: `rgba(${hexToRgb(currentColor)}, 0.3)`,
                      }}
                      className="absolute top-1 right-1 h-6 w-6 inline-flex items-center justify-center rounded-full bg-green-200"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        style={{
                          color: currentColor,
                        }}
                        className="h-5 w-5 "
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
              ) : (
                <div className="relative flex flex-col bg-white dark:bg-gray-600 p-2 px-3 pe-9 rounded-full shadow-md cursor-pointer">
                  <span className="font-semibold text-gray-500 dark:text-gray-50 leading-tight uppercase">
                    Old
                  </span>
                  <span
                    style={{
                      border: `2px solid ${currentColor}`,
                    }}
                    className=" absolute inset-0 border-2  rounded-full"
                  >
                    <span
                      style={{
                        background: currentColor,
                        backgroundColor: `rgba(${hexToRgb(currentColor)}, 0.3)`,
                      }}
                      className="absolute top-1 right-1 h-6 w-6 inline-flex items-center justify-center rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        style={{
                          color: currentColor,
                        }}
                        className="h-5 w-5"
                      >
                        <path
                          fillRule="evenodd"
                          d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </span>
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewProduct;
