import React from "react";
import Chart from "react-apexcharts";


const Donut = (props) => {
  const data = props.data;
  return (
    <div className="SpLineArea dark:text-white">
      {data?.series?.length ? (<>
        <div className="flex gap-2 justify-center items-center">
       <div className="text-center dark:text-gray-100 text-sm font-semibold">{data?.series?.[0]?.name}</div>
       <div style={{
         background: data?.options?.stroke?.colors?.[0]
       }} className="w-8 h-4 ">
     </div>
     </div>
     <Chart options={data?.options} series={data?.series} type="donut" />
      </>
      ) : <div className="text-center"><p>Data Not Found!</p></div>}
    </div>
  );
};

export default Donut;
