import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useStateContext } from "../contexts/ContextProvider";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { TiDeleteOutline } from "react-icons/ti";
import axios from "axios";

const Notification = () => {
  const { currentColor } = useStateContext();
  const BASE_URL = window.config.API_URL;
  const [imgUploading, setImgUploading] = useState(false);
  const { state: ContextState, sendNotification } = useContext(ApisContext);
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [uploadedfiles, setUploadedfiles] = useState(null);
  const uploadImage = async (productImage, user) => {
    setImgUploading(true);

    try {
      const tokenStr = user.token;

      const response = await axios.post(BASE_URL + "images", productImage, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      setImgUploading(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Image successfully uploaded!");
          // console.log(response.data);
          setUploadedfiles(response?.data?.data);
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Banner not added");
      } else if (response.status === 401) {
        // toast.dismiss();
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
        navigate("/login");
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };
  // console.log(uploadedfiles, "upload");

  function handleChange(e) {
    let formData = new FormData(); //formdata object
    formData.append("image", e.target.files[0]);
    const res = uploadImage(formData, user);
    setValue("images", e.target.files[0]);
    const newFileUrls = URL.createObjectURL(e.target.files[0]);
    setFile(...newFileUrls);
  }
  const deleteUploadedImage = async (id) => {
    console.log(id, "delete uploaded image");
    // deleteImage(imageId, user);
    try {
      const tokenStr = user.token;

      const response = await axios.delete(BASE_URL + `images/${id}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (response.status === 200) {
        if (response.data.Success === true) {
          toast.dismiss();
          toast.success("Image successfully deleted!");
          // console.log(response.data);
          setUploadedfiles();

          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: image not found");
      } else if (response.status === 401) {
        // toast.dismiss();
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      if (error.response.status === 401) {
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
        navigate("/login");
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
    // setUploadedfiles();
  };

  const submit = (data) => {
    const formData = new FormData();
    if (uploadedfiles) {
      formData.append("image_id", uploadedfiles.id);
    }
    formData.append("title", data.title);
    formData.append("description", data.description);
    sendNotification(formData, user);
    reset();
    setUploadedfiles(null);
    setFile(null);
  };

  return (
    <div className="mt-24 sm:mt-8 md:mt-5 px-5 sm:px-8 md:px-14">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-5">
        Create Notification
      </div>
      <form onSubmit={handleSubmit((data) => submit(data))} noValidate>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
            {!uploadedfiles ? (
              <div className="items-center justify-center w-full">
                <label
                  htmlFor="dropzone-file"
                  className={`flex flex-col items-center justify-center w-full h-64 border-2  border-dashed rounded-lg cursor-pointer ${errors.image
                      ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500  dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                      : "border-gray-300 bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    }`}
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    {imgUploading ? (
                      <>
                        <div role="status">
                          <svg
                            aria-hidden="true"
                            className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="currentColor"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentFill"
                            />
                          </svg>
                          <span className="sr-only">Loading...</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <svg
                          aria-hidden="true"
                          className="w-10 h-10 mb-3 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          ></path>
                        </svg>
                        <p className="mb-2 text-sm text-gray-500 text-center dark:text-gray-400">
                          <span className="font-semibold">
                            Click to upload Product Images
                            {/* <span className="text-red-600 text-xs">*</span> */}
                          </span>{" "}
                          <br />
                          or drag and drop
                        </p>
                      </>
                    )}
                  </div>
                  <input autoComplete="off"
                    id="dropzone-file"
                    type="file"
                    {...register("image")}
                    accept="image/*"
                    onChange={handleChange}
                    className="hidden"
                  />
                </label>

                <small className="text-sm text-red-600 dark:text-red-500">
                  {errors.image?.message}
                </small>
              </div>
            ) : (
              <div>
                
                <div className="max-h-60 flex justify-center relative"
                >
                  <div
                    onClick={() => deleteUploadedImage(uploadedfiles.id)}
                    className="absolute text-red-600 top-2 right-2 bg-gray-100 cursor-pointer h-7 w-7 rounded-full flex justify-center items-center text-2xl"
                  >
                    <TiDeleteOutline />
                  </div>
                  <img
                    src={uploadedfiles.image}
                    className="object-contain min-h-[175px] rounded-md"
                    alt=""
                  />
                </div>
                
              </div>
            )}
          </div>

          <div>
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Notification Title
            <span className="text-red-600 text-xs">*</span>
          </label>
          <input autoComplete="off"
            type="text"
            id="title"
            className={`border text-sm rounded-lg block w-full p-2.5 ${
              errors.title
                ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            } `}
            placeholder="Enter Notification Title"
            {...register("title", {
              required: {
                value: true,
                message: "Notification title required",
              },
            })}
          />
          <small className="text-sm text-red-600 dark:text-red-500">
            {errors.title?.message}
          </small>
        </div>
        <div >
          <label
            htmlFor="description"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Notification Description
            <span className="text-red-600 text-xs">*</span>
          </label>
          <textarea
            type="text"
            id="description"
            rows={5}
            className={`border text-sm rounded-lg block w-full p-2.5 ${
              errors.description
                ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
            } `}
            placeholder="Enter Notification Description"
            {...register("description", {
              required: {
                value: true,
                message: "Description required",
              },
            })}
          ></textarea>
          <small className="text-sm text-red-600 dark:text-red-500">
            {errors.description?.message}
          </small>
        </div>
          </div>

        </div>
        {/* 
        <div className="grid gap-6 mb-6 md:grid-cols-2">
          <div>
            {!file.length > 0 ? (
              <div className="items-center justify-center w-full">
                <label
                  htmlFor="dropzone-file"
                  className={`flex flex-col items-center justify-center w-full h-64 border-2  border-dashed rounded-lg cursor-pointer ${
                    errors.images
                      ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500  dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                      : "border-gray-300 bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  }`}
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      aria-hidden="true"
                      className="w-10 h-10 mb-3 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 text-center dark:text-gray-400">
                      <span className="font-semibold">
                        Click to upload Notification Images
                        <span className="text-red-600 text-xs">*</span>
                      </span>{" "}
                      <br />
                      or drag and drop
                    </p>
                  </div>
                  <input autoComplete="off"
                    id="dropzone-file"
                    type="file"
                    {...register("images", {
                      required: {
                        value: true,
                        message: "Notification images required",
                      },
                    })}
                    onChange={handleChange}
                    className="hidden"
                  />
                </label>
                <small className="text-sm text-red-600 dark:text-red-500">
                  {errors.images?.message}
                </small>
              </div>
            ) : (
              <div>
                {file.map((img, index) => {
                  return index === 0 ? (
                    <div key={index} className="max-h-60 flex">
                      <img src={img} className="object-contain" alt="" />
                    </div>
                  ) : null;
                })}
                <div className="flex gap-1 pb-0.5 pt-2 w-full overflow-x-auto">
                  {file.map((img, index) => {
                    return index === 0 ? null : (
                      <img
                        width={100}
                        key={index}
                        className="object-cover max-h-24 border rounded-md"
                        src={img}
                        alt=""
                      />
                    );
                  })}
                </div>
              </div>
            )} 
          </div>
           
          <div>
          </div>
        </div>
          */}
        <div className="text-right mt-3">
          <button
            type="submit"
            style={{
              background: currentColor,
            }}
            className=" text-white hover:opacity-80  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
          >
            Send
          </button>
        </div>

        {/*<div className="mb-6">
          <label
            htmlFor="success"
            className="block mb-2 text-sm font-medium text-green-700 dark:text-green-500"
          >
            Your name
          </label>
          <input autoComplete="off"
            type="text"
            id="success"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500"
            placeholder="Success input"
            {...register("name", { required: true })}
          />
          <p className="mt-2 text-sm text-green-600 dark:text-green-500">
            <span className="font-medium">Well done!</span> Some success message.
          </p>
</div> 
<div>
  <label
    htmlFor="error"
    className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
  >
    Your name
  </label>
  <input autoComplete="off"
    type="text"
    id="error"
    className="bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500"
    placeholder="Error input"
  />
  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
    <span className="font-medium">Oh, snapp!</span> Some error message.
  </p>
</div>
*/}
      </form>
    </div>
  );
};

export default Notification;
