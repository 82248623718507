import React from "react";
import Chart from "react-apexcharts";

import "./SplineArea.css";

const SplineArea = (props) => {
  const data = props.data;
  return (
    <div className="SpLineArea dark:text-white">
      <div className="flex gap-2 justify-center items-center">
        {data ?(<>
      <div className="text-center dark:text-gray-100 text-sm font-semibold">{data?.series?.[0]?.name}</div>
      <div style={{
          background: data?.options?.stroke?.colors?.[0]
        }} className="w-8 h-4 ">
          </div>
      </> )
          : null}
      </div>

      {data ? <Chart options={data.options} series={data.series} type="area" /> : null}
    </div>
  );
};

export default SplineArea;
