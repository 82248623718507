/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
// import { BiCloudDownload } from "react-icons/bi";

// import Datepicker from "react-tailwindcss-datepicker";

import Paging from "../components/dashboard/PagingTable";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useStateContext } from "../contexts/ContextProvider";

const CenceledOrders = () => {
  const [parPageCount, setParPageCount] = useState(5);
  const [searchQuerry, setSearchQuerry] = useState("");
  const [canceledOrders, setCanceledOrders] = useState([]);
  const [filtered, setFiltered] = useState([]);
  
  const {
    state: ContextState,
    fetchOrders,
  } = useContext(ApisContext);
  const { orders, fetchOrdersPending } = ContextState;
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;
  
  useEffect(() => {
    fetchOrders(user);
    
  }, []);
  
  useEffect(() => {
    if (orders) {
      const filter = orders.filter(
        (order) => order.status === "Cancel"
      );
      setCanceledOrders([...filter]);
    } else {
      setCanceledOrders([]);
    }

    
  }, [orders]);
  
  useEffect(() => {
    filterOrders();
  }, [searchQuerry, canceledOrders]);
  
  const filterOrders = () => {
    if (canceledOrders && searchQuerry !== "") {
      const filteredOrders = canceledOrders.filter(
        (order) => order.name.toLowerCase().includes(searchQuerry.toLowerCase()) || order.id.toString().toLowerCase().includes(searchQuerry.toLowerCase())
      );
      setFiltered([...filteredOrders]);
    } else {
      setFiltered([...canceledOrders]);
    }
  };
  
  return (
    <div className="px-5  sm:px-10 sm:mt-8 mt-24">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-3">
      Canceled Orders
      </div>

      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-5 border ">
        <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
        <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input autoComplete="off"
              type="text"
              onChange={(e) => setSearchQuerry(e.target.value)}
              id="simple-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
            />
          </div>
          {/*<select
            id="status"
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option>Status</option>
            <option value="US">Pending</option>
            <option value="CA">Delivered</option>
            <option value="FR">Processing</option>
            <option value="DE">Cancel</option>
  </select> */}
         <select
            id="limit"
            onChange={(e) => {
              setParPageCount(e.target.value);
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option defaultValue disabled>
              Order limits
            </option>
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        {/*<div className="mt-3 flex flex-wrap sm:flex-nowrap justify-between items-center gap-2">
          <div className="w-full">
            <small className="text-gray-900 dark:text-gray-200 text-sm">
              Select From to End Date
            </small>
            <div className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ">
              <Datepicker value={value} onChange={handleValueChange} />
            </div>
          </div>
          <button
            type="button"
            className="focus:outline-none whitespace-nowrap w-full sm:w-fit sm:mt-5 justify-center text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-base px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 flex items-center gap-2"
          >
            <div className="text-2xl">
              <BiCloudDownload />
            </div>
            Download All Orders
          </button>
  </div> */}
      </div>
      <Paging orders={filtered} parPageCount={parPageCount} />
    </div>
  );
};
export default CenceledOrders;
