import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useStateContext } from "../contexts/ContextProvider";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";

import ReactQuill, { Quill } from "react-quill";
import ImageUploader from "quill-image-uploader";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { AiFillCloseCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import Editor from "../components/dashboard/Editor";
// #2 register module
Quill.register("modules/imageUploader", ImageUploader);
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
  imageUploader: {
    upload: (file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append("image", file);

        fetch(
          "https://api.imgbb.com/1/upload?key=334ecea9ec1213784db5cb9a14dac265",
          {
            method: "POST",
            body: formData,
          }
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            resolve(result.data.url);
          })
          .catch((error) => {
            reject("Upload failed");
            console.error("Error:", error);
          });
      });
    },
  },
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};

const SendEmail = () => {
  const { currentColor } = useStateContext();
  const {
    state: ContextState,
    sendEmails,
    fetchCustomers,
  } = useContext(ApisContext);
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;
  const { customers, fetchCustomersPending } = ContextState;
  // const  [customers, setCustomers] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const [value, setEditorValue] = useState("");
  const valueChangeHandler = (val) => {
    setEditorValue(val);
  };
  // useEffect(() => {
  //   console.log(value, "valuevaluevalue")
  // }, [value])
  const [selectedEmailGroup, setSelectedEmailGroup] = useState(null);
  const [viewMailsModal, setViewMailsModal] = useState(false);

  const [emails, setEmails] = useState([]);
  const [batchIndex, setBatchIndex] = useState(0);

  useEffect(() => {
    if (customers && customers.length) {
      const batchSize = 100;
      const numBatches = Math.ceil(customers.length / batchSize);

      const updatedEmails = Array.from({ length: numBatches }, (_, i) => {
        const start = i * batchSize + 1;
        const end = Math.min((i + 1) * batchSize, customers.length);
        const batchRange = `${start}-${end - 1}`;
        return customers.slice(start - 1, end).map((user) => user.email);
      });

      setEmails(updatedEmails);
    }
  }, [customers]);

  // console.log(value, "setEditorValue");
  useEffect(() => {
    setValue("body", value);
  }, [value]);

  useEffect(() => {
    fetchCustomers(user);

    // for (let i = 0; i < 200; i++) {
    //   const user = {
    //     id: 9400 + i, // Increment the id for each new user
    //     name: `User ${i + 1}`,
    //     email: `user${i + 1}@example.com`,
    //     phone_number: "0000000000",
    //     self_referral: "selfreferralcode",
    //     referral_by: null,
    //     profile_picture: "null",
    //     created_at: "2023-10-11 00:00:00",
    //     updated_at: "2023-10-11 00:00:00",
    //     is_active: "active",
    //     user_address: [
    //       {
    //         id: i + 1, // Increment the id for each address
    //         user_id: 9400 + i, // Match the user id
    //         name: `User ${i + 1} Address`,
    //         mobile: "0000000000",
    //         alternative_mobile: "0000000000",
    //         address: "123 Main St",
    //         pincode: "00000",
    //         city: "City",
    //         state: "State",
    //         is_active: "1",
    //         created_at: "2023-10-11 00:00:00",
    //         updated_at: "2023-10-11 00:00:00",
    //       },
    //     ],
    //   };

    //   customers.push(user);
    //   setCustomers(prevState => [...prevState, user])
    // }
  }, []);

  const submit = (data) => {
    if (
      !selectedEmailGroup ||
      selectedEmailGroup[0] === "Select Emails" ||
      !selectedEmailGroup?.length
    ) {
      toast.error(`Please select Users`);
      return;
    }
    const formData = new FormData();
    selectedEmailGroup.map((email) => {
      formData.append("email[]", email);
    });
    formData.append("subject", data.subject);
    formData.append("body", data.body);
    // console.log(data, "submit");
    sendEmails(formData, user);
    reset();
    setEditorValue("");
  };

  return (
    <>
      {viewMailsModal && (
        <div
          style={{ zIndex: "999999999" }}
          className="fixed bg-[#00000077] backdrop-blur-sm inset-0 h-screen w-screen flex justify-center items-center"
        >
          <div className="bg-slate-50 dark:bg-slate-700 rounded-xl p-5 pb-2 min-w-[50%] max-h-[70%] flex flex-col">
            <div className="border-b-1 pb-2 border-gray-800 relative">
              <div
                onClick={() => setViewMailsModal(false)}
                className="absolute cursor-pointer text-red-600 text-3xl -right-4 -top-4"
              >
                <AiFillCloseCircle />
              </div>
              <p className="text-base font-semibold text-gray-900 dark:text-white">
                Selected Emails
              </p>
            </div>
            <div className="overflow-y-auto ">
              <div className="h-full overflow-y-auto flex flex-col gap-0.5 p-2 ">
                {selectedEmailGroup?.map((email, i) => {
                  return (
                    <div key={i} className="p-1">
                      {email}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="mt-24 sm:mt-8 md:mt-5 px-5 sm:px-8 md:px-14">
        <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-5">
          Send Email
        </div>
        <form onSubmit={handleSubmit((data) => submit(data))} noValidate>
          <div className="mb-4">
            <label
              htmlFor="users"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Users
              <span className="text-red-600 text-xs">*</span>
            </label>

            <div className="flex gap-1">
              <select
                onChange={(e) =>
                  setSelectedEmailGroup(e.target.value.split(","))
                }
                name="users"
                id="users"
                className={`border text-sm rounded-lg block w-full p-2.5 bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white`}
                placeholder="Select Users"
              >
                <option>Select Emails</option>
                {emails?.map((batch, i) => {
                  const start = i * 100 + 1;
                  const end = Math.min(start + batch.length - 1, (i + 1) * 100);
                  const batchRange = `${start} - ${end}`;
                  return (
                    <option value={batch.join(",")} key={i}>
                      {batchRange}
                    </option>
                  );
                })}
              </select>

              {selectedEmailGroup?.length &&
                selectedEmailGroup &&
                selectedEmailGroup[0] !== "Select Emails" && (
                  <div>
                    <button
                      type="button"
                      style={{
                        background: currentColor,
                      }}
                      onClick={() => setViewMailsModal(!viewMailsModal)}
                      className=" text-white hover:opacity-80  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-3 py-1.5 text-center "
                    >
                      View Emails
                    </button>
                  </div>
                )}
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="subject"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Email Subject
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input
              autoComplete="off"
              type="text"
              id="subject"
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.subject
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Email Subject"
              {...register("subject", {
                required: {
                  value: true,
                  message: "Email subject required",
                },
              })}
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.subject?.message}
            </small>
          </div>
          <div className="mb-3">
            <label
              htmlFor="body"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Mail Body
              <span className="text-red-600 text-xs">*</span>
            </label>

            <div className="relative">
              {/* <ReactQuill
                className={
                  "dark:text-slate-50 rounded-2xl dark:placeholder:text-slate-50 h-[200px]"
                }
                theme="snow"
                placeholder={"Enter Mail Body here"}
                value={value}
                onChange={setEditorValue}
                modules={modules}
                formats={formats}
              /> */}
              <Editor valueChangeHandler={valueChangeHandler} value={value} />
            </div>
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.body?.message}
            </small>
          </div>
          <div></div>

          <div className="text-right mt-24 md:mt-16">
            <button
              type="submit"
              style={{
                background: currentColor,
              }}
              className=" text-white hover:opacity-80  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
            >
              Send
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SendEmail;
