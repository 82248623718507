/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Navbar, Footer, Sidebar, ThemeSettings } from "../components";
import "../App.css";

import { useStateContext } from "../contexts/ContextProvider";
import AddToTopRatedSideBar from "../components/AddToTopRatedSideBar";
import AddToSaleSideBar from "../components/AddToSaleSideBar";
import EditSaleProductsBar from "../components/EditSaleProductsBar";
import SetTimeModal from "../components/SetTimeModal";
import ChangeStatusRequests from "../components/ChangeStatusRequests";
import ChangeStatusBookRequests from "../components/ChangeStatusBookRequests";

const SideBarLayout = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    addToTopRatedSideBar,
    addToSaleSideBar,
    editSaleProductsBar,
    chnageStatus,
    changeStatusRequests,
    changeStatusBookRequests,
  } = useStateContext();

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode") || "blue";
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);
  return (
    <div className="flex dark:bg-main-dark-bg">
    
      {activeMenu ? (
        <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
          <Sidebar />
        </div>
      ) : (
        <div className="w-0 dark:bg-secondary-dark-bg">
          <Sidebar />
        </div>
      )}
      <div
        className={
          activeMenu
            ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen overflow-hidden md:ml-72 w-full "
            : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen overflow-hidden flex-2 "
        }
      >
        <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
          <Navbar />
        </div>
        <div>
          {themeSettings && <ThemeSettings />}
          {addToTopRatedSideBar && <AddToTopRatedSideBar />}
          {addToSaleSideBar && <AddToSaleSideBar />}
          {editSaleProductsBar && <EditSaleProductsBar />}
          {chnageStatus && <SetTimeModal />}
          {changeStatusRequests && <ChangeStatusRequests />}
          {changeStatusBookRequests && <ChangeStatusBookRequests />}
          <Outlet />
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SideBarLayout;
