/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";

import { MdOutlinePublishedWithChanges } from "react-icons/md";

import { ApisContext } from "../../contexts/ApiConterxt";
import { AuthContext } from "../../contexts/Auth.Context";
import { useStateContext } from "../../contexts/ContextProvider";
import moment from "moment/moment";

import ReactPaginate from "react-paginate";

import NotFoundImg from "../../assets/No data-pana.png";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BsFillEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const Paging = ({ orders, parPageCount }) => {
  const { setChnageStatus, currentColor } = useStateContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState([]);

  const {
    state: ContextState
  } = useContext(ApisContext);
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;

  const { fetchOrdersPending } = ContextState;

  const chnageStatusHandler = (id) => {
    setChnageStatus(id);
  };
  // console.log(Math.ceil(orders?.length / parPageCount), 'math.ceil(orders?.length / pageCount', orders?.length)
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    const startIndex = parseInt(currentPage) * parseInt(parPageCount);
    const endIndex = parseInt(startIndex) + parseInt(parPageCount);
    setFilterData(orders?.slice(startIndex, endIndex));
  }, [orders]);
  useEffect(() => {
    const startIndex = parseInt(currentPage) * parseInt(parPageCount);
    const endIndex = parseInt(startIndex) + parseInt(parPageCount);
    setFilterData(orders?.slice(startIndex, endIndex));
  }, [currentPage, orders, parPageCount]);

  return (
    <>
      <div className="-my-2 py-2 sm:-mx-6 sm:px-3 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
          <table className="min-w-full px-4">
            <thead>
              <tr className="bg-gray-300 dark:bg-gray-600 dark:text-gray-50 border-b border-gray-200 dark:border-gray-700 text-sm leading-4 uppercase tracking-wider whitespace-nowrap">
                <th className="ps-6 px-3 py-4 text-left font-semibold">
                  INVOICE NO.
                </th>
                <th className="px-3 py-4 text-left font-semibold">
                  ORDER TIME
                </th>
                <th className="px-3 py-4 text-left font-semibold">
                  CUSTOMER NAME
                </th>
                <th className="px-3 py-4 text-left font-semibold">METHOD</th>
                <th className="px-3 py-4 text-left font-semibold">AMOUNT</th>
                <th className="px-3 py-4 text-center font-semibold">View</th>
                <th className="px-3 py-4 text-left font-semibold">Status</th>
                <th className="px-3 py-4 text-left font-semibold">ACTION</th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-500">
              {!fetchOrdersPending &&
                filterData?.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className="border-b border-gray-200 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                    >
                      <td className="ps-6 px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 ">{item?.id}</div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5">
                          {moment(item?.created_at).format(
                            "MMM D, YYYY h:mm A"
                          )}
                          <br />
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 capitalize">
                          {item?.name}
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 uppercase">{item?.payment_mode}</div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 ">
                          &#8377;{item?.total_amount}
                        </div>
                      </td>
                      <td className="px-3 py-4 text-xl text-wk-align">
                        <Link to={`/orders/viewOrder/${item?.id}`}>
                          <BsFillEyeFill />
                        </Link>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm ">
                        {item?.status === "Pending" && (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                            Pending
                          </span>
                        )}
                        {item?.status === "Processing" && (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                            Processing
                          </span>
                        )}
                        {item?.status === "Cancel" && (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800 mr-2 py-0.5 dark:bg-red-900 dark:text-red-300">
                            Canceled
                          </span>
                        )}
                        {item?.status === "Delivered" && (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 mr-2 py-0.5 dark:bg-green-900 dark:text-green-300">
                            Delivered
                          </span>
                        )}
                        {item?.status === "Shipped" && (
                          <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 mr-2 py-0.5 dark:bg-green-900 dark:text-green-300">
                            Shipped
                          </span>
                        )}
                      </td>
                      <td className="pe-1 sm:pe-6 px-1 sm:px-3 py-2 whitespace-nowrap">
                        <button
                          onClick={() => chnageStatusHandler(item?.id)}
                          type="button"
                          style={{
                            background: currentColor,
                          }}
                          className="flex items-center gap-1 focus:outline-none text-white font-medium rounded-lg text-sm px-3 py-2 hover:opacity-80"
                        >
                          <MdOutlinePublishedWithChanges className="text-xl" />{" "}
                          Change Status
                        </button>
                      </td>
                    </tr>
                  );
                })}
              {fetchOrdersPending && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    colSpan={8}
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
              {!fetchOrdersPending && !filterData?.length && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td colSpan={8} className="p-4 py-2 ">
                    <div className="flex justify-center items-left md:items-center flex-col md:flex-row">
                      <img
                        className="max-h-56 w-56 sm:max-w-[450px] sm:max-h-[450px]"
                        src={NotFoundImg}
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
              )}
              {!fetchOrdersPending && filterData?.length ? (
                <tr>
                  <td colSpan={8} >
                    <div className="flex justify-between px-4 my-2 items-left md:items-center flex-col md:flex-row">
                      <div className="whitespace-nowrap">
                        <span className="text-sm text-gray-700 dark:text-gray-400">
                          Showing{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {filterData?.length > 0
                              ? currentPage * parPageCount + 1
                              : 0}
                          </span>{" "}
                          to{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {Math.min(
                              (currentPage + 1) * parPageCount,
                              orders?.length
                            )}
                          </span>{" "}
                          of{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {orders?.length}
                          </span>{" "}
                          Entries
                        </span>
                      </div>
                      <ReactPaginate
                        previousLabel={<GrFormPrevious />}
                        nextLabel={<GrFormNext />}
                        breakLabel={"..."}
                        initialPage={currentPage}
                        pageCount={Math.ceil(orders?.length / parPageCount)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "items-center inline-flex space-x-2"
                        }
                        pageClassName={
                          "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
                        previousLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        disabledLinkClassName={"text-gray-400 opacity-50"}
                        nextLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
                        activeLinkClassName={
                          "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
                        }
                      />
                    </div>
                  </td>
                </tr>
              ): null}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
export default Paging;
