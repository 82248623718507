import React, { useState } from "react";
import { MdOutlineCancel } from "react-icons/md";
import { BiFullscreen, BiExitFullscreen } from "react-icons/bi";
import { BsSun, BsMoon } from "react-icons/bs";

import { themeColors } from "../data/dummy";
import { useStateContext } from "../contexts/ContextProvider";
import "./ThemeSetting.css";

const ThemeSettings = () => {
  const { setColor, setMode, currentMode, currentColor, setThemeSettings } =
    useStateContext();

  const [fullscreen, setFullscreen] = useState(false);

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setFullscreen(true);
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
      setFullscreen(false);
    }
  };

  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
      <div className="float-right settings-modal dark:text-gray-200  bg-white dark:bg-[#484B52] ">
        <div className="flex justify-between items-center p-4 ml-4 pb-0">
          <p className="font-semibold text-lg">Settings</p>
          <button
            type="button"
            onClick={() => setThemeSettings(false)}
            style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
            className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
          >
            <MdOutlineCancel />
          </button>
        </div>
        <div className="p-3 overflow-hidden overflow-y-auto h-full">
          <div className="flex-col border-t-1 border-color p-4">
            <p className="font-semibold text-md">Mode</p>
            <div className="flex gap-8 pt-2 justify-around">
              <label
                htmlFor="light"
                style={{
                  color: currentColor,
                }}
                className={`text-2xl cursor-pointer bg-light-gray w-1/2 shadow-lg rounded-lg hover:bg-[${currentColor}]`}
              >
                <div className="flex h-[72px] justify-center items-center ">
                  <input autoComplete="off"
                    type="radio"
                    id="light"
                    name="theme"
                    value="Light"
                    className="cursor-pointer hidden"
                    onChange={setMode}
                    checked={currentMode === "Light"}
                  />

                  <BsSun />
                </div>
              </label>
              <label
                htmlFor="dark"
                style={{
                  color: currentColor,
                }}
                className={`text-2xl bg-gray-700 w-1/2 shadow-lg rounded-lg cursor-pointer`}
              >
                <div className="flex h-[72px] justify-center items-center ">
                  <input autoComplete="off"
                    type="radio"
                    id="dark"
                    name="theme"
                    value="Dark"
                    onChange={setMode}
                    className="cursor-pointer hidden"
                    checked={currentMode === "Dark"}
                  />
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}

                  <BsMoon />
                </div>
              </label>
            </div>
          </div>
          <div className="p-4 border-t-1 border-color">
            <p className="font-semibold text-md ">Colors</p>
            <div className="flex gap-2 flex-wrap">
              {themeColors.map((item, index) => (
                <div
                  style={{
                    ...(currentColor === item.color && {
                      border: `3px solid ${item.color}`,
                    }),
                  }}
                  className={
                    "relative mt-2 border-2 p-3 rounded-xl cursor-pointer flex gap-5 items-center "
                  }
                  key={index}
                >
                  <button
                    type="button"
                    className="h-5 -rotate-45 w-8 rounded-[50%] cursor-pointer"
                    style={{
                      transform: item.color === currentColor ? 'none': '',
                      backgroundColor: item.color,
                      boxShadow: item.color === currentColor ? `1px 1px 20px 3px ${item.light}`:'',
                    }}
                    onClick={() => setColor(item.color)}
                  ></button>
                </div>
              ))}
            </div>
          </div>
          <div className="p-4 pb-0 border-t-1 border-color">
            <p className="font-semibold text-md ">Full Screen</p>
            <div className="flex justify-center pt-4 p-2">
              <button
                onClick={toggleFullScreen}
                className="border-2 shadow-lg w-full justify-center gap-2 py-2 flex items-center rounded-lg text-lg font-bold"
              >
                {fullscreen ? (
                  <>
                    <BiExitFullscreen />
                    Exit FullScreen
                  </>
                ) : (
                  <>
                    <BiFullscreen /> Full Screen
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeSettings;
