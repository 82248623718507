// Imageresizer.js

import React, {
    useEffect,
    useState
} from 'react';
import './Imageresizer.css';
import { useStateContext } from "../../contexts/ContextProvider";


const ImageResizer = ({image, setImage, onCancel}) => {
  const { currentColor } = useStateContext();
    // const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [width, setWidth] = useState('');
    const [height, setHeight] = useState('');
    const [beforeWidth, setBeforeWidth] = useState('');
    const [beforeHeight, setBefotreHeight] = useState('');
    const [percentage, setPercentage] = useState('');
    const [quality, setQuality] = useState(75);

    useEffect(() => {
        if (image) {
            const reader = new FileReader();
            reader.onload = () => {
                setPreview(reader.result); // Update the preview state with the loaded image
            };
            reader.readAsDataURL(image);
        }
        beforeHeightWidth();
    }, [image]);
    
    // Convert data URL to Blob
    function dataURLtoBlob(dataURL) {
        const byteString = atob(dataURL.split(',')[1]);
        const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    const beforeHeightWidth = () => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
    
            let targetWidth, targetHeight;
    
            if (width && height) {
                targetWidth = parseInt(width, 10);
                targetHeight = parseInt(height, 10);
            } else if (percentage) {
                targetWidth = (parseInt(percentage, 10) * img.width) / 100;
                targetHeight = (parseInt(percentage, 10) * img.height) / 100;
            } else {
                targetWidth = img.width;
                targetHeight = img.height;
            }
            
            canvas.width = targetWidth;
            canvas.height = targetHeight;
    
            setBeforeWidth(targetWidth);
            setBefotreHeight(targetHeight);
            setWidth(targetWidth);
            setHeight(targetHeight);
            
            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
            // setWidth(canvas.width)
            // setHeight(canvas.height)
            const compressedDataUrl = canvas.toDataURL('image/jpeg', quality / 100);
    
        };
        // Use URL.createObjectURL() to set the image source
        img.src = URL.createObjectURL(image);
    };
    const handleResizeAndCompress = () => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
    
            let targetWidth, targetHeight;
    
            if (width && height) {
                targetWidth = parseInt(width, 10);
                targetHeight = parseInt(height, 10);
            } else if (percentage) {
                targetWidth = (parseInt(percentage, 10) * img.width) / 100;
                targetHeight = (parseInt(percentage, 10) * img.height) / 100;
                setWidth(targetWidth);
                setHeight(targetHeight);
            } else {
                targetWidth = img.width;
                targetHeight = img.height;
            }
    
            canvas.width = targetWidth;
            canvas.height = targetHeight;
    
            ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
            // setWidth(canvas.width)
            // setHeight(canvas.height)
            const compressedDataUrl = canvas.toDataURL('image/jpeg', quality / 100);
    
            // Update the preview
            setPreview(compressedDataUrl);
        };
    
        // Use URL.createObjectURL() to set the image source
        img.src = URL.createObjectURL(image);
    };
    
    

    useEffect(() => {
        handleResizeAndCompress();
    }, [width, height, percentage, quality])

    return (
        <div className="">
            <div className='border-b pb-2 mb-1'>
                <h1 className="text-gray-900 dark:text-white font-bold">
                    Image Resize and Compressor
                </h1>
            </div>

            {image && (
                <div className='flex gap-2 sm:gap-4'>
                    <div className='w-full'>
                       
                        <div className="flex flex-col sm:flex-row items-center gap-3 py-2 ">
                            <div className='w-full'>
                                <p className='font-bold text-center text-gray-900 dark:text-white mb-0'>
                                    Before
                                </p>
                                <div className="w-full max-h-[calc(100vh_-_320px)] overflow-y-auto border">
                                    <img src={URL.createObjectURL(image)} className={`object-cover h-full w-full`} alt="Preview" />
                                </div>
                                <div className="flex justify-between items-center gap-3 py-2">
                                    <div className="flex items-center w-full">
                                        <label htmlFor="resizeWidth" className='text-sm mr-1 text-gray-900 dark:text-white font-semibold'>Width:</label>
                                        <input
                                            type="number"
                                            id="resizeWidth"
                                            className='p-1 rounded w-full text-sm focus:outline-none font-medium text-gray-900 dark:text-white'
                                            placeholder="Width"
                                            value={beforeWidth}
                                            readOnly
                                            min={1}
                                            onChange={(e) => setWidth(e.target.value)}
                                        />
                                    </div>
                                    <div className="flex items-center w-full">
                                        <label htmlFor="resizeHeight" className='text-sm mr-1 text-gray-900 dark:text-white font-semibold'>Height:</label>
                                        <input
                                            type="number"
                                            id="resizeHeight"
                                            className='p-1 rounded w-full text-sm focus:outline-none font-medium text-gray-900 dark:text-white'
                                            placeholder="Height"
                                            value={beforeHeight}
                                            readOnly
                                            min={1}
                                            onChange={(e) => setHeight(e.target.value)}
                                        />
                                    </div>
                                    <div className="flex items-center w-full">
                                        <label htmlFor="size" className='text-sm mr-1 text-gray-900 dark:text-white font-semibold'>Size(KB):</label>
                                        <input
                                            type="number"
                                            id="size"
                                            className=' p-1 rounded w-full text-sm focus:outline-none font-medium text-gray-900 dark:text-white'
                                            placeholder="Height"
                                            value={Number(image?.size) / 1024}
                                            min={1}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='w-full'>
                                <p className='font-bold text-center text-gray-900 dark:text-white mb-0'>
                                    After
                                </p>
                                <div className="w-full max-h-[calc(100vh_-_320px)] overflow-y-auto border">
                                    <img src={preview} alt="Preview" />
                                </div>
                                <div className="flex justify-between items-center gap-3 py-2">
                                    <div className="flex items-center w-full">
                                        <label htmlFor="resizeWidth" className='text-sm mr-1 text-gray-900 dark:text-white font-semibold'>Width:</label>
                                        <input
                                            type="number"
                                            id="resizeWidth"
                                            className='border p-1 rounded w-full text-sm focus:outline-none font-medium text-gray-900 dark:text-white'
                                            placeholder="Width"
                                            value={width}
                                            min={1}
                                            onChange={(e) => setWidth(e.target.value)}
                                        />
                                    </div>
                                    <div className="flex items-center w-full">
                                        <label htmlFor="resizeHeight" className='text-sm mr-1 text-gray-900 dark:text-white font-semibold'>Height:</label>
                                        <input
                                            type="number"
                                            id="resizeHeight"
                                            className='border p-1 rounded w-full text-sm focus:outline-none font-medium text-gray-900 dark:text-white'
                                            placeholder="Height"
                                            value={height}
                                            min={1}
                                            onChange={(e) => setHeight(e.target.value)}
                                        />
                                    </div>
                                    <div className="flex items-center w-full">
                                        <label htmlFor="size" className='text-sm mr-1 text-gray-900 dark:text-white font-semibold'>Size(KB):</label>
                                        <input
                                            type="number"
                                            id="size"
                                            className='p-1 rounded w-full text-sm focus:outline-none font-medium text-gray-900 dark:text-white'
                                            placeholder="Height"
                                            value={preview ? Number(dataURLtoBlob(preview)?.size) / 1024 : 0}
                                            min={1}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            )}
             <div className="flex items-center">
                                <label htmlFor="resizePercentage" className='text-sm mr-1 text-gray-900 dark:text-white font-semibold'>
                                    Percentage:
                                </label>
                                <input
                                    type="number"
                                    id="resizePercentage"
                                    className='border p-1 rounded w-full text-sm focus:outline-none font-medium text-gray-900 dark:text-white'
                                    placeholder="Percentage"
                                    value={percentage}
                                    min={1}
                                    max={100}
                                    onChange={(e) => setPercentage(e.target.value)}
                                />
                            </div>
            <div className=" flex items-center my-2 ">
                <label htmlFor="quality" className='text-sm mr-1 text-gray-900 dark:text-white font-semibold'>Quality:</label>
                <input
                    type="range"
                    id="quality"
                    min="1"
                    max="100"
                    value={quality}
                    onChange={(e) => setQuality(e.target.value)}
                />
                <span id="quality-value" className="quality-value">
                    {quality}
                </span>
            </div>
            <div className='flex justify-between items-center'>
                            <button onClick={()=> onCancel()} style={{ border: `1px solid ${currentColor}`, color: currentColor }} className=" text-white  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:opacity-80">
                                Cancel
                            </button>
                            <button style={{ background: currentColor }} className=" text-white  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:opacity-80" 
                                onClick={()=>setImage(dataURLtoBlob(preview))}    
                            >
                                Save
                            </button>
            </div>
        </div>
    );
};

export default ImageResizer;