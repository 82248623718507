/* eslint-disable no-unused-vars */
import React, { useEffect, useContext, useState } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { MdOutlineDelete, MdAdd, MdDeleteOutline } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";

import { useStateContext } from "../contexts/ContextProvider";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
// import { TfiImport, TfiExport } from "react-icons/tfi";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";


import NotFoundImg from "../assets/No data-pana.png";

const TopRated = () => {
  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
    addToTopRatedSideBar,
    setAddToTopRatedSideBar,
  } = useStateContext();
  const {
    state: ContextState,
    getTopRatedProducts,
    deleteTopRated,
    getCategories,
  } = useContext(ApisContext);
  const { topRated, categories, getTopRatedPending } = ContextState;
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;

  useEffect(() => {
    getTopRatedProducts(user);
    getCategories(user);
    return () => {
      setAddToTopRatedSideBar(false);
    };
  }, []);

  const [visible, setVisible] = useState(false);
  const [deleteTopRatedId, setDeleteTopRatedId] = useState(null);
  
  const [searchQuery, setSearchQuery] = useState("");

  // console.log(topRated, "TopRated Products");
  // console.log(categories, "categories =");

  const handelDeleteTopRated = (productId) => {
    setDeleteTopRatedId(productId);
    setVisible(true);
  };
  const accept = () => {
    deleteTopRated(deleteTopRatedId, user);
  };
  const reject = () => {
    setDeleteTopRatedId(null);
  };

  const filterSearchTopRated = (topRated, searchString) => {
    return topRated?.filter((item) => {
      const title = item?.product_details?.title;
      return title && title.toLowerCase().includes(searchString.toLowerCase());
    });
  };
  const filteredTopRated = filterSearchTopRated(topRated, searchQuery);

  return (
    <div className="px-5  sm:px-10 sm:mt-8 mt-24">
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to proceed?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-3">
        TopRated
      </div>
      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-4 border flex flex-col sm:flex-row justify-between items-center gap-2">
        <div className="flex items-center gap-4 w-full sm:w-fit">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input autoComplete="off"
              type="text"
              id="simple-search"
              onChange={(e)=>setSearchQuery(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
              required
            />
          </div>
    
        </div>
        <div className="flex flex-wrap lg:flex-nowrap items-center gap-3 w-full sm:w-fit">
         
          <button
            type="button"
            onClick={() => setAddToTopRatedSideBar(true)}
            style={{
              background: currentColor,
            }}
            className="w-full justify-center sm:w-fit focus:outline-none text-white font-medium rounded-lg text-base px-3 py-2.5 hover:opacity-80 flex items-center gap-1"
          >
            <div className="text-xl">
              <MdAdd />
            </div>
            Add To Top Rated
          </button>
        </div>
      </div>
    
      <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-sm text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
             
              <th scope="col" className="px-3 ps-6 py-3 w-fit">
                Product Image
              </th>
              <th scope="col" className="px-3 py-3">
                Product name
              </th>
              {/* <th scope="col" className="px-3 py-3">
                Category
              </th> */}

              <th scope="col" className="px-3 py-3">
                Price
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Discount (%)
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Sale Price
              </th>
              <th scope="col" className="px-3 py-3">
                STOCK
              </th>
              <th scope="col" className="px-3 py-3">
                STATUS
              </th>
              <th scope="col" className="px-3 py-3">
                VIEW
              </th>
              <th scope="col" className="px-3 py-3 pe-5">
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody>
            {getTopRatedPending && (
              <>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
              </>
            )}
            {!getTopRatedPending &&
              topRated &&
              filteredTopRated?.map((item, i) => {
                return (
                  <tr
                    key={i}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    {/*<td className="w-4 p-4">
                <div className="flex items-center">
                  <input
                    id="checkbox-table-search-1"
                    type="checkbox"
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label htmlFor="checkbox-table-search-1" className="sr-only">
                    checkbox
                  </label>
                </div>
</td> */}
                    <td className="px-3 ps-6 py-2 flex justify-center">
                      <img
                        className="rounded-lg h-12 w-12 border object-cover"
                        src={item?.product_details?.product_image?.[0]?.image}
                        alt=""
                      />
                    </td>
                    <td className="px-3 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white ">
                      {" "}
                      {item?.product_details?.title?.substr(0, 23)}
                      {item?.product_details?.title?.length > 20 ? "..." : ""}
                    </td>
                    {/* <td className="px-3 py-4">
                      {categories?.map((category) => {
                        if (
                          item?.product_details?.category_id == category?.id
                        ) {
                          return category?.category_name;
                        }
                      })}
                    </td> */}
                    <td className="px-3 py-4 font-semibold">
                      {item?.product_details?.price || "0"}
                    </td>
                    <td className="px-3 py-4 font-semibold">
                      
                      {item?.product_details?.discount_percent ? parseInt(item?.product_details?.discount_percent).toFixed(2):0}
                    </td>
                    <td className="px-3 py-4 font-semibold">
                     
                      {
                      Math.round(
                        item?.product_details?.price - (item?.product_details?.price * item?.product_details?.discount_percent) / 100
                      )
                      }
                    </td>
                    <td className="px-3 py-4">
                      {item?.product_details?.stock}
                    </td>
                    <td className="px-3 py-4">
                      {item?.product_details?.stock > 0 ? (
                        <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                          Selling
                        </span>
                      ) : (
                        <span className="bg-red-100 whitespace-nowrap text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                          Sold out
                        </span>
                      )}
                    </td>
                    <td className="px-3 py-4 text-xl text-wk-align">
                      <Link
                        to={`/books/viewBook/${item?.product_details?.id}`}
                      >
                        <BsFillEyeFill />
                      </Link>
                    </td>

                    <td className="px-3 py-4">
                      <div className="text-2xl text-center">
                        <button
                          onClick={() => handelDeleteTopRated(item?.id)}
                          className="font-medium flex justify-center text-red-600 dark:text-red-500 hover:underline"
                        >
                          <MdOutlineDelete />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}

{ !getTopRatedPending && !filteredTopRated?.length &&
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
              <td colSpan={11} className="p-4 py-2 ">
                <div className="flex justify-center items-left md:items-center flex-col md:flex-row">
                    <img className="max-h-56 w-56 sm:max-w-[450px] sm:max-h-[450px]" src={NotFoundImg} alt="" />
                  </div>
                </td>
              </tr>
            }
            
            {!getTopRatedPending && (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan={11} className="p-4 py-2 ">
                  <div className="flex justify-between items-left md:items-center flex-col md:flex-row">
                    <div className="whitespace-nowrap">
                      <span className="text-sm text-gray-700 dark:text-gray-400">
                        Showing{" "}
                        <span className="font-semibold text-gray-900 dark:text-white">
                          {topRated?.length}
                        </span>{" "}
                        Entries
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TopRated;
