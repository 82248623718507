import React, { useContext } from "react";
import { Navigate } from "react-router";

import { AuthContext } from "./contexts/Auth.Context";

const PrivateRoute = ({ children }) => {
    
    const { state } = useContext(AuthContext);

  return state.user ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
