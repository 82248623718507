/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useStateContext } from "../contexts/ContextProvider";

import ReactPaginate from "react-paginate";

import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";

import NotFoundImg from "../assets/No data-pana.png";
import { TiInfoLarge } from "react-icons/ti";
import moment from "moment";

const LogsList = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState("");
  const { state: ContextState, getApiLogs } = useContext(ApisContext);
  const { logs, getLogsPending, allLogs } = ContextState;
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    getApiLogs(user, 1);
  }, [user]);
  
  useEffect(() => {
    // Update page count when allLogs changes
    if (allLogs) {
      const total = allLogs?.total;
      const limit = allLogs?.per_page;
      setpageCount(Math.ceil(total / limit));
      setCurrentPage(parseInt(allLogs?.current_page) + 1);
    }
  }, [allLogs]);

  const handlePageClick = (data) => {
    if (data.selected >= 0) {
      const page = data.selected + 1;
      setCurrentPage(page);

      if (searchQuery) {
        // Fetch logs when clicking on pagination with search
        getApiLogs(user, page, searchQuery);
      } else {
        // Fetch logs when clicking on pagination without search
        getApiLogs(user, page);
      }
    }
  };
  

  useEffect(() => {
    if (searchQuery) {
      setCurrentPage(1);
      getApiLogs(user, 1, searchQuery);
    } else {
      getApiLogs(user, (currentPage - 1) ? (currentPage - 1) : 1);
    }
  }, [user, searchQuery]);

  return (
    <div className="px-5  sm:px-10 sm:mt-8 mt-24">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-3">
        Api Logs
      </div>
      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-4 border flex flex-col sm:flex-row justify-between items-center gap-2">
        <div className="flex items-center gap-4 w-full sm:w-fit">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              autoComplete="off"
              type="text"
              id="simple-search"
              onChange={(e) => setSearchQuery(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-sm text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="ps-6 px-3 py-3">
                Status
              </th>
              <th scope="col" className="ps-6 px-3 py-3 whitespace-nowrap">
                Time
              </th>
              <th scope="col" className="px-3 py-3">
                Method
              </th>

              <th scope="col" className="px-3 py-3">
                Url
              </th>
              <th scope="col" className="px-3 py-3 pe-6">
                VIEW
              </th>
            </tr>
          </thead>
          <tbody>
            {getLogsPending && (
              <>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                     
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
              </>
            )}
            {!getLogsPending &&
              logs &&
              logs?.map((log, index) => {
                return (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="ps-6 px-3 py-2 font-medium whitespace-nowrap dark:text-white flex justify-center">
                     <p className={`${log?.status_code === "200"? 'text-green-700':'text-red-500'}`}>{log?.status_code}</p>
                    </td>
                    <th
                      scope="row"
                      className="ps-6 px-3 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {moment(log?.request_time).format('MMM-DD-YYYY HH:mm:ss')}
                    </th>
                    <td className="px-3 py-4 text-center">
                      {log?.method}
                    </td>
                    <td className="px-3 py-4">
                      {log?.url}
                    </td>
                   
                    <td className="px-3 py-4 text-xl pe-6 flex justify-center">
                      <Link to={`/apilogs/viewLog/${log?.id}`}>
                        <TiInfoLarge />
                      </Link>
                    </td>
                  </tr>
                );
              })}

            {!getLogsPending && !logs && (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan={11} className="p-4 py-2 ">
                  <div className="flex justify-center h-[calc(100vh_-_250px)] items-left md:items-center flex-col md:flex-row">
                    <img className="max-h-56" src={NotFoundImg} alt="" />
                  </div>
                </td>
              </tr>
            )}
            {( allLogs !== null || logs?.length ) && (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <td colSpan={11} className="p-4 py-2 ">
                  <div className="flex justify-between items-left md:items-center flex-col md:flex-row">
                    <div className="whitespace-nowrap">
                      <span className="text-sm text-gray-700 dark:text-gray-400">
                        Showing{" "}
                        <span className="font-semibold text-gray-900 dark:text-white">
                          {allLogs?.from}
                        </span>{" "}
                        to{" "}
                        <span className="font-semibold text-gray-900 dark:text-white">
                          {allLogs?.to}
                        </span>{" "}
                        of{" "}
                        <span className="font-semibold text-gray-900 dark:text-white">
                          {allLogs?.total}
                        </span>{" "}
                        Entries
                      </span>
                    </div>

                    <ReactPaginate
                      previousLabel={<GrFormPrevious />}
                      nextLabel={<GrFormNext />}
                      breakLabel={"..."}
                      forcePage={currentPage-2}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={" items-center inline-flex space-x-2"}
                      pageClassName={
                        "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                      }
                      pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
                      previousLinkClassName={
                        "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                      }
                      disabledLinkClassName={"text-gray-400 opacity-50"}
                      nextLinkClassName={
                        "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                      }
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
                      activeLinkClassName={
                        "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
                      }
                    />
                  </div>
                </td>
              </tr>
            )}
           
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LogsList;
