/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from "react";

import { useForm } from "react-hook-form";
import { useStateContext } from "../contexts/ContextProvider";
import { FiSettings } from "react-icons/fi";
import { ThemeSettings } from "../components";

import Image from "../assets/light background.jpg";
import BackDark from "../assets/dark background.webp";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/Auth.Context";


const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { state: ContextState, login } = useContext(AuthContext);

  const { isLoginPending, isLoggedIn, loginError } = ContextState;
  useEffect(() => {
    // console.log(isLoggedIn);
    if (isLoggedIn) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoginPending, isLoggedIn, loginError]);
  const onSubmit = (data) => {

    login(data, () => {
      navigate("/");
      // if (status) {
      // }
      // else {
      //   toast.error("Login Failed")
      // }
    });
  };

  const clickHandler = () => {};

  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();
  return (
    <div>
      <section
        style={{ backgroundImage: `url('${Image}')`, backgroundSize: "cover" }}
        className=""
      >
        <div className="fixed right-0 top-2/4" style={{ zIndex: "1000" }}>
            <button
              type="button"
              onClick={() => setThemeSettings(true)}
              style={{
                color: currentColor,
                borderRadius: "50% 0 50% 50%",
              }}
              className="text-3xl p-3 hover:drop-shadow-xl drop-shadow-lg opacity-50 bg-white dark:bg-[#484B52] hover:bg-light-gray"
            >
              <FiSettings />
            </button>{" "}
        </div>{" "}
        {themeSettings && <ThemeSettings />}{" "}
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto h-screen lg:py-0">
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1
                style={{
                  color: currentColor,
                }}
                className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white"
              >
                Sign in to your account{" "}
              </h1>{" "}
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="space-y-4 md:space-y-6"
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Your email{" "}
                  </label>{" "}
                  <input
                    type="email"
                    {...register("email", { required: true, message: "Email required",})}
                    id="email"
                    className={`border text-sm rounded-lg block w-full p-2.5 ${
                      errors.email
                        ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                        : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    } `}
                    placeholder="name@company.com"
                  />
                   <small className="text-sm text-red-600 dark:text-red-500">
                {errors.email?.message}
              </small>
                </div>{" "}
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Password{" "}
                  </label>{" "}
                  <input
                    type="password"
                    {...register("password", { required: true })}
                    id="password"
                    placeholder="••••••••"
                    current-password="true"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>{" "}
                <div className="flex items-center justify-between">
                  {" "}
                  {/*<a
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    href="/"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  >
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                    Forgot password?
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                            </a>*/}{" "}
                </div>{" "}
                <button
                  type="submit"
                  disabled={isLoginPending}
                  style={{
                    background: currentColor,
                  }}
                  className="w-full disabled:opacity-50 text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  Sign in
                </button>{" "}
              </form>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </section>{" "}
    </div>
  );
};

export default Login;
