import React, { useContext } from "react";
import { useSetState } from "react-use";
import axios from "axios";
import { toast } from "react-toastify";

import { AuthContext } from "../contexts/Auth.Context";


export const ApisContext = React.createContext(null);

const initialState = {
  categories: null,
  products: null,
  productsCatlogs: null,
  productNotFound: false,
  logs: null,
  allLogs: null,
  allProducts: null,
  allProductsCatlogs: null,
  allBundles: null,
  bundles: null,
  allRequests: null,
  allBookRequests: null,
  requests: null,
  bookRequests: null,
  deliveryPricing: null,
  banners: null,
  isPending: false,
  categoriesPending : false,
  bannersPending: false,
  getProductsPending: false,
  getProductsCatlogsPending: false,
  getBundlesPending: false,
  getLogsPending: false,
  getTopRatedPending: false,
  topRated: null,
  selectedProductsIdsSale: null,
  fetchOrdersPending: false,
  fetchRequestsPending: false,
  fetchBookRequestsPending: false,
  orders: null,
  customers: null,
  deletedCustomers: null,
  deletedCustomersAll: null,
  fetchCustomersPending: false,
  fetchDeletedCustomersPending: false,
  fetchDeliveryChargesPending: false,
  dashboard:null,
  chatSending: false,
  invoiceDoc: null,
  notConfiredOrders:[],
  revenueReportOrders: [],
  usersOrders:null
};

export const ApiContextProvider = (props) => {

  const { setUnAuthorizedFun} = useContext(AuthContext);

 
  const BASE_URL = window.config.API_URL;

  const [state, setState] = useSetState(initialState);

  const setPending = (isPending) => setState({ isPending });
  const setCategoriesPending = (categoriesPending) => setState({ categoriesPending });
  const setGetProductsPending = (getProductsPending) => setState({ getProductsPending });
  const setGetProductsCatlogsPending = (getProductsCatlogsPending) => setState({ getProductsCatlogsPending });
  const setGetBundlesPending = (getBundlesPending) => setState({ getBundlesPending });
  const setGetLogsPending = (getLogsPending) => setState({ getLogsPending });
  
  const setAllRequests = (allRequests) => setState({ allRequests });
  const setAllBookRequests = (allBookRequests) => setState({ allBookRequests });
  const setRequests = (requests) => setState({ requests });
  const setBookRequests = (bookRequests) => setState({ bookRequests });
  const setBannnersPending = (bannersPending) => setState({ bannersPending });
  const setCategories = (categories) => setState({ categories });
  const setProducts = (products) => setState({ products });
  const setLogs = (logs) => setState({ logs });
  const setAllProducts = (allProducts) => setState({ allProducts });
  const setProductsCatlogs = (productsCatlogs) => setState({ productsCatlogs });
  const setAllProductsCatlogs = (allProductsCatlogs) => setState({ allProductsCatlogs });
  const setBundles = (bundles) => setState({ bundles });
  const setAllBundles = (allBundles) => setState({ allBundles });
  const setAllLogs = (allLogs) => setState({ allLogs });
  const setProductNotFound = (productNotFound) => setState({ productNotFound });
  const setBanners = (banners) => setState({ banners });

  const setGetTopRatedPending =(getTopRatedPending) => setState({ getTopRatedPending });
  const setTopRated =(topRated) => setState({ topRated });
  const setSelectedProductsIdsSale =(selectedProductsIdsSale) => setState({ selectedProductsIdsSale });



  const setRevenueReportOrders =(revenueReportOrders) => setState({ revenueReportOrders });
  const setFetchOrderPending =(fetchOrdersPending) => setState({ fetchOrdersPending });
  const setOrders =(orders) => setState({ orders });
  const setUsersOrders =(usersOrders) => setState({ usersOrders });
  const setNotConfiredOrders =(notConfiredOrders) => setState({ notConfiredOrders });
  const setDashboard = (dashboard) => setState({ dashboard });
  
  const setFetchCustomersPending =(fetchCustomersPending) => setState({ fetchCustomersPending });
  const setCustomers =(customers) => setState({ customers });
  const setFetchDeletedCustomersPending =(fetchDeletedCustomersPending) => setState({ fetchDeletedCustomersPending });
  const setDeletedCustomers =(deletedCustomers) => setState({ deletedCustomers });
  const setDeletedCustomersAll =(deletedCustomersAll) => setState({ deletedCustomersAll });
  const setChatSending =(chatSending) => setState({ chatSending });
  const setFetchRequestsPending =(fetchRequestsPending) => setState({ fetchRequestsPending });
  const setFetchBookRequestsPending =(fetchBookRequestsPending) => setState({ fetchBookRequestsPending });
  const setFetchDeliveryChargesPending =(fetchDeliveryChargesPending) => setState({ fetchDeliveryChargesPending });
  const setDeliveryPricing =(deliveryPricing) => setState({ deliveryPricing });
  const setInvoiceDoc =(invoiceDoc) => setState({ invoiceDoc });

  const setPendingFun = (val) => {
    setPending(val);
  }
  
  const setSelectedProductsIdsSaleFun = (val) => {
    setSelectedProductsIdsSale(val);
  }

  const createBanner = async (bannerData, user, navigate) => {
    setPending(true);

    try {
      const tokenStr = user.token;

      const response = await axios.post(BASE_URL + "banners", bannerData, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      setPending(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Banner successfully added!");
          navigate("/banners");
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Banner not added");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };
  const getBanners = async (user) => {
    //   console.log(user, "getCategories");
    setBannnersPending(true);
    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + "banners", {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setBannnersPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              setBanners(response?.data?.data);

              //   localStorage.removeItem("user");
              // toast.success("Data successfully Received");
              // navigate("/");
            } else {
              // toast.error("Error data not found");
            }
          } else {
            // toast.error("Banners not found" + response);
          }
        });
    } catch (error) {
      setBannnersPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        setBanners([])
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };
  const deleteBanner = async (bannerId, user) => {
    setPending(true);
    try {
      const tokenStr = user.token;

      const response = await axios.delete(BASE_URL + `banners/${bannerId}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setPending(false);
      if (response.status === 200) {
        if (response.data.Success === true) {
          toast.dismiss();
          toast.success("Banner successfully deleted!");
          getBanners(user);
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Banner not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  const updateCategory = async (catData, catId, user, navigate) => {
    setPending(true);
    // console.log(catId, "catData updated")
    try {
      const tokenStr = user.token;

      const response = await axios.post(BASE_URL + "updateCategory/"+catId, catData, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      setPending(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Category successfully Upadated!");
          navigate("/categories");
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Category not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        // console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };
  const createCategory = async (catData, user, navigate) => {
    setPending(true);

    const tokenStr = user.token;
    try {

      const response = await axios.post(BASE_URL + "category", catData, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      setPending(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Category successfully added!");
          navigate("/categories");
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Category not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.data);
      }
    }
  };
  const getCategories = async (user) => {
    //   console.log(user, "getCategories");
    setCategoriesPending(true);
    
    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + "categories", {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setCategoriesPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              setCategories(response?.data?.data);

              //   localStorage.removeItem("user");
              // toast.success("Data successfully Received");
              // navigate("/");
            } else {
              toast.dismiss();
              toast.error("Error data not found");
            }
          } else {
            toast.dismiss();
            toast.error("Categories not found" + response);
          }
        });
    } catch (error) {
      setCategoriesPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };



  const getTopRatedProducts = async (user) => {
    //   console.log(user, "getCategories");
    setGetTopRatedPending(true);
    

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + "topRatedProducts", {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setGetTopRatedPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              setTopRated(response.data.data);
              // console.log("top rated products", response.data.data)
              
              //   localStorage.removeItem("user");
              // toast.success("Data successfully Received");
              // navigate("/");
            } else {
              // toast.error("Error data not found");
            }
          } else {
            // toast.error("data not found" + response);
          }
        });
    } catch (error) {
      setGetTopRatedPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };
  const deleteTopRated = async (topRatedId, user) => {
    setPending(true);
    try {
      const tokenStr = user.token;

      const response = await axios.delete(BASE_URL + `topRatedProducts/${topRatedId}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setPending(false);
      if (response.status === 200) {
        if (response.data.Success === true) {
          toast.dismiss();
          toast.success("topRated successfully deleted!");
          getTopRatedProducts(user);
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: topRated Books not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  const getProducts = async (user, page) => {
    //   console.log(user, "getCategories");
    setGetProductsPending(true);
    let url; 
    
    if (page && page >= 1) {
      url = `products?page=${page}`;
    } else {
      url = "products"; 
    }

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + url, {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setGetProductsPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              setProducts(response.data.data.data);
              setProductNotFound(false);
              // console.log("products", response.data.data.data)
              setAllProducts(response.data.data)
              //   localStorage.removeItem("user");
              // toast.success("Data successfully Received");
              // navigate("/");
            } else {
              // toast.error("Error data not found");
              console.log("Error: ", response.data.data);
              setProductNotFound(true);
            }
          } else {
            // toast.error("data not found" + response);
            console.log(response.data.data);
            setProductNotFound(true);
          }
        });
    } catch (error) {
      setGetProductsPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        setProductNotFound(true);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };
 
  const deleteBundle = async (bundleId, user) => {
    setPending(true);
    try {
      const tokenStr = user.token;

      const response = await axios.delete(BASE_URL + `bundles/${bundleId}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setPending(false);
      if (response.status === 200) {
        if (response.data.Success === true) {
          toast.dismiss();
          toast.success("Bundle successfully deleted!");
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Book not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  const deleteProducts = async (productId, user) => {
    setPending(true);
    try {
      const tokenStr = user.token;

      const response = await axios.delete(BASE_URL + `products/${productId}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setPending(false);
      if (response.status === 200) {
        if (response.data.Success === true) {
          toast.dismiss();
          toast.success("Book successfully deleted!");
          getProducts(user);
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Book not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };
  const createProduct = async (productData, user, navigate) => {
    setPending(true);

    try {
      const tokenStr = user.token;

      const response = await axios.post(BASE_URL + "products", productData, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      setPending(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Book successfully added!");
          if (productData?.is_old) {
            navigate(-1);
          } else {
            navigate(-1);
          }
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Book not found");
      } else if (response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };
  const updateProduct = async (productDat, productId, user, navigate) => {
    setPending(true);
    // console.log(productId, "catData updated")
    try {
      const tokenStr = user.token;

      const response = await axios.post(BASE_URL + "products/"+productId, productDat, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      setPending(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Book successfully Upadated!");
          navigate(-1);
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Book not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  const fillterProducts = async (searchQuery, user, page) => {
    // setPending(true);

    let url;
    if (page) {
      url = `filterSearchProduct?page=${page}`
    } else {
      url = `filterSearchProduct`
    }

    setGetProductsPending(true);
    try {
      const tokenStr = user.token;

      const response = await axios.post(BASE_URL + url, searchQuery, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setGetProductsPending(false);
      // setPending(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          setAllProducts(response.data.data);
          setProducts(response.data.data.data);
          // console.log(response.data.data, "success");
          return response.data;
        } else if (response.data.status === "error") {
          setAllProducts(null);
          setProducts(null);
          // toast.error(response.data.message);
        } else {
          setAllProducts(null);
          setProducts(null);
          // toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        setAllProducts(null);
          setProducts(null);
        // toast.error("Request Error: Product not found");
      } else if (response.status === 401) {
        setAllProducts(null);
        setProducts(null);
        setUnAuthorizedFun(true);
      } else {
        setAllProducts(null);
          setProducts(null);
        // toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      // setPending(false);
      setGetProductsPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setAllProducts(null);
        setProducts(null);
        setUnAuthorizedFun(true);

      } else {
        setAllProducts(null);
          setProducts(null);
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };
 
  const fillterProductsCatlogs = async (searchQuery, user, page) => {
    // setPending(true);

    let url;
    if (page) {
      url = `filterSearchProduct?page=${page}`
    } else {
      url = `filterSearchProduct`
    }

    setGetProductsCatlogsPending(true);
    try {
      const tokenStr = user.token;

      const response = await axios.post(BASE_URL + url, searchQuery, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setGetProductsCatlogsPending(false);
      // setPending(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          setAllProductsCatlogs(response.data.data);
          setProductsCatlogs(response.data.data.data);
          // console.log(response.data.data, "success");
          return response.data;
        } else if (response.data.status === "error") {
          setAllProductsCatlogs(null);
          setProductsCatlogs(null);
          // toast.error(response.data.message);
        } else {
          setAllProductsCatlogs(null);
          setProductsCatlogs(null);
          // toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        setAllProductsCatlogs(null);
        setProductsCatlogs(null);
        // toast.error("Request Error: Product not found");
      } else if (response.status === 401) {
        setAllProductsCatlogs(null);
        setProductsCatlogs(null);
        setUnAuthorizedFun(true);
      } else {
        setAllProductsCatlogs(null);
        setProductsCatlogs(null);
        // toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      // setPending(false);
      setGetProductsCatlogsPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setAllProductsCatlogs(null);
        setProductsCatlogs(null);
        setUnAuthorizedFun(true);

      } else {
        setAllProductsCatlogs(null);
          setProductsCatlogs(null);
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };
 
 
  const fetchBundles = async (user, page) => {
    // setPending(true);

    let url;
    if (page) {
      url = `bundles?page=${page}`
    } else {
      url = `bundles`
    }

    setGetBundlesPending(true);
    try {
      const tokenStr = user.token;

      const response = await axios.get(BASE_URL + url, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setGetBundlesPending(false);
      // setPending(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          setAllBundles(response.data.data);
          setBundles(response.data.data.data);
          // console.log(response.data.data, "success");
          return response.data;
        } else if (response.data.status === "error") {
          setAllBundles(null);
          setBundles(null);
          // toast.error(response.data.message);
        } else {
          setAllBundles(null);
          setBundles(null);
          // toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        setAllBundles(null);
        setBundles(null);
        // toast.error("Request Error: Product not found");
      } else if (response.status === 401) {
        setAllBundles(null);
        setBundles(null);
        setUnAuthorizedFun(true);
      } else {
        setAllBundles(null);
        setBundles(null);
        // toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      // setPending(false);
      setGetBundlesPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setAllBundles(null);
        setBundles(null);
        setUnAuthorizedFun(true);

      } else {
        setAllBundles(null);
          setBundles(null);
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };
 
  const createBundle = async (user, formData) =>{
    setPendingFun(true);
    try {
      const tokenStr = user.token;

      const response = await axios.post(BASE_URL + 'bundles', formData, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setPendingFun(false);
      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Bundle created successfully");
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Api not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.data);
      }
    }
    
  }
 


  const fetchCustomers = async (user) => {
    setFetchCustomersPending(true);
    

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + "user_list", {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setFetchCustomersPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              setCustomers(response.data.data);
              // console.log("orders", response.data.data)
              
              //   localStorage.removeItem("user");
              // toast.success("Data successfully Received");
              // navigate("/");
            } else {
              // toast.error("Error data not found");
            }
          } else {
            // toast.error("data not found" + response);
          }
        });
    } catch (error) {
      setFetchCustomersPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };
 


  const fetchDeletedCustomers = async (user, page, query) => {
    setFetchDeletedCustomersPending(true);
    let path = "delete-users";
    if (page && query) {
      path += "?page=" +page + "&search=" + query
    } else if (page) {
      path += "?page=" +page
    }

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + path, {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setFetchDeletedCustomersPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              setDeletedCustomers(response.data.data.data);
              setDeletedCustomersAll(response.data.data)
              // console.log("orders", response.data.data)
              
              //   localStorage.removeItem("user");
              // toast.success("Data successfully Received");
              // navigate("/");
            } else {
              // toast.error("Error data not found");
            }
          } else {
            // toast.error("data not found" + response);
          }
        });
    } catch (error) {
      setFetchDeletedCustomersPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };
  const customerChangeStatus = async (id, user) => {
    const tokenStr = user.token;
    // console.log(data, user, "token", tokenStr)
    try {

      const response = await axios.post(BASE_URL + `user_active_inactive/${id}`, {}, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Status successfully changed");
          fetchCustomers(user);
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Api not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
   
      if (error.response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.data);
      }
    }
  };

  const fetchNotConfiredOrders = async (user) => {
    setFetchOrderPending(true);
    

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + "listOrderPaymentNotConfirmed", {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setFetchOrderPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              setNotConfiredOrders((response.data.data).reverse());

              //   localStorage.removeItem("user");
              // toast.success("Data successfully Received");
              // navigate("/");
            } else {
              // toast.error("Error data not found");
            }
          } else {
            // toast.error("data not found" + response);
          }
        });
    } catch (error) {
      setFetchOrderPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response.data.message);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };

  const fetchOrders = async (user) => {
    setFetchOrderPending(true);
    

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + "admin/orders", {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setFetchOrderPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              setOrders(response.data.data);
              // console.log("orders", response.data.data)
              
              //   localStorage.removeItem("user");
              // toast.success("Data successfully Received");
              // navigate("/");
            } else {
              // toast.error("Error data not found");
            }
          } else {
            // toast.error("data not found" + response);
          }
        });
    } catch (error) {
      setFetchOrderPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response.data.message);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };

  const fetchDashboard = async (user) => {
    setPending(true);
    

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + "admin/dashboard", {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              setDashboard(response.data.data);
              // console.log("dashboard", response.data.data)
              
             
            } else {
              // toast.error("Error data not found");
            }
          } else {
            // toast.error("data not found" + response);
          }
        });
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };


  const sendNotification = async (data, user) => {
    setPending(true);

    const tokenStr = user.token;
    // console.log(data, user, "token", tokenStr)
    try {

      const response = await axios.post(BASE_URL + "sendNotification", data, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      setPending(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Notification sent successfully");
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Api not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.data);
      }
    }
  };

  const sendEmails = async (data, user) => {
    setPending(true);

    const tokenStr = user.token;
    // console.log(data, user, "token", tokenStr)
    try {

      const response = await axios.post(BASE_URL + "sendBulkMail", data, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      setPending(false);

      if (response.status === 200) {
        setPending(false);
        if (response.data.Success === true && response.data.data) {
          setPending(false);
          toast.dismiss();
          toast.success("Mail sent successfully");
          return response.data;
        } else if (response.data.status === "error") {
          setPending(false);
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          setPending(false);
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        setPending(false);
        toast.dismiss();
        toast.error("Request Error: Api not found");
      } else if (response.status === 401) {
        setPending(false);
        // toast.error("Unauthorized: Please log in again");
      } else {
        setPending(false);
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        setPending(false);
        setUnAuthorizedFun(true);
      } else {
        setPending(false);
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.data);
      }
    }
  };

// fetch Delivert Charges
  const fetchDeliveryCharges = async (user) => {
    //   console.log(user, "getCategories");
    setFetchDeliveryChargesPending(true);
  
    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + "deliveryPricing", {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setFetchDeliveryChargesPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              // console.log("requests", response.data.data.data)
              setDeliveryPricing(response.data.data)
              // console.log(response.data.data, "data")
            } else {
              // toast.error("Error data not found");
              console.log("Error: ", response.data.data);
            }
          } else {
            // toast.error("data not found" + response);
            console.log(response.data.data);
          }
        });
    } catch (error) {
      setFetchDeliveryChargesPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };

// fetch support requests
  const fetchRequests = async (user, page, status) => {
    //   console.log(user, "getCategories");
    setFetchRequestsPending(true);
    let url; 
    
    if (page && page >= 1 && status) {
      url = `get_request_support?page=${page}&status=${status}`;
    } else if (page && page >= 1 ) { 
      url = `get_request_support?page=${page}`;
    } else if (status) { 
      url = `get_request_support?status=${status}`;
    }
    else {
      url = "get_request_support"; 
    }

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + url, {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setFetchRequestsPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              // console.log("requests", response.data.data.data)
              setRequests(response.data.data.data)
              setAllRequests(response.data.data)
              // console.log(response.data.data, "data")
            } else {
              // toast.error("Error data not found");
              console.log("Error: ", response.data.data);
            }
          } else {
            // toast.error("data not found" + response);
            console.log(response.data.data);
          }
        });
    } catch (error) {
      setFetchRequestsPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };

  // fetch Book requests
  const fetchBookRequests = async (user, page, status) => {
    //   console.log(user, "getCategories");
    setFetchBookRequestsPending(true);
    let url; 
    
    if (page && page >= 1 && status) {
      url = `requested_books?page=${page}&status=${status}`;
    } else if (page && page >= 1 ) { 
      url = `requested_books?page=${page}`;
    } else if (status) { 
      url = `requested_books?status=${status}`;
    }
    else {
      url = "requested_books"; 
    }

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + url, {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setFetchBookRequestsPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              // console.log("requests", response.data.data.data)
              setBookRequests(response.data.data.data)
              setAllBookRequests(response.data.data)
              // console.log(response.data.data, "data")
            } else {
              // toast.error("Error data not found");
              console.log("Error: ", response.data.data);
            }
          } else {
            // toast.error("data not found" + response);
            console.log(response.data.data);
          }
        });
    } catch (error) {
      setFetchBookRequestsPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };

  // download invoice file
  const downLoadInvoice = async (id, user) => {
    setPending(true);
    const data = new FormData();
    id = parseInt(id);
    id = id - 23;
    const invoiceId = "IN-" + id;
    const tokenStr = user.token;
    // console.log(data, user, "token", tokenStr)
    try {

      const response = await axios.get(BASE_URL + "downloadInvoice/"+invoiceId, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      setPending(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Downloaded invoice file");
          console.log(response?.data?.data, "invoices");
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        }
        setInvoiceDoc(response.data);
        // console.log(response.data, " response: invvoices");
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Api not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPending(false);
      if (error.response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };
  // chatPost 
  const chatPost = async (data, user) => {
    setChatSending(true);
    const tokenStr = user.token;
    // console.log(data, user, "token", tokenStr)
    try {

      const response = await axios.post(BASE_URL + "request_support_chat_admin", data, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      setChatSending(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          // toast.success("success!");
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Api not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setChatSending(false);
      if (error.response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };


  // Revenue Report
  const fetchRevenueReportOrders = async (user, start_date, end_date) => {
    setFetchOrderPending(true);
    
    let url = "orderDateSearch";
    if (start_date && end_date) {
      url = `orderDateSearch?start_date=${start_date}&end_date=${end_date}`;
    }

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + url, {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          setFetchOrderPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              setRevenueReportOrders(response.data.data);
              
              //   localStorage.removeItem("user");
              // toast.success("Data successfully Received");
              // navigate("/");
            } else {
              // toast.error("Error data not found");
            }
          } else {
            // toast.error("data not found" + response);
          }
        });
    } catch (error) {
      setFetchOrderPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };
  const updatetMinOrderAmount = async (amount, user) => {

    const tokenStr = user.token;
    try {

      const response = await axios.post(BASE_URL + "update_minimum_order_amount", amount, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Updated successfully added!");
          fetchDeliveryCharges(user);
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.data);
      }
    }
  };

  // get api logs list
  const getApiLogs = async (user, page, searchQuery) => {
    //   console.log(user, "getCategories");
    setGetLogsPending(true);
    let url;

    if (searchQuery) {
      url = `logs?page=${page}&search=${searchQuery}`
    } else { 
      url = `logs?page=${page}`
    }

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + url, {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setGetLogsPending(false);
          if (response?.status === 200) {
            if (response?.data) {
              setLogs(response?.data?.data);
              setAllLogs(response?.data)
            }
          } else {
            // toast.error("data not found" + response);
            console.log(response?.data?.data);
          }
        });
    } catch (error) {
      setGetLogsPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };

// support message readed api call
  const readNotification = async (id, user) => {

    const tokenStr = user.token;
    try {

      const response = await axios.get(BASE_URL + "support-chat/"+id, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          fetchDashboard(user);
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.data);
      }
    }
  };
  // fetch users orders
  const fetchUsersOrders = async (id, user) => {
    setFetchOrderPending(true);
    

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + "user-orders/"+id, {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setFetchOrderPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              setUsersOrders(response.data.data);
              // console.log("orders", response.data.data)
              
              //   localStorage.removeItem("user");
              // toast.success("Data successfully Received");
              // navigate("/");
            } else {
              // toast.error("Error data not found");
            }
          } else {
            // toast.error("data not found" + response);
          }
        });
    } catch (error) {
      setFetchOrderPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response.data.message);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };


  return (
    <ApisContext.Provider
      value={{
        state,
        setPendingFun,
        getCategories,
        createCategory,
        createBanner,
        getBanners,
        deleteBanner,
        updateCategory,
        getProducts,
        getApiLogs,
        deleteProducts,
        createProduct,
        updateProduct,
        fillterProducts,
        fillterProductsCatlogs,
        fetchBundles,
        deleteBundle,
        createBundle,
        getTopRatedProducts,
        deleteTopRated,
        setSelectedProductsIdsSaleFun,
        fetchOrders,
        fetchDashboard,
        sendNotification,
        fetchRequests,
        fetchBookRequests,
        fetchCustomers,
        customerChangeStatus,
        sendEmails,
        downLoadInvoice,
        chatPost,
        fetchDeliveryCharges,
        fetchNotConfiredOrders,
        fetchRevenueReportOrders,
        updatetMinOrderAmount,
        readNotification,
        fetchUsersOrders,
        fetchDeletedCustomers
      }}
    >
      {props.children}
    </ApisContext.Provider>
  );
};
