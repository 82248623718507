/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { BiFullscreen, BiExitFullscreen } from "react-icons/bi";
import { BsSun, BsMoon, BsFillEyeFill } from "react-icons/bs";

import { useStateContext } from "../contexts/ContextProvider";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import ReactPaginate from "react-paginate";
import "./EditSaleProductsBar.css";
// import "./AddToTopRatedSideBar.css";
import { HiOutlinePencilAlt } from "react-icons/hi";
import {
  MdOutlineDelete,
  MdAdd,
  MdDeleteOutline,
  MdOutlineCancel,
} from "react-icons/md";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { Link } from "react-router-dom";

import NotFoundImg from "../assets/No data-pana.png";

import axios from "axios";
import { toast } from "react-toastify";

const EditSaleProductsBar = () => {
  const BASE_URL = window.config.API_URL;

  const { setEditSaleProductsBar, currentColor } = useStateContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [category, setCategory] = useState("");
  const {
    state: ContextState,
    getProducts,
    setPendingFun,
    getCategories,
    fillterProducts,
    getTopRatedProducts,
    setSelectedProductsIdsSaleFun,
  } = useContext(ApisContext);
  const {
    products,
    getProductsPending,
    allProducts,
    categories,
    fillteredProducts,
    topRated,
    selectedProductsIdsSale,
  } = ContextState;
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;
  const [pageCount, setpageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    getProducts(user);
    getCategories(user);
  }, []);
  useEffect(() => {
    if (allProducts) {
      const total = allProducts?.total;
      const limit = allProducts?.per_page;
      setpageCount(Math.ceil(total / limit));
      setCurrentPage(parseInt(products?.current_page) + 1);
    }
  }, [allProducts, products]);

  const handlePageClick = (data) => {
    let page;
    if (data.selected >= 0) {
      page = data.selected + 1;
      // console.log(page, "page");
      getProducts(user, page);
    }
  };

  useEffect(() => {
    if (category > 0 && searchQuery) {
      const formData = new FormData();
      formData.append("category_id", category);
      formData.append("search", searchQuery);
      fillterProducts(formData, user);
    } else if (searchQuery) {
      const formData = new FormData();
      formData.append("search", searchQuery);
      fillterProducts(formData, user);
    } else if (category > 0) {
      const formData = new FormData();
      formData.append("category_id", category);
      fillterProducts(formData, user);
    }
  }, [searchQuery, category]);

  useEffect(() => {
    console.log(fillteredProducts, "fill");
  }, [fillteredProducts]);

  const handleCheckboxChange = (event, product) => {
    const isChecked = event.target.checked;

    setSelectedProducts((prevSelectedProducts) => {
      if (isChecked) {
        return [...prevSelectedProducts, product];
      } else {
        return prevSelectedProducts.filter(
          (preProduct) => preProduct.id !== product.id
        );
      }
    });
  };

  // const handleSelectAll = (event) => {
  //   if (event.target.checked) {
  //     const allProductIds = products?.map((product) => product.id);
  //     fillteredProducts?.map((product) => allProductIds.push(product.id));
  //     setSelectedProducts(allProductIds);
  //   } else {
  //     setSelectedProducts([]);
  //   }
  // };

  useEffect(() => {
    console.log(
      selectedProductsIdsSale,
      selectedProducts,
      "Selected products  0000000"
    );
    selectedProductsIdsSale?.map((product) =>
      setSelectedProducts((prevSelectedProducts) => {
        return [...prevSelectedProducts, product];
      })
    );
  }, []);

  const handleSubmit = async () => {
    if (selectedProducts.length > 0) {
      console.log("Selected product IDs:", selectedProducts);
      setSelectedProductsIdsSaleFun(selectedProducts);
      setEditSaleProductsBar(false);
    } else {
      toast.info("Please select at least one product");
    }
    // Perform any additional actions with the selected product IDs
  };

  return (
    <div className="bg-half-transparent w-screen fixed nav-item top-0 right-0">
      <div className="float-right w-[330px] sm:w-[530px] md:w-[630px] EditSaleProductsBar-modal dark:text-gray-200  bg-white dark:bg-[#484B52] ">
        <div className="flex justify-between items-center p-4 ml-4 pb-0">
          <p className="font-semibold text-lg">Select Products</p>
          <button
            type="button"
            onClick={() => setEditSaleProductsBar(false)}
            style={{ color: "rgb(153, 171, 180)", borderRadius: "50%" }}
            className="text-2xl p-3 hover:drop-shadow-xl hover:bg-light-gray"
          >
            <MdOutlineCancel />
          </button>
        </div>
        <div className="p-4 py-1 mx-2">
          <div className=" relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input autoComplete="off"
              type="text"
              id="simple-search"
              onChange={(e) => setSearchQuery(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
            />
          </div>
          {/*<div className="w-full mt-1">
            <select
              id="category"
              onChange={(e) => {
                setCategory(e.target.value);
              }}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option defaultValue value={0}>
                All Categories
              </option>
              {categories?.map((item, index) => (
                <option key={index} value={item?.id}>
                  {item?.category_name}
                </option>
              ))}
            </select>
              </div>*/}
        </div>
        <div className="p-3 overflow-hidden overflow-y-auto h-full">
          <div className="flex flex-col justify-between gap-4">
            <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-sm text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="p-4">
                      {/* <div className="flex items-center">
                        <input
                          id="checkbox-all-search"
                          type="checkbox"
                          onChange={handleSelectAll}
                          checked={
                            selectedProducts?.length === products?.length ||
                            fillteredProducts?.length
                          }
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="checkbox-all-search"
                          className="sr-only"
                        >
                          checkbox
                        </label>
                        </div>*/}
                    </th>
                    <th scope="col" className="ps-6 px-3 py-3">
                      Product Image
                    </th>
                    <th scope="col" className=" px-3 py-3 whitespace-nowrap">
                      Product name
                    </th>
                    <th scope="col" className="px-3 py-3">
                      Category
                    </th>

                    <th scope="col" className="px-3 py-3">
                      Price
                    </th>
                    <th scope="col" className="px-3 py-3 whitespace-nowrap">
                      SALE PRICE
                    </th>
                    <th scope="col" className="px-3 py-3">
                      STOCK
                    </th>
                    <th scope="col" className="px-3 py-3">
                      STATUS
                    </th>

                    {/*<th scope="col" className="px-3 py-3">
                PUBLISHED
              </th>*/}
                  </tr>
                </thead>
                <tbody>
                  {getProductsPending && (
                    <>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th
                          colSpan={11}
                          scope="row"
                          className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <div
                            role="status"
                            className="animate-pulse flex items-center gap-8"
                          >
                            <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                              <svg
                                className="w-6 h-6 text-gray-200"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 640 512"
                              >
                                <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                              </svg>
                            </div>
                            <div className="w-1/6">
                              <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                            </div>
                            <div className="w-1/4">
                              <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                            </div>

                            <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                            <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                            <span className="sr-only">Loading...</span>
                          </div>
                        </th>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th
                          colSpan={11}
                          scope="row"
                          className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <div
                            role="status"
                            className="animate-pulse flex items-center gap-8"
                          >
                            <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                              <svg
                                className="w-6 h-6 text-gray-200"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 640 512"
                              >
                                <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                              </svg>
                            </div>
                            <div className="w-1/6">
                              <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                            </div>
                            <div className="w-1/4">
                              <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                            </div>

                            <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                            <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                            <span className="sr-only">Loading...</span>
                          </div>
                        </th>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th
                          colSpan={11}
                          scope="row"
                          className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <div
                            role="status"
                            className="animate-pulse flex items-center gap-8"
                          >
                            <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                              <svg
                                className="w-6 h-6 text-gray-200"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 640 512"
                              >
                                <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                              </svg>
                            </div>
                            <div className="w-1/6">
                              <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                            </div>
                            <div className="w-1/4">
                              <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                            </div>

                            <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                            <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                            <span className="sr-only">Loading...</span>
                          </div>
                        </th>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th
                          colSpan={11}
                          scope="row"
                          className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <div
                            role="status"
                            className="animate-pulse flex items-center gap-8"
                          >
                            <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                              <svg
                                className="w-6 h-6 text-gray-200"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 640 512"
                              >
                                <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                              </svg>
                            </div>
                            <div className="w-1/6">
                              <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                            </div>
                            <div className="w-1/4">
                              <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                            </div>

                            <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                            <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                            <span className="sr-only">Loading...</span>
                          </div>
                        </th>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th
                          colSpan={11}
                          scope="row"
                          className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <div
                            role="status"
                            className="animate-pulse flex items-center gap-8"
                          >
                            <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                              <svg
                                className="w-6 h-6 text-gray-200"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 640 512"
                              >
                                <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                              </svg>
                            </div>
                            <div className="w-1/6">
                              <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                            </div>
                            <div className="w-1/4">
                              <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                            </div>

                            <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                            <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                            <span className="sr-only">Loading...</span>
                          </div>
                        </th>
                      </tr>
                      <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                        <th
                          colSpan={11}
                          scope="row"
                          className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          <div
                            role="status"
                            className="animate-pulse flex items-center gap-8"
                          >
                            <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full dark:bg-gray-700">
                              <svg
                                className="w-6 h-6 text-gray-200"
                                xmlns="http://www.w3.org/2000/svg"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 640 512"
                              >
                                <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                              </svg>
                            </div>
                            <div className="w-1/6">
                              <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                            </div>
                            <div className="w-1/4">
                              <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                              <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                            </div>

                            <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                            <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                            <span className="sr-only">Loading...</span>
                          </div>
                        </th>
                      </tr>
                    </>
                  )}

                  {!getProductsPending && !products && (
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td colSpan={11} className="p-4 py-2 ">
                        <div className="flex justify-center items-left md:items-center flex-col md:flex-row">
                          <img
                            className="max-h-56 w-56 sm:max-w-[450px] sm:max-h-[450px]"
                            src={NotFoundImg}
                            alt=""
                          />
                        </div>
                      </td>
                    </tr>
                  )}

                  {!getProductsPending &&
                    products &&
                    products?.map((product, index) => {
                      return (
                        <tr
                          key={index}
                          className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                        >
                          <td className="w-4 p-4">
                            <div className="flex items-center">
                              <input autoComplete="off"
                                id={`checkbox-table-search-${index}`}
                                type="checkbox"
                                onChange={(e) =>
                                  handleCheckboxChange(e, product)
                                }
                                checked={selectedProducts.some(
                                  (selectedProduct) =>
                                    selectedProduct.id === product.id
                                )}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label
                                htmlFor="checkbox-table-search-1"
                                className="sr-only"
                              >
                                checkbox
                              </label>
                            </div>
                          </td>
                          <th
                            scope="row"
                            className=" px-3 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white flex justify-center"
                          >
                            <div className="rounded-lg h-12 w-12 border flex justify-center items-center">
                              <img
                                src={product?.product_image?.[0]?.image}
                                className="rounded-lg h-12 w-12 border object-cover"
                                alt="..."
                              />
                            </div>
                          </th>
                          <td className="ps-6 px-3 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                            <div className="whitespace-normal">
                              {product?.title?.substr(0, 23)}
                              {product?.title?.length > 20 ? "..." : ""}
                            </div>
                          </td>
                          <td className="px-3 py-4">
                            {product?.category?.category_name}
                          </td>
                          <td className="px-3 py-4 font-semibold">
                            {product?.price}
                          </td>
                          <td className="px-3 py-4 font-semibold">
                            {Math.round(product?.price -
                              (product?.price * product?.discount_percent) /
                                100)}
                          </td>
                          <td className="px-3 py-4">{product?.stock}</td>
                          <td className="px-3 py-4">
                            {product?.stock > 0 ? (
                              <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                                Selling
                              </span>
                            ) : (
                              <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                                SoldOut
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td colSpan={11} className="p-4 py-2 ">
                        <div className="flex justify-between items-left md:items-center flex-col md:flex-row">
                          <div className="whitespace-nowrap">
                            <span className="text-sm text-gray-700 dark:text-gray-400">
                              Showing{" "}
                              <span className="font-semibold text-gray-900 dark:text-white">
                                {allProducts?.from}
                              </span>{" "}
                              to{" "}
                              <span className="font-semibold text-gray-900 dark:text-white">
                                {allProducts?.to}
                              </span>{" "}
                              of{" "}
                              <span className="font-semibold text-gray-900 dark:text-white">
                                {allProducts?.total}
                              </span>{" "}
                              Entries
                            </span>
                          </div>

                          <ReactPaginate
                            previousLabel={<GrFormPrevious />}
                            nextLabel={<GrFormNext />}
                            breakLabel={"..."}
                            initialPage={currentPage}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handlePageClick}
                            containerClassName={
                              " items-center inline-flex space-x-2"
                            }
                            pageClassName={
                              "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                            }
                            pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
                            previousLinkClassName={
                              "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                            }
                            disabledLinkClassName={"text-gray-400 opacity-50"}
                            nextLinkClassName={
                              "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                            }
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
                            activeLinkClassName={
                              "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
                            }
                          />
                        </div>
                      </td>
                    </tr>
               
                </tbody>
              </table>
            </div>

            <div className=" flex flex-col justify-end gap-4 md:flex-row">
              <div className="mt-5">
                <button
                  type="button"
                  style={{
                    background: currentColor,
                  }}
                  onClick={handleSubmit}
                  className="whitespace-nowrap focus:outline-none text-white hover:opacity-80 font-medium rounded-lg text-base px-3 py-2.5 "
                >
                  Add To Sale
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSaleProductsBar;
