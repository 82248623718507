/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useStateContext } from "../contexts/ContextProvider";
import { AuthContext } from "../contexts/Auth.Context";
import { ApisContext } from "../contexts/ApiConterxt";
import { useParams, useNavigate } from "react-router-dom";

import axios from "axios";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
];
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const EditCuppon = () => {
  const BASE_URL = window.config.API_URL;
  const navigate = useNavigate();
  const { currentColor } = useStateContext();
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;
  const {
    state: ContextState,
    getProducts,
    setPendingFun,
    getCategories,
    setSelectedProductsIdsSaleFun,
  } = useContext(ApisContext);
  let { cupponId } = useParams();
  cupponId = parseInt(cupponId);
  const [cuppon, setCuppon] = useState();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const getCuppon = async (id, user) => {
    setPendingFun(true);
    try {
      const tokenStr = user.token;

      const response = await axios.get(BASE_URL + `coupons/${id}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setPendingFun(false);
      if (response.status === 200) {
        if (response.data.Success === true) {
          // toast.success("Product successfully deleted!");
          setCuppon(response.data.data);
          console.log("product successfully getted", response.data.data);
          return response.data;
        } else if (response.data.status === "error") {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.error("Request Error: Product not found");
      } else if (response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPendingFun(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  useEffect(() => {

    getCuppon(cupponId, user);
  }, []);

  useEffect(() => {
    setFormvalues(cuppon);
  }, [cuppon]);
  const [value, setEditorValue] = useState('');

  const [typeValue, setTypeValue] = useState(watch("type"));

  useEffect(() => {
    setTypeValue(watch("type"));
  }, [watch('type')])

  const setFormvalues = (cuppon) => {
    setValue("name", cuppon?.name);
    setValue("code", cuppon?.coupon_code);
    setValue("discount", cuppon?.discount);
    setValue("status", cuppon?.is_active);
    setValue("type", cuppon?.type);
    setValue("required_coin", cuppon?.required_coin);
    setValue("min_value", cuppon?.min_value);
    setValue("up_to_discount", cuppon?.up_to_discount);
    setEditorValue(cuppon?.description)
  };

  const submitHandler = async (data) => {
    const newData = {};
    newData.coupon_code = data.code;
    newData.discount = data.discount;
    newData.name = data.name;
    // newData.name = value;
    newData.is_active = data.status;
    newData.type = data.type;
    newData.description = value;
    newData.required_coin = data.required_coin;

    if (data.type === "special_coupon") {
      newData.min_value = data.min_value;
      newData.up_to_discount= data.up_to_discount;
    }

    // console.log(newData, "submit");
    setPendingFun(true);
    const params = new URLSearchParams(newData);
    const encodedData = params.toString();

    

    try {
      const tokenStr = user.token;

      const response = await axios.patch(
        BASE_URL + `coupons/${cupponId}`,
        encodedData,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      // setPending(false);
      setPendingFun(false);
      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
         

          toast.success("Cuppon successfully Updated!");

          navigate("/coupons");
          return response.data;
        } else if (response.data.status === "error") {
        
          toast.error(response.data.message);
        } else {
         
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
       

        toast.error("Request Error: Sale not Updated");
      } else if (response.status === 401) {
       
        setUnAuthorizedFun(true);
      } else {
      

        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPendingFun(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  return (
    <div className="mt-24 sm:mt-8 md:mt-5 px-5 sm:px-8 md:px-14">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-5">
        Edit Cuppon
      </div>
      <form onSubmit={handleSubmit((data) => submitHandler(data))} noValidate>
        <div className="grid gap-4 mb-6 md:grid-cols-2">
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Name
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="text"
              id="name"
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.name
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Name"
              {...register("name", {
                required: {
                  value: true,
                  message: "Name required",
                },
              })}
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.name?.message}
            </small>
          </div>
          <div className="mb-3">
            <label
              htmlFor="code"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Cuppon Code
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="text"
              id="code"
              rows={5}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.code
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Cuppon Code"
              {...register("code", {
                required: {
                  value: true,
                  message: "Code required",
                },
              })}
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.code?.message}
            </small>
          </div>
          <div className="mb-3">
            <label
              htmlFor="discount"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Discount
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="number"
              id="discount"
              min={0}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.discount
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Cuppon Discount"
              {...register("discount", {
                required: {
                  value: true,
                  message: "discount required",
                },
              })}
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.discount?.message}
            </small>
          </div>
          <div className="mb-3">
            <label
              htmlFor="name"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Status
              <span className="text-red-600 text-xs">*</span>
            </label>
            <select
              id="status"
              {...register("status", {
                required: {
                  value: true,
                  message: "Status required",
                },
              })}
              defaultValue={cuppon?.is_active ? "1" : "0"} // Set the defaultValue based on the `cuppon?.is_active` value
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.status
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              }`}
            >
              <option value={"1"}>Active</option>
              <option value={"0"}>Inactive</option>
            </select>
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.status?.message}
            </small>
          </div>
          <div className="mb-3">
            <label
              htmlFor="required_coin"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Coin
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="number"
              id="required_coin"
              min={0}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.required_coin
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Cuppon Coin"
              {...register("required_coin", {
                required: {
                  value: true,
                  message: "Coin required",
                },
              })}
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.coin?.message}
            </small>
          </div>
          <div className="mb-3">
            <label
              htmlFor="status"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Type
              <span className="text-red-600 text-xs">*</span>
            </label>
            <select
              id="type"
              {...register("type", {
                required: {
                  value: true,
                  message: "Type required",
                },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.type
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
            >
              <option value={"lifetime"}>Lifetime</option>
              <option value={"first_order"}>First Order</option>
              <option value={"refferral"}>Refferral</option>
              <option value={"special_coupon"}>Special Coupon</option>
            </select>
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.type?.message}
            </small>
          </div>
          {typeValue === 'special_coupon' ? <div className="mb-3">
            <label
              htmlFor="up_to_discount"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Up to discount
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="number"
              id="up_to_discount"
              min={0}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.up_to_discount
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Coupon up to discount"
              {...register("up_to_discount", {
                required: {
                  value: true,
                  message: "up to discount required",
                },
              })}
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.up_to_discount?.message}
            </small>
          </div> :null }
          {typeValue === 'special_coupon' ? <div className="mb-3">
            <label
              htmlFor="min_value"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              min_value
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="number"
              id="min_value"
              min={0}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.min_value
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Coupon min value"
              {...register("min_value", {
                required: {
                  value: true,
                  message: "min value required",
                },
              })}
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.min_value?.message}
            </small>
          </div> :null }
        </div>
        <div className="mb-3">
          <label
            htmlFor="required_coin"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Description
            <span className="text-red-600 text-xs">*</span>
          </label>
          <div className="relative">
            <ReactQuill
              className={
                "dark:text-slate-50 rounded-2xl dark:placeholder:text-slate-50 h-[200px]"
              }
              theme="snow"
              placeholder={"Enter Description here"}
              value={value} onChange={setEditorValue}
              modules={modules}
              formats={formats}
            />
          </div>
          <small className="text-sm text-red-600 dark:text-red-500">
            {errors.description?.message}
          </small>
        </div>
        <div className="text-right mt-24 md:mt-16">
          <button
            style={{
              background: currentColor,
            }}
            type="submit"
            className=" text-white  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:opacity-80"
          >
            Update coupon
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditCuppon;
