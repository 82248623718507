/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
// import { BsArrowRepeat, BsCurrencyDollar, BsTruck } from "react-icons/bs";
import {
  BsArrowRepeat,
  BsBoxFill,
  BsFillBasket3Fill,
  BsTruck,
} from "react-icons/bs";
import { FaBoxOpen } from "react-icons/fa";
import { TfiShoppingCartFull } from "react-icons/tfi";
import { MdDone, MdOutlineAddComment } from "react-icons/md";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { LiaShippingFastSolid } from "react-icons/lia";

import { SplineArea, Donut } from "../components";
import TopLineCard from "../components/dashboard/topLine/TopLineCard";
import SecondLineCard from "../components/dashboard/secondLine/SecondLineCard";
import Paging from "../components/dashboard/PagingTable";
import { useStateContext } from "../contexts/ContextProvider";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { HiUserGroup } from "react-icons/hi";
import ImageResizer from "../components/dashboard/ImageResizerComponent";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const [isSalesTab, setIsSalesTab] = useState(true);

  // const { currentColor } = useStateContext();
  const { currentColor, currentMode } = useStateContext();
  const [ordersdata, setOrdersdata] = useState({
    series: [
      {
        name: "Orders",
        data: [151, 100, 123, 250, 175, 150, 200],
      },
    ],
    options: {
      chart: {
        type: "area",
        height: "auto",
      },

      fill: {
        colors: ["#fff"],
        type: "gradient",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#50df84"],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      grid: {
        show: false,
      },
      xaxis: {
        type: "days",
        categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        labels: {
          show: true,
          rotate: -45,

          style: {
            colors: currentMode === "Dark" ? "#fff" : "",
          },
        },
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          style: {
            colors: currentMode === "Dark" ? "#fff" : "",
          },
        },
      },
      toolbar: {
        show: false,
      },
    },
  });
  const [salesdata, setSalesData] = useState({
    series: [
      {
        name: "Sales",
        data: [7000, 5000, 4000, 9000, 7000, 12000, 10000],
      },
    ],

    options: {
      chart: {
        type: "area",
        height: "auto",
      },

      fill: {
        colors: ["#fff"],
        type: "gradient",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#ff929f"],
      },
      tooltip: {
        show: false,
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      grid: {
        show: false,
      },
      xaxis: {
        type: "days",
        categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        labels: {
          show: true,
          rotate: -45,

          style: {
            colors: currentMode === "Dark" ? "#fff" : "",
          },
        },
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          style: {
            colors: currentMode === "Dark" ? "#fff" : "",
          },
        },
      },
      toolbar: {
        show: false,
        labels: {
          show: false,
        },
      },
    },
  });
  const [donutData, setDonutData] = useState({
    series: [44, 55, 41, 17, 15],
    options: {
      chart: {
        type: "donut",
        foreColor: currentMode === "Dark" ? "#fff" : "",
      },
      legend: {
        position: "top",
      },
      labels: ["Apple", "Mango", "Orange", "Watermelon"],
    },
  });

  const { state: ContextState, fetchDashboard } = useContext(ApisContext);
  const { dashboard } = ContextState;
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;

  useEffect(() => {
    fetchDashboard(user);
  }, []);
  // const Ordersdata = {
  //   series: [
  //     {
  //       name: "Orders",
  //       data: [151, 100, 123, 250, 175, 150, 200],
  //     },
  //   ],
  //   options: {
  //     chart: {
  //       type: "area",
  //       height: "auto",
  //     },

  //     fill: {
  //       colors: ["#fff"],
  //       type: "gradient",
  //     },
  //     dataLabels: {
  //       enabled: false,
  //     },
  //     stroke: {
  //       curve: "smooth",
  //       colors: ["#50df84"],
  //     },
  //     tooltip: {
  //       x: {
  //         format: "dd/MM/yy HH:mm",
  //       },
  //     },
  //     grid: {
  //       show: false,
  //     },
  //     xaxis: {
  //       type: "days",
  //       categories: [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  //       labels: {
  //         show: true,
  //         rotate: -45,

  //         style: {
  //           colors: currentMode === "Dark" ? "#fff" : "",
  //         },
  //       },
  //     },
  //     yaxis: {
  //       show: true,
  //       labels: {
  //         show: true,
  //         style: {
  //           colors: currentMode === "Dark" ? "#fff" : "",
  //         },
  //       },
  //     },
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  // };
  useEffect(() => {
    // console.log(dashboard, "dashboard already");

    if (dashboard) {
      // Ordersdata.series[0].data
      const newOrdersData = [];
      const newSalesData = [];
      const newBestProductsLables = [];
      const newBestProductsSales = [];
      (dashboard?.weekly_orders).filter((item, i) => {
        if (i < 7 && item.day) {
          newOrdersData.push(parseInt(item.total).toFixed(0));
          return item.day;
        }
      });

      setOrdersdata((prevData) => ({
        ...prevData,
        series: [
          {
            name: "Orders",
            data: newOrdersData,
          },
        ],
      }));

      (dashboard?.weekly_sales).filter((item, i) => {
        if (i < 7 && item.day) {
          newSalesData.push(parseInt(item.total).toFixed(0));
          
          return item.day;
        }
      });
      // console.log(newSalesData, "newSalesData");
      setSalesData((prevData) => ({
        ...prevData,
        series: [
          {
            name: "Orders",
            data: newSalesData,
          },
        ],
      }));

      dashboard?.bestSellingProducts?.map((item, i) => {
        newBestProductsLables.push( (item.product.title).length > 15 ? ((item.product.title).substring(0,15)+"...") :(item.product.title) );
        newBestProductsSales.push(parseInt(item.total_sold));
       
      });

      setDonutData((prevData) => ({
        series: newBestProductsSales,
        options: {
          chart: {
            type: "donut",
            foreColor: currentMode === "Dark" ? "#fff" : "",
          },
          legend: {
            position: "top",
          },
          labels: newBestProductsLables,
        },
      }));

      // console.log(newBestProductsLables, "new products", newBestProductsSales);
      // console.log(...apiOrdersChartData, "alsiufguisgfioa", apiOrdersChartData?.length, newOrdersData);
    }
  }, [dashboard]);


  return (
    <>
      <div className="px-5 sm:px-16 mt-24 md:mt-1">
        <h1 className="text-xl dark:text-slate-100">Dashboard Overview</h1>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 px-7 md:px-14 pt-5 justify-items-center justify-center sm:justify-normal  gap-3 xl:gap-5 items-stretch">
        <Link to={"/pendingorders"} className="w-full h-full" > 
          <SecondLineCard
            icon={BsArrowRepeat}
            title={"Orders Pending"}
            count={dashboard?.pending_orders}
          />
        </Link>
        <Link to={"/processingorders"} className="w-full h-full" >
          <SecondLineCard
            icon={BsTruck}
            title={"Orders Proccessing"}
            count={dashboard?.processing_orders}
          />
        </Link>
        <Link to={"/shippedorders"} className="w-full h-full" >
          <SecondLineCard
            icon={LiaShippingFastSolid}
            title={"Orders Shipped"}
            count={dashboard?.shipped_orders}
          />
        </Link>
        <Link to={"/deliveredorders"} className="w-full h-full" >
          <SecondLineCard
            icon={MdDone}
            title={"Orders Delivered"}
            count={dashboard?.delivered_orders}
          />
        </Link>
        <Link to={"/requestbook"} className="w-full h-full" >
          <SecondLineCard
            icon={MdOutlineAddComment}
            title={"Request Books"}
            count={dashboard?.request_books}
          />
        </Link>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 px-7 md:px-14 pt-5 justify-items-center justify-center sm:justify-normal flex-wrap md:flex-nowrap gap-8">
        <TopLineCard
          icon={BsBoxFill}
          iconColor={"text-orange-500"}
          title={"Products"}
          count={dashboard?.total_products}
        />
        <TopLineCard
          icon={BsFillBasket3Fill}
          iconColor={"text-green-500"}
          title={"Categories"}
          count={dashboard?.total_categories}
        />
        <TopLineCard
          icon={TfiShoppingCartFull}
          iconColor={"text-indigo-500"}
          title={"Orders"}
          count={dashboard?.total_orders}
        />
        <TopLineCard
          icon={HiUserGroup}
          iconColor={"text-blue-500"}
          title={"Users"}
          count={dashboard?.total_users}
        />
      </div>
      <div className="px-5 sm:px-12 md:px-14 grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="mt-7 border shadow-lg dark:border-gray-800 p-4 px-6 rounded-lg bg-white dark:bg-gray-700">
          <div className="text-base dark:text-slate-100 font-semibold">
            Weekly Sales
          </div>

          <div className="text-sm dark:text-slate-100 font-medium my-4 border-b-1 dark:border-b-gray-600 flex gap-4">
            <button
              onClick={() => setIsSalesTab(true)}
              className={
                isSalesTab
                  ? "pb-2 border-b-2 px-2 border-b-pink-400 text-pink-500 font-semibold"
                  : "px-2 mb-2"
              }
            >
              Sales
            </button>
            <button
              onClick={() => setIsSalesTab(false)}
              className={
                !isSalesTab
                  ? "pb-2 border-b-2 px-2 border-b-green-600 text-green-500 font-semibold"
                  : "px-2 mb-2"
              }
            >
              Orders
            </button>
          </div>
          <div>
            <SplineArea data={isSalesTab ? salesdata : ordersdata} />
          </div>
        </div>

        <div className="mt-7 border shadow-lg dark:border-gray-800 p-4 px-6 rounded-lg bg-white dark:bg-gray-700">
          <div className="text-base dark:text-slate-100 font-semibold">
            Best Selling Products
          </div>
          <div>
            <Donut data={donutData} />
          </div>
        </div>
      </div>
      <div className="mt-8 px-5 sm:px-12 md:px-14">
        <div className="text-xl pt-2 mb-3 font-semibold dark:text-gray-100">
        Recent Orders
        </div>
        <Paging orders={dashboard?.recent_orders} parPageCount={10} />
      </div>
    </>
  );
};

export default Dashboard;
