import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useStateContext } from "../contexts/ContextProvider";
import { FaPlusCircle, FaUserCircle } from "react-icons/fa";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import { BiSend } from "react-icons/bi";
import { BsChevronLeft } from "react-icons/bs";

import { useForm } from "react-hook-form";

import moment from "moment";
import { HiArrowNarrowLeft } from "react-icons/hi";
const ReplyToCustomer = () => {
  let { chat_id } = useParams();
  chat_id = parseInt(chat_id);
  const {
    state: ContextState,
    fetchRequests,
    readNotification,
    chatPost,
  } = useContext(ApisContext);
  const { allRequests, chatSending } = ContextState;
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;
  const {
    changeStatusRequests,
    setChangeStatusRequests,
    currentColor,
    suppoertChatId,
  } = useStateContext();

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  const chnageStatusHandler = (id) => {
    setChangeStatusRequests(id);
  };
  const [chatArray, setChatArray] = useState([]);
  useEffect(() => {
    if (!suppoertChatId) {
      navigate("/customersSupport");
    } else {
      setChatArray(suppoertChatId?.support_chat);
      for (let chat of suppoertChatId?.support_chat) {
        readNotification(chat?.id, user)
      }
    }
  }, [suppoertChatId]);

    // Custom validation function to check if the input contains only whitespace
    const validateChat = value => {
      if (value.trim() === '') {
        return "Chat message is required";
      }
      return true; // Validation passed
    };

  const submit = async (data) => {
    const formData = new FormData();
    formData.append("message", data.chat);
    formData.append("support_id", chat_id);
    // console.log(formData.get("message"), formData.get("support_id"), "submit");
    reset();
    await chatPost(formData, user);
    setChatArray((prev) => [
      ...prev,
      {
        created_at: moment(),
        id: prev.length > 0 ? prev[prev.length - 1].id + 1 : 1,
        image: null,
        is_send_by_admin: "yes",
        message: data.chat,
        support_id: chat_id,
        updated_at: moment(),
      },
    ]);
    reset();
  };
  const messageRef = useRef();
  useEffect(() => {
    messageRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
    });
  }, [suppoertChatId]);

  const chatWrapperRef = useRef();

  useEffect(() => {
    scrollToBottom();
  }, [chatArray]);

  const scrollToBottom = () => {
    if (chatWrapperRef.current) {
      const chatWrapperElement = chatWrapperRef.current;
      chatWrapperElement.scrollTop = chatWrapperElement.scrollHeight;
    }
  };

  return (
    <>
      <div className="mt-24 sm:mt-24 md:mt-0 px-5 sm:px-8 md:px-14">
        <div className="border dark:border-gray-900 bg-white dark:bg-gray-700 flex flex-col justify-between rounded-lg h-[calc(100vh_-_100px)] min-h-[400px]">
          <div className="relative border rounded-b-2xl border-gray-400 dark:border-gray-800 shadow-md bg-gray-100 dark:bg-gray-600 rounded p-3 flex flex-col justify-between leading-normal">
            <div className="flex justify-between">
              <div
                onClick={() => {
                  navigate("/customersSupport");
                }}
                className="text-2xl cursor-pointer flex items-center text-center justify-start -ml-2 mr-1 dark:text-gray-50"
              >
                <BsChevronLeft />
              </div>
              <div onClick={()=>navigate(`/customersOrders/${suppoertChatId?.user_id}`)} className="flex items-center gap-3 w-full cursor-pointer">
                <div className="text-4xl dark:text-gray-300">
                  <FaUserCircle />
                </div>
                <div className="w-full">
                  <h6 className="text-gray-900 dark:text-gray-50 font-bold text-xl">
                    {suppoertChatId?.title}
                  </h6>
                  <p className="text-gray-900 text-sm dark:text-gray-50 leading-none capitalize">
                    {suppoertChatId?.name}
                  </p>
                </div>
              </div>
              <div className=" flex items-center justify-center text-xl ">
                <button
                  className="h-7 w-7 rounded-full border text-green-700 dark:text-green-300 border-green-700 dark:border-green-300 flex items-center justify-center"
                  onClick={() => chnageStatusHandler(suppoertChatId)}
                >
                  <MdOutlinePublishedWithChanges />
                </button>
              </div>
            </div>
            <div className="my-1 mt-2 border-b dark:border-b-gray-900"></div>
            <div className="px-2">
              <div className="">
                <p className="text-gray-700 dark:text-gray-200 text-base overflow-hidden">
                  {suppoertChatId?.description &&
                    suppoertChatId.description
                      .split("\n")
                      .map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                </p>
              </div>
              <div className="text-sm text-right">
                <p className="text-gray-600 dark:text-gray-300">
                  {moment(new Date(suppoertChatId?.created_at)).format("HH:mm | DD MMM")}
                </p>
              </div>
            </div>
          </div>
          <div
            ref={chatWrapperRef}
            className="overflow-y-auto h-full min-h-[200px] p-3 flex flex-col gap-4"
          >
            {chatArray?.map((chat, i) => {
              console.log(chat, "chat");
              return chat.is_send_by_admin === "yes" ? (
                <div key={i} className="flex justify-end w-full">
                  <div className="max-w-[80%] md:max-w-[60%] w-fit">
                    <div className="border whitespace-pre-wrap dark:border-gray-800 rounded-lg p-2 bg-slate-100 dark:bg-slate-800 dark:text-white">
                      <p>{chat?.message}</p>
                      <img src={chat?.image} alt="" />
                    </div>
                    <div className="text-sm text-right">
                      <p className="text-gray-600 dark:text-gray-300">
                        {moment(chat?.created_at ? new Date(chat?.created_at): new Date()).format("HH:mm | DD MMM")}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div key={i} className="flex flex-col justify-start w-full">
                  <div className="max-w-[80%] overflow-hidden md:max-w-[60%] w-fit ">
                    <div className="border whitespace-pre-wrap dark:border-gray-800 rounded-lg p-2 bg-slate-100 dark:bg-slate-800 dark:text-white">
                      <p>{chat?.message}</p>
                      <img src={chat?.image} alt="" />
                    </div>
                    <div className="text-sm text-right">
                      <p className="text-gray-600 dark:text-gray-300">
                        {moment(new Date(chat?.created_at)).format("HH:mm | DD MMM")}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <form onSubmit={handleSubmit((data) => submit(data))}>
            <div className="flex justify-between gap-3 border rounded-t-2xl border-gray-400 bg-gray-100 dark:bg-gray-600 rounded p-3">
              <div className="flex items-center gap-3 w-full">
                {/* <div className="text-4xl dark:text-gray-300">
                  <FaPlusCircle />
                </div> */}
                <div className="w-full ps-2">
                  <input autoComplete="off"
                    type="text"
                    {...register("chat", {
                      required: {
                        value: true,
                      },
                      validate: validateChat
                    })}
                    placeholder="Type your chat..."
                    className="w-full h-full bg-transparent focus:outline-none dark:text-white"
                  />
                </div>
              </div>
              <div className="rounded-full flex items-center justify-center text-xl dark:text-gray-50">
                <button type="submit">
                  {chatSending ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <BiSend />
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ReplyToCustomer;
