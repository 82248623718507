/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect } from "react";
import { useSetState } from "react-use";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const AuthContext = React.createContext(null);

const user = JSON.parse(localStorage.getItem("user"));
let auth = null;
if (user && user.token) {
  auth = user; 
}


export const AuthContextProvider = (props) => {
  const initialState = {
    user: auth !== null ? auth : false,
    isLoggedIn: auth !== null ? auth : false,
    isLoginPending: false,
    loginError: false,
    unAuthorized: false,
  };
  const BASE_URL = window.config.API_URL;
  const navigate = useNavigate();
  const [state, setState] = useSetState(initialState);

  const setUser = (user) => setState({ user });
  const setLoginPending = (isLoginPending) => setState({ isLoginPending });
  const setLoginSuccess = (isLoggedIn) => setState({ isLoggedIn });
  const setLoginError = (loginError) => setState({ loginError });
  const setUnAuthorized = (unAuthorized) => setState({ unAuthorized });

  useEffect(() => {
    if (state.unAuthorized) {
      setUser(null);
      setLoginSuccess(false);
      localStorage.removeItem("user");
      toast.dismiss();
      toast.error("session expired.");
      navigate('/login');
    }
  }, [state.unAuthorized]);

  const login = async (userData, callback) => {
    setLoginPending(true);
    setLoginSuccess(false);
    setLoginError(null);

    try {
      const response = await axios.post(BASE_URL + "loginUser", userData);

      setLoginPending(false);
      // console.log("resLogin: ", response);
      if (response.status === 200) {
        // console.log("afterLogin 200: ", response);
        if (response.data.Success === true && response.data.Success) {
          // console.log("afterLogin 200 true ", response.data);
          if (response.data.data && response.data.data.token !== "") {
            setUser(response.data.data);
            setLoginSuccess(true);
            localStorage.setItem("user", JSON.stringify(response.data.data));
            toast.dismiss();
            toast.success("Login Success");
            return response.data;
          } else if (response.data.status === "error") {
            // console.log("response.data:-", response.data);
            callback(false, null);
            // setMessage(response.data.message);
          } else {
            // console.log("else innnerrr", response.data);
            toast.error("Error - " + response.data.message);
          }
        } else {
          // console.log(response, "response else error true")
          toast.error("error false" + response);
        }
      } else if (response.status === 404 || response === null) {
        toast.error("Login Error" + response);
      } else if (response.status === 401) {
        toast.error("Login Error", response);
        setLoginError(true);
      } else {
        toast.error("Login Error 12", response);
        // console.error("Login Error", response.data.error);
        setLoginError(true);
      }
    } catch (error) {
      setLoginPending(false);
      if (error.response.status === 400) {
        toast.error(error.response.data.message);  
        // console.log(error.response.data.message);

        
      } else {
        toast.error(error.response.data.message);
      }
      setLoginError(true);
    }
  };

  //   const register = async (userData, callback) => {
  //     setLoginPending(true);
  //     setLoginSuccess(false);
  //     setLoginError(null);

  //     try {
  //       const response = await axios.post(BASE_URL + "register", userData);

  //       setLoginPending(false);
  //       if (response.status === 200) {
  //         if (response.data.status === "success") {
  //           if (response.data.token !== "") {
  //             localStorage.setItem("user", JSON.stringify(response.data));
  //             setUser(response.data.user);
  //             setLoginSuccess(true);
  //             toast.success("Login Success");
  //             return response.data;
  //           } else if (response.data.status === "error") {
  //             console.log("response.data:-", response.data);
  //             callback(false, null);
  //             // setMessage(response.data.message);
  //           }
  //         } else {
  //           toast.error(response.data.message);
  //         }
  //       } else if (response.status === 404 || response === null) {
  //         toast.error("Login Error", response);
  //       } else if (response.status === 401) {
  //         toast.error("Login Error", response);
  //         setLoginError(true);
  //       } else {
  //         toast.error("Login Error 12", response);
  //         console.error("Login Error", response.data.error);
  //         setLoginError(true);
  //       }
  //     } catch (error) {
  //       toast.error("Login Error 11", error);
  //       setLoginError(true);
  //     }
  //   };

  const logout = async (user) => {
    // console.log(user, "logout");

    let tokenStr = user.token;
    try {
      const response = await axios.post(
        BASE_URL + "logoutUser",
        {},
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      // console.log(response, "logged out");

      if (response.status === 200) {
        if (response.data.Success === true) {
          setLoginSuccess(false);
          setUser(null);
          setLoginPending(false);
          setLoginError(null);
          localStorage.removeItem("user");
          toast.dismiss();
          toast.success("Logout successful");
          // navigate("/");
        } else {
          toast.error("Logout failed");
        }
      } else {
        toast.error("Logout failed");
      }
    } catch (error) {
      if (error.response.status === 401) {
        setUnAuthorizedFun(true);
        setLoginSuccess(false);
        setUser(null);
        localStorage.removeItem("user");
        navigate("/login");
      } else {
        toast.error("Logout failed");
      }
    }
  };

  const setUnAuthorizedFun = (value) => {
    setUnAuthorized(value);
    if (value === true) {
      logout();
      localStorage.removeItem("user");
      setLoginSuccess(false);
      setUser(null);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        state,
        login,
        logout,
        setUnAuthorizedFun
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
