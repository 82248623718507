/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";

import { AuthContext } from "../contexts/Auth.Context";
import { ApisContext } from "../contexts/ApiConterxt";

import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";

const ViewLogDetails = () => {
  let { logId } = useParams();
  logId = parseInt(logId);
  const [log, setLog] = useState();
  const [loading, setLoading] = useState(false);

  const { currentColor } = useStateContext();
  const BASE_URL = window.config.API_URL;
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;

  const navigate = useNavigate();

  const fetchLog = async (logId, user) => {
    setLoading(true);
    try {
      const tokenStr = user.token;

      const response = await axios.get(BASE_URL + `logs/${logId}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      if (response.status === 200) {
        if (response.data) {
          setLog(response.data);
          console.log("log successfully getted", response.data);
          setLoading(false);
          return response.data;
        }
      } else if (response.status === 404 || response === null) {
        toast.error("Request Error: Log not found");
      } else if (response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  useEffect(() => {
    fetchLog(logId, user);
  }, []);

  return (
    <div className="mt-24 sm:mt-8 md:mt-5 px-5 sm:px-8 md:px-14">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-5">
        Log Details
      </div>

      {loading && (
        <div role="status" className="space-y-8 animate-pulse">
          <div className="flex flex-col gap-4">
            <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-1.5 md:p-3 rounded-md flex items-center gap-1.5 md:gap-3">
              <div className="min-w-fit">
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-2.5 object-cover"></div>
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-4 object-cover"></div>
              </div>
              <div className="flex gap-3 justify-between items-center w-full md:pr-2">
                <div className="w-full">
                  <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700  mb-2.5"></div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-1.5 md:p-3 rounded-md flex items-center gap-1.5 md:gap-3">
              <div className="min-w-fit">
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-2.5 object-cover"></div>
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-4 object-cover"></div>
              </div>
              <div className="flex gap-3 justify-between items-center w-full md:pr-2">
                <div className="w-full">
                  <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700  mb-2.5"></div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-1.5 md:p-3 rounded-md flex items-center gap-1.5 md:gap-3">
              <div className="min-w-fit">
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-2.5 object-cover"></div>
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-4 object-cover"></div>
              </div>
              <div className="flex gap-3 justify-between items-center w-full md:pr-2">
                <div className="w-full">
                  <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700  mb-2.5"></div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-1.5 md:p-3 rounded-md flex items-center gap-1.5 md:gap-3">
              <div className="min-w-fit">
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-2.5 object-cover"></div>
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-4 object-cover"></div>
              </div>
              <div className="flex gap-3 justify-between items-center w-full md:pr-2">
                <div className="w-full">
                  <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700  mb-2.5"></div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-1.5 md:p-3 rounded-md flex items-center gap-1.5 md:gap-3">
              <div className="min-w-fit">
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-2.5 object-cover"></div>
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-4 object-cover"></div>
              </div>
              <div className="flex gap-3 justify-between items-center w-full md:pr-2">
                <div className="w-full">
                  <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700  mb-2.5"></div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-1.5 md:p-3 rounded-md flex items-center gap-1.5 md:gap-3">
              <div className="min-w-fit">
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-2.5 object-cover"></div>
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-4 object-cover"></div>
              </div>
              <div className="flex gap-3 justify-between items-center w-full md:pr-2">
                <div className="w-full">
                  <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700  mb-2.5"></div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-1.5 md:p-3 rounded-md flex items-center gap-1.5 md:gap-3">
              <div className="min-w-fit">
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-2.5 object-cover"></div>
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-4 object-cover"></div>
              </div>
              <div className="flex gap-3 justify-between items-center w-full md:pr-2">
                <div className="w-full">
                  <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700  mb-2.5"></div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-1.5 md:p-3 rounded-md flex items-center gap-1.5 md:gap-3">
              <div className="min-w-fit">
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-2.5 object-cover"></div>
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-4 object-cover"></div>
              </div>
              <div className="flex gap-3 justify-between items-center w-full md:pr-2">
                <div className="w-full">
                  <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700  mb-2.5"></div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>
              </div>
            </div>
            <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-1.5 md:p-3 rounded-md flex items-center gap-1.5 md:gap-3">
              <div className="min-w-fit">
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-2.5 object-cover"></div>
                <div className="h-2 w-14 bg-gray-300 dark:bg-gray-700 md:w-14 rounded-md mb-4 object-cover"></div>
              </div>
              <div className="flex gap-3 justify-between items-center w-full md:pr-2">
                <div className="w-full">
                  <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-700  mb-2.5"></div>
                  <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-4"></div>
                </div>
              </div>
            </div>
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {!loading && log && (
        <div className="border dark:border-gray-600 bg-white dark:bg-gray-600 p-2 rounded-md overflow-x-auto">
          <div className="flex flex-col gap-1 pr-2">
          <p className={`text-sm md:text-base ${log?.status_code ==="200"?'text-green-600':'text-red-500'}  `}><span className="text-gray-900 dark:text-white font-medium">Status Code: </span> {log?.status_code}</p>
          <p className="text-sm md:text-base text-gray-900 dark:text-white"><span className="font-medium">Method: </span> {log?.method}</p>
          <p className="text-sm md:text-base text-gray-900 dark:text-white"><span className="font-medium">Url: </span> {log?.url}</p>
          <p className="text-sm md:text-base text-gray-900 dark:text-white"><span className="font-medium">Ip Address: </span> {log?.ip_address}</p>
          <p className="text-sm md:text-base text-gray-900 dark:text-white"><span className="font-medium">User Id: </span> {log?.user_id}</p>
            <p className="text-sm md:text-base text-gray-900 dark:text-white"><span className="font-medium">Request Time:</span> {moment(log?.request_time).format('MMM-DD-YYYY HH:mm:ss')}</p>
            <p className="text-sm md:text-base text-gray-900 dark:text-white"><span className="font-medium">Header:</span> {log?.headers}</p>
            <p className="text-sm md:text-base text-gray-900 dark:text-white"><span className="font-medium">Body:</span> {log?.body}</p>
            <p className="text-sm md:text-base text-gray-900 dark:text-white"><span className="font-medium">Response:</span> {log?.response}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewLogDetails;
