/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { BsFillEyeFill } from "react-icons/bs";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { MdAdd, MdOutlineDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";

import Datepicker from "react-tailwindcss-datepicker";

import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { toast } from "react-toastify";

import axios from "axios";
import { useNavigate } from "react-router-dom";
const AddSale = () => {
  const BASE_URL = window.config.API_URL;
  const navigate = useNavigate();
  const { setAddToSaleSideBar, currentColor } = useStateContext();
  const [dateValue, setDateValue] = useState({
    startDate: new Date().toISOString().split("T")[0],
    endDate: (() => {
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow.toISOString().split("T")[0];
    })(),
  });
  const [discounts, setDiscounts] = useState([]);
  const [saleProductIds, setSaleProductIds] = useState([]);
  const {
    state: ContextState,
    getProducts,
    setPendingFun,
    setSelectedProductsIdsSaleFun,
  } = useContext(ApisContext);
  const {
    selectedProductsIdsSale,
  } = ContextState;
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  const handleValueChange = (newValue) => {
    setDateValue(newValue);
  };
  useEffect(() => {
    getProducts(user);
    // console.log(selectedProductsIdsSale, "selectedProductsIdsSale")
  }, []);


  const handleDelete = (itemId) => {
    const currentIds = selectedProductsIdsSale; // Assuming `selectedProductsIdsSale` is the current state value
    const updatedIds = currentIds.filter((id) => id !== itemId);
    setSelectedProductsIdsSaleFun(updatedIds);
  };

  const submitProductHandler = async (data) => {
    setPendingFun(true);
    let name = data.name;
    let end_date = dateValue.endDate;
    let start_date = dateValue.startDate;
    delete data.name;
    delete data.end_date;
    delete data.start_date;

    let keys = Object.keys(data);
    let values = Object.values(data);

    let ids = selectedProductsIdsSale.map(item => item?.id);
    keys.forEach(key => {
      if (!ids.includes(parseInt(key))) {
          delete data[key];
      }
  });
  

    let newkeys = Object.keys(data);
    let newvalues = Object.values(data);
    // setPendingFun(false);
    // return;
    if (newkeys.length <= 0 || newvalues.length <= 0) {
      setPendingFun(false);
      toast.error("Please select a valid product and discount");
      return;
    }
    
    const formData = new FormData();
    formData.append("campaign_name", name);
    formData.append("start_date", start_date);
    formData.append("end_date", end_date);
    formData.append("discounts", newvalues?.join(","));
    formData.append("product_ids", newkeys?.join(","));
    


    try {
      const tokenStr = user.token;

      const response = await axios.post(BASE_URL + "flashSale", formData, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      // setPending(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          setPendingFun(false);

          toast.success("Sale successfully added!");
          setSelectedProductsIdsSaleFun(null);
          navigate("/flashSale");
          return response.data;
        } else if (response.data.status === "error") {
          setPendingFun(false);

          toast.error(response.data.message);
          setSelectedProductsIdsSaleFun(null);
        } else {
          setPendingFun(false);
          toast.error(response.data.message + "-" + response.data.data);
          setSelectedProductsIdsSaleFun(null);
        }
      } else if (response.status === 404 || response === null) {
        setPendingFun(false);

        toast.error("Request Error: Sale not added");
        setSelectedProductsIdsSaleFun(null);
      } else if (response.status === 401) {
        setPendingFun(false);

        // toast.error("Unauthorized: Please log in again");
        setSelectedProductsIdsSaleFun(null);
      } else {
        setPendingFun(false);

        toast.error(`Error ${response.status}: ${response.statusText}`);
        setSelectedProductsIdsSaleFun(null);
      }
    } catch (error) {
      setPendingFun(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
        setSelectedProductsIdsSaleFun(null);
      } else {
        toast.error("Error- " + error.response.data.message);
        setSelectedProductsIdsSaleFun(null);
      }
    }
  };

  const handleChangeSalePrice = (salePrice, id, price) => {
    if (salePrice) {
      clearErrors(`${id}`);
      console.log("id , discount, sale price ----> ", id, ((price - salePrice) / price) * 100, price - (price * ((((price - salePrice) / price) * 100) / 100)));
      setValue(`${id}`, ((price - salePrice) / price) * 100);
    } else {
      setError(`${id}`, { type: 'required', message: 'Sale price required' });
      setValue(`${id}`, "");
    }
    
  }

  return (
    <div className="mt-24 sm:mt-8 md:mt-5 px-5 sm:px-8 md:px-14">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-5">
        Add Sale
      </div>
      <form
        onSubmit={handleSubmit((data) => submitProductHandler(data))}
        noValidate
      >
        <div className="grid gap-6 md:grid-cols-2">
          <div>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Campaign Name
                <span className="text-red-600 text-xs">*</span>
              </label>
              <input autoComplete="off"
                type="text"
                id="name"
                className={`border text-sm rounded-lg block w-full p-2.5 ${
                  errors.name
                    ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                    : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                } `}
                placeholder="Enter Campaign name"
                {...register("name", {
                  required: {
                    value: true,
                    message: "Campaign name required",
                  },
                })}
              />
              <small className="text-sm text-red-600 dark:text-red-500">
                {errors.name?.message}
              </small>
            </div>
          </div>
          <div className="w-full mb-4">
            <small className=" text-gray-900 dark:text-gray-200 text-sm">
              Select From to End Date
              <span className="text-red-600 text-xs">*</span>
            </small>
            <div className="mt-1 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white ">
              <Datepicker value={dateValue} onChange={handleValueChange} />
            </div>
          </div>
        </div>
        <div className="border dark:border-gray-500 text-sm rounded-lg block w-full p-2.5 mb-5">
          <div className="flex justify-between items-center  mb-3">
            <div className="ps-3 text-gray-800 dark:text-gray-300 font-semibold text-xl ">
              Products
            </div>
            <button
              type="button"
              onClick={() => setAddToSaleSideBar(true)}
              style={{
                background: currentColor,
              }}
              className="w-fit justify-center focus:outline-none text-white hover:opacity-80 font-medium rounded-lg text-base px-3 py-2 flex items-center gap-1"
            >
              <div className="text-xl">
                <MdAdd />
              </div>
              Add Product
            </button>
          </div>
          <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-sm text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="ps-6 px-3 py-3">
                    Sale Price
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Discount
                  </th>
                  <th scope="col" className="whitespace-nowrap px-3 py-3">
                    Product Image
                  </th>
                  <th scope="col" className="whitespace-nowrap px-3 py-3">
                    Product name
                  </th>
                  <th scope="col" className="px-3 py-3">
                    Category
                  </th>

                  <th scope="col" className="px-3 py-3">
                    Price
                  </th>
                  <th scope="col" className="px-3 py-3 whitespace-nowrap">
                    SALE PRICE
                  </th>
                  <th scope="col" className="px-3 py-3">
                    STOCK
                  </th>

                  <th scope="col" className="px-3 py-3 pe-5">
                    ACTIONS
                  </th>
                </tr>
              </thead>
              <tbody>
                {selectedProductsIdsSale?.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                    >
                      <td className="ps-6 px-3 py-3 dark:text-white">
                        <input autoComplete="off"
                          type="text"
                          id={`${item.id}`}
                          onChange={(e)=>handleChangeSalePrice(e.target.value, item.id, item?.price)}
                          className={`border text-sm rounded-lg block w-fit p-2.5 ${
                            errors[`${item.id}`]
                              ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                              : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-300 dark:placeholder-gray-200 dark:text-white"
                          } `}
                          placeholder="Enter Sale Price"
                          // onChange={(e) => handleInputChange(e, item.id)}
                        />
                        <small className="text-sm text-red-600 dark:text-red-500">
                          {errors[`${item.id}`]?.message}
                        </small>
                      </td>
                      <td className=" px-3 py-3 dark:text-white">
                        <input autoComplete="off"
                          type="text"
                          readOnly={true}
                          id={`${item.id}`}
                          className={`border text-sm rounded-lg block w-full p-2.5 ${
                            errors[`${item.id}`]
                              ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                              : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-300 dark:placeholder-gray-200 dark:text-white"
                          } `}
                          placeholder="Discount"
                          {...register(`${item.id}`, {
                            required: {
                              value: true,
                              message: "Sale price required",
                            },
                          })}
                          // onChange={(e) => handleInputChange(e, item.id)}
                        />
                        
                      </td>
                      <th
                        scope="row"
                        className="px-3 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white flex justify-center"
                      >
                        <div className="rounded-lg h-12 w-12 border ">
                          <img
                            src={item?.product_image?.[0]?.image}
                            className="rounded-lg h-12 w-12 border object-cover"
                            alt="..."
                          />
                        </div>
                      </th>
                      <td className=" px-3 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        <div className="whitespace-normal">
                          {item?.title?.substr(0, 23)}
                          {item?.title?.length > 20 ? "..." : ""}
                        </div>
                      </td>
                      <td className="px-3 py-4">
                        {item?.category?.category_name}
                      </td>
                      <td className="px-3 py-4 font-semibold">
                        {item?.price}
                      </td>
                      <td className="px-3 py-4 font-semibold">
                        {Math.round(item?.price -
                          (item?.price * item?.discount_percent) / 100)}
                      </td>
                      <td className="px-3 py-4">{item?.stock}</td>

                      <td className="px-3 py-4">
                        <div className="text-2xl">
                          <button
                            type="button"
                            onClick={() => handleDelete(item)}
                            className="flex justify-center font-medium text-red-600 dark:text-red-500 hover:underline"
                          >
                            <MdOutlineDelete />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="text-right">
          <button
            type="submit"
            style={{
              background: currentColor,
            }}
            className=" text-white focus:ring-4 focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:opacity-80"
          >
            Add Sale
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddSale;
