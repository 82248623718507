import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { Link, NavLink } from "react-router-dom";

import { CgCloseO } from "react-icons/cg";
import { ReactComponent as Logo } from "../../assets/BooksPie-short.svg";

const activeNav =
  "text-[#5956e9] block py-2 pl-3 pr-4 lg:p-0 hover:text-[#5956e9] text-body_copy1_b -tracking-[0.8px]";
const normalNav =
  "block py-2 pl-3 pr-4 lg:p-0 hover:text-[#111] text-body_copy1";

const Navbar = () => {
  const [scrolled, setScrolled] = useState();
  const [isOpen, setIsOpen] = useState();
  const classes = classNames("header", {
    scrolled: scrolled,
  });
  const sideMenuClasses = classNames("sideMenu", {
    isOpen: isOpen,
    isNotOpen: !isOpen,
  });
  useEffect(() => {
    const handleScroll = (_) => {
      if (window.pageYOffset > 1) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return (_) => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fontRalwey = {
    fontFamily: "'Raleway', sans-serif",
  };

  return (
    <>
      <div
        style={{
          zIndex: "999999",
        }}
        className={`fixed w-screen top-0 h-screen overflow-hidden bg-[#FBF7F7CC] backdrop-blur-md border p-4 ${sideMenuClasses}`}
      >
        <div className={`flex justify-between items-center `}>
          <div onClick={() => setIsOpen(false)}>
            <Link to={"/"} className="flex items-center gap-2">
              <Logo className="w-[40px] sm:w-[60px]" />{" "}
              <h1 className="text-[#5956E9] text-[20px] md:text-[30px] font-extrabold">
                BooksPie
              </h1>
            </Link>
          </div>
          <button className="p-0.5 text-xl" onClick={() => setIsOpen(false)}>
            <CgCloseO />
          </button>
        </div>
        <div className="h-full w-full flex flex-col mt-[30px] ">
          <ul className="flex flex-col gap-5">
            <li
              onClick={() => setIsOpen(false)}
              className="bg-[#F6F0E4] hover:bg-[#F6F0E4AA] rounded"
            >
              <NavLink
                to={"/"}
                className={({ isActive }) => (isActive ? activeNav : normalNav)}
              >
                Home
              </NavLink>
            </li>
            <li
              onClick={() => setIsOpen(false)}
              className="bg-[#F6F0E4] hover:bg-[#F6F0E4AA] rounded"
            >
              <NavLink
                to={"/"}
                className={({ isActive }) => (isActive ? activeNav : normalNav)}
              >
                Features
              </NavLink>
            </li>
            <li
              onClick={() => setIsOpen(false)}
              className="bg-[#F6F0E4] hover:bg-[#F6F0E4AA] rounded"
            >
              <NavLink
                to={"/"}
                className={({ isActive }) => (isActive ? activeNav : normalNav)}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <nav
        style={{
          zIndex: "99999",
        }}
        className={`bg-transparent border-gray-200 mt-5 sticky top-0 ${classes}`}
      >
        <div className="flex justify-center">
          <div className="2xl:max-w-screen-xl xl:max-w-screen-lg w-full">
            <div
              className={` flex flex-wrap  items-center justify-between mx-auto 2xl:mx-5 xl:mx-0 smm:px-3 sm:mx-3 lg:mx-5 p-3 `}
            >
              <div className={`flex items-center  gap-16`}>
                <Link to={"/"} className="flex items-center gap-2">
                  <Logo className="w-[40px] sm:w-[60px]" />{" "}
                  <h1 className="text-[#5956E9] text-[20px] md:text-[30px] font-extrabold">
                    BooksPie
                  </h1>
                </Link>
                <div
                  className="hidden w-full lg:block lg:w-auto"
                  id="navbar-default"
                >
                  <ul
                    className={`flex md:flex-row lg:gap-12 gap-6 lg:border-0`}
                  >
                    <li>
                      <NavLink
                        to={"/"}
                        className={({ isActive }) =>
                          isActive ? activeNav : normalNav
                        }
                      >
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/"}
                        className={({ isActive }) =>
                          isActive ? activeNav : normalNav
                        }
                      >
                        Features
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={"/"}
                        className={({ isActive }) =>
                          isActive ? activeNav : normalNav
                        }
                      >
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={`flex items-center lg:hidden`}>
                <a href="https://play.google.com/store/apps/details?id=com.sant.bookspie"
                  style={fontRalwey}
                  className="rounded-md sm:rounded-[10px] shadow-xl hover:scale-105 pt-[7px] lg:pt-[9px] pb-[6px] lg:pb-[8px] px-[12px] whitespace-nowrap sm:pt-[13px] sm:pb-[12px] sm:px-[20px] font-bold text-[#5956E9] bg-[#FAB8C3] text-[14px] lg:text-[20px] leading-normal"
                >
                  Download App
                </a>
                <button
                  onClick={() => setIsOpen(true)}
                  data-collapse-toggle="navbar-default"
                  type="button"
                  className="inline-flex items-center p-0.5 px-0 ml-3 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                  aria-controls="navbar-default"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              <div
                className="hidden w-full lg:block lg:w-auto"
                id="navbar-default"
              >
                <div className={`flex items-center gap-5 `}>
                  <div className="relative">
                    {/* <GlobeIcon
                      onClick={() => setIsOpenLangModal(!isOpenLangModal)}
                      className="cursor-pointer"
                    /> */}
                    <a  href="https://play.google.com/store/apps/details?id=com.sant.bookspie"
                      style={fontRalwey}
                      className="rounded-[10px] shadow-xl hover:scale-105 pt-[9px] pb-[8px] px-[16px] whitespace-nowrap sm:pt-[13px] sm:pb-[12px] sm:px-[20px] font-bold text-[#5956E9] bg-[#FAB8C3] text-[20px] leading-normal"
                    >
                      Download App
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <style jsx="true">{`
          .header {
            transition: background-color 0.2s;
          }
          .header.scrolled {
            background-color: #fff;
            backdrop-filter: blur(9px);
          }
          .sideMenu {
            transition: transform 0.7s;
          }
          .sideMenu.isOpen {
            transform: translate(0%);
          }
          .sideMenu.isNotOpen {
            transform: translate(100%);
          }
        `}</style>
      </nav>
    </>
  );
};

export default Navbar;
