/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from "react";
// import { BiCloudDownload } from "react-icons/bi";

// import Datepicker from "react-tailwindcss-datepicker";

import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useStateContext } from "../contexts/ContextProvider";

import { MdOutlinePublishedWithChanges } from "react-icons/md";
import moment from "moment/moment";

import ReactPaginate from "react-paginate";
import NotFoundImg from "../assets/No data-pana.png";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { BsFillEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useForm } from "react-hook-form";

const Paging = ({ users, parPageCount }) => {
  const BASE_URL = window.config.API_URL;
  const { setChnageStatus, currentColor } = useStateContext();
  const [currentPage, setCurrentPage] = useState(0);
  const [filterData, setFilterData] = useState([]);

  const [isOpenUdateoinModel, setIsOpenUdateoinModel] = useState(null);

  const {
    state: ContextState,
    setPendingFun,
    fetchCustomers,
  } = useContext(ApisContext);
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;

  const { fetchCustomersPending } = ContextState;

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  // console.log(Math.ceil(users?.length / parPageCount), 'math.ceil(users?.length / pageCount', users?.length)
  const handlePageClick = (data) => {
    const selectedPage = data.selected;
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    const startIndex = parseInt(currentPage) * parseInt(parPageCount);
    const endIndex = parseInt(startIndex) + parseInt(parPageCount);
    setFilterData(users?.slice(startIndex, endIndex));
  }, [currentPage, users, parPageCount]);

  const customerChangeStatus = async (id, user) => {
    const tokenStr = user.token;
    // console.log(data, user, "token", tokenStr)
    try {
      const response = await axios.post(
        BASE_URL + `user_active_inactive/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Status successfully changed");
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Api not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      if (error.response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.data);
      }
    }
  };

  useEffect(() => {
    if (isOpenUdateoinModel) {
      setValue('coins', isOpenUdateoinModel?.user_coin_sum_coin || 0)
    }
  }, [isOpenUdateoinModel])
  

  const submitHandler = async (data) => {
    const tokenStr = user.token;
    const formData = new FormData();
    formData.append("coin", data?.coins);
    formData.append("user_id", isOpenUdateoinModel?.id);
    // console.log(data, user, "token", tokenStr)
    setPendingFun(true);
    try {
      const response = await axios.post(
        BASE_URL + `coin`,
        formData,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );
      setPendingFun(false);
      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          toast.success("Coins successfully updated");
          setIsOpenUdateoinModel(null);
          fetchCustomers(user);
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Api not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
    setPendingFun(false);
      if (error.response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.data);
      }
    }
    console.log(data);
    // const res = await createBundle(user, data);
    // console.log("res" , res)
    // if (res?.Success == true) {
    //   setIsOpenUdateoinModel(false);
    //   fetchCustomers(user);
    // }
  };

  return (
    <>
      {isOpenUdateoinModel && (
        <div
          style={{ zIndex: 9999 }}
          className="fixed inset-0 bg-black backdrop-blur-md bg-opacity-50 h-full w-full flex p-3"
        >
          <div className="py-3 px-4 mx-auto my-auto h-fit max-h-full sm:max-w-screen-sm w-full overflow-y-auto bg-white dark:bg-gray-600 rounded-md ">
            <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-4">
              Update Coins
            </div>
            <form
              onSubmit={handleSubmit((data) => submitHandler(data))}
              noValidate
            >
              <div className="grid gap-4 mb-6 grid-cols-1">
                <div className="mb-3">
                  <label
                    htmlFor="coins"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Coins
                    <span className="text-red-600 text-xs">*</span>
                  </label>
                  <input
                    autoComplete="off"
                    type="number"
                    id="coins"
                    className={`border text-sm rounded-lg block w-full p-2.5 ${
                      errors?.coins
                        ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                        : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    } `}
                    placeholder="Enter coins"
                    {...register("coins", {
                      required: {
                        value: true,
                        message: "coins required",
                      },
                    })}
                  />
                  <small className="text-sm text-red-600 dark:text-red-500">
                    {errors.coins?.message}
                  </small>
                </div>
              </div>
              <div className="text-right mt-5 flex justify-end gap-3 items-center">
                <button
                  style={{
                    color: currentColor,
                    border: `1px solid ${currentColor}`,
                  }}
                  onClick={() => setIsOpenUdateoinModel(null)}
                  type="button"
                  className={`focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:opacity-80`}
                >
                  Cancel
                </button>
                <button
                  style={{
                    background: currentColor,
                  }}
                  type="submit"
                  className=" text-white  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:opacity-80"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      <div className="-my-2 py-2 sm:-mx-6 sm:px-3 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
          <table className="min-w-full px-4">
            <thead>
              <tr className="bg-gray-300 dark:bg-gray-600 dark:text-gray-50 border-b border-gray-200 dark:border-gray-700 text-sm leading-4 uppercase tracking-wider whitespace-nowrap">
                <th className="ps-6 px-3 py-4 text-left font-semibold">ID.</th>
                <th className="px-3 py-4 text-left font-semibold">
                  CUSTOMER NAME
                </th>
                <th className="px-3 py-4 text-left font-semibold">Email</th>
                <th className="px-3 py-4 text-left font-semibold">Phone no.</th>
                <th className="px-3 py-4 text-left font-semibold">
                  Registered on
                </th>
                <th className="px-3 py-4 text-center font-semibold">Coins</th>
                <th className="px-3 py-4 text-center font-semibold">Status</th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-500">
              {!fetchCustomersPending &&
                filterData?.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className="border-b border-gray-200 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                    >
                      <td className="ps-6 px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 ">{item?.id}</div>
                      </td>

                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 capitalize">
                          {item?.name}
                        </div>
                      </td>

                      <td className="px-3 py-4 text-xl ">
                        <div className="text-sm leading-5 capitalize">
                          {item?.email}
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm ">
                        <div className="text-sm leading-5 capitalize">
                          {item?.phone_number}
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm ">
                        <div className="text-sm leading-5 capitalize">
                          {moment(item?.created_at).format("DD MMM YYYY")}
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm ">
                        <div
                          className="text-sm leading-5 capitalize cursor-pointer"
                          onClick={() => setIsOpenUdateoinModel(item)}
                        >
                          {item?.user_coin_sum_coin || 0}
                        </div>
                      </td>
                      <td className="px-3 py-2 ">
                        <div className="flex justify-center">
                          <label className="relative inline-flex items-center cursor-pointer">
                            <input
                              autoComplete="off"
                              type="checkbox"
                              value=""
                              defaultChecked={
                                item?.is_active === "active" && true
                              }
                              onChange={(e) =>
                                customerChangeStatus(item.id, user)
                              }
                              className="sr-only peer"
                            />
                            <div className="w-9 h-[18px] bg-gray-200 rounded-full peer dark:bg-gray-700  dark:peer-focus:ring-green-800 peer-checked:after:translate-x-[16px] after:content-[''] after:absolute after:top-0.5 after:left-[3px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[14px] after:w-[14px] after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                          </label>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {fetchCustomersPending && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    colSpan={8}
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
              {!fetchCustomersPending && !filterData?.length && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td colSpan={8} className="p-4 py-2 ">
                    <div className="flex justify-center items-left md:items-center flex-col md:flex-row">
                      <img
                        className="max-h-56 w-56 sm:max-w-[450px] sm:max-h-[450px]"
                        src={NotFoundImg}
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
              )}
              {!fetchCustomersPending && filterData?.length ? (
                <tr>
                  <td colSpan={8}>
                    <div className="flex justify-between m-4 my-2 items-left md:items-center flex-col md:flex-row">
                      <div className="whitespace-nowrap">
                        <span className="text-sm text-gray-700 dark:text-gray-400">
                          Showing{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {filterData?.length > 0
                              ? currentPage * parPageCount + 1
                              : 0}
                          </span>{" "}
                          to{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {Math.min(
                              (currentPage + 1) * parPageCount,
                              users?.length
                            )
                              ? Math.min(
                                  (currentPage + 1) * parPageCount,
                                  users?.length
                                )
                              : ""}
                          </span>{" "}
                          of{" "}
                          <span className="font-semibold text-gray-900 dark:text-white">
                            {users?.length ? users?.length : ""}
                          </span>{" "}
                          Entries
                        </span>
                      </div>
                      <ReactPaginate
                        previousLabel={<GrFormPrevious />}
                        nextLabel={<GrFormNext />}
                        breakLabel={"..."}
                        initialPage={currentPage}
                        pageCount={Math.ceil(users?.length / parPageCount)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={
                          "items-center inline-flex space-x-2"
                        }
                        pageClassName={
                          "page-link cursor-pointer font-semibold rounded-full h-10 w-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        pageLinkClassName="h-full w-full rounded-full flex justify-center items-center text-center"
                        previousLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        disabledLinkClassName={"text-gray-400 opacity-50"}
                        nextLinkClassName={
                          "page-link rounded-full p-2 h-8 w-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white flex justify-center items-center text-center"
                        }
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"p-0 pt-0 pr-0 pb-0 pl-0"}
                        activeLinkClassName={
                          "active font-semibold rounded-full h-full w-full leading-tight text-gray-50 bg-gray-800 border border-gray-300 hover:bg-gray-700 hover:text-gray-100 dark:bg-gray-700 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-300 dark:hover:text-black flex justify-center items-center text-center"
                        }
                      />
                    </div>
                  </td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const Customers = () => {
  const [parPageCount, setParPageCount] = useState(10);
  const [searchQuerry, setSearchQuerry] = useState("");
  const [filtered, setFiltered] = useState([]);

  const { state: ContextState, fetchCustomers } = useContext(ApisContext);
  const { customers, fetchCustomersPending } = ContextState;
  const { state: AuthContextState } = useContext(AuthContext);
  const { user } = AuthContextState;

  useEffect(() => {
    fetchCustomers(user);
  }, []);

  useEffect(() => {
    filterCustomers();
  }, [searchQuerry, customers, parPageCount]);

  const filterCustomers = () => {
    if (searchQuerry !== "") {
      // Filter orders based on selected status and search query
      const filteredOrders = customers?.filter(
        (customer) =>
          customer.name.toLowerCase().includes(searchQuerry.toLowerCase()) ||
          customer.email.toLowerCase().includes(searchQuerry.toLowerCase()) ||
          customer.phone_number
            .toString()
            .toLowerCase()
            .includes(searchQuerry.toLowerCase()) ||
          customer.id
            .toString()
            .toLowerCase()
            .includes(searchQuerry.toLowerCase())
      );
      if (filteredOrders) {
        setFiltered([...filteredOrders]);
      } else {
        setFiltered([]);
      }
    } else {
      setFiltered(customers);
    }
  };

  return (
    <div className="px-5  sm:px-10 sm:mt-8 mt-24">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-3">
        Customers
      </div>
      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-5 border ">
        <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              autoComplete="off"
              type="text"
              onChange={(e) => setSearchQuerry(e.target.value)}
              id="simple-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
            />
          </div>
          <select
            id="limit"
            onChange={(e) => {
              setParPageCount(e.target.value);
            }}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          >
            <option defaultValue disabled>
              Customers limits
            </option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
      <Paging users={filtered} parPageCount={parPageCount} />
    </div>
  );
};
export default Customers;
