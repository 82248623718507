import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { AuthContext } from "../contexts/Auth.Context";
import { ApisContext } from "../contexts/ApiConterxt";
import { FaEdit } from "react-icons/fa";

import NotFoundImg from "../assets/No data-pana.png";
import axios from "axios";
import { toast } from "react-toastify";

const MinimumAmount = ({ amount, cod_fee }) => {
  const { currentColor } = useStateContext();
  const { state: ContextState, updatetMinOrderAmount } = useContext(ApisContext);
  const { state: AuthContextState, setUnAuthorizedFun } =
  useContext(AuthContext);

const { user } = AuthContextState;
  const[subLoading, setSubLoading] = useState(false);
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    setValue("minimum_order_amount", amount);
    setValue("cod_fee", cod_fee);
  }, [amount, cod_fee])

  const subHandler = async (data) => {
    setSubLoading(true);
    await updatetMinOrderAmount(data, user);
    setSubLoading(false);
  }
// cod_fee
  return (
    <>
     <div className="text-base sm:text-lg text-gray-700 dark:text-gray-50 font-semibold mt-10 my-3">
        Minimum Order Amount For Special Offer
      </div>
      <form onSubmit={handleSubmit((data) => subHandler(data))}>
          <div className="w-full">
            <input autoComplete="off"
              type="number"
              min={0}
              id="minimum_order_amount"
              {...register("minimum_order_amount", {
                required: { value: true, message: "Enter minimum order amount" },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.minimum_order_amount
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Amount"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.minimum_order_amount?.message}
            </small>
        </div>
        <div className="text-base sm:text-base text-gray-700 dark:text-gray-50 font-semibold mt-3 mb-1">
        COD Amount
      </div>
      <div className="flex flex-col md:flex-row items-center gap-4">
          <div className="w-full">
            <input autoComplete="off"
              type="number"
              min={0}
              id="cod_fee"
              {...register("cod_fee", {
                required: { value: true, message: "Enter COD amount" },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.cod_fee
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Amount"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.cod_fee?.message}
            </small>
          </div>
        <div className="text-right md:w-auto w-full">
          {!subLoading ? (
            <button
              type="submit"
              style={{
                background: currentColor,
              }}
              className=" text-white  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:opacity-80"
            >
              {"Update"}
            </button>
          ) : (
            <button
              disabled
              style={{
                background: currentColor,
              }}
              type="button"
              className="md:w-auto text-white  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:opacity-80 inline-flex items-center"
            >
              <svg
                aria-hidden="true"
                role="status"
                className="inline w-4 h-4 mr-3 text-gray-500 animate-spin dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="#fff"
                />
              </svg>
              Loading...
            </button>
          )}
        </div>
       
      </div>
      </form>
    </>
  );
}

const DeliveryCharges = () => {
  const { currentColor } = useStateContext();
  const BASE_URL = window.config.API_URL;
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);

  const { user } = AuthContextState;

  const { state: ContextState, fetchDeliveryCharges } = useContext(ApisContext);

  const { deliveryPricing, fetchDeliveryChargesPending } = ContextState;

  const [searchQuerry, setSearchQuerry] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [subLoading, setSubLoading] = useState(false);

  const [updateDeliveryPricing, setUpdateDeliveryPricing] = useState(null);

  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    fetchDeliveryCharges(user);
  }, []);
  useEffect(() => {
    // console.log(orders, "orders");
    filterdeliveryPricing();
  }, [deliveryPricing]);

  useEffect(() => {
    filterdeliveryPricing();
  }, [searchQuerry]);

  const filterdeliveryPricing = () => {
    // Filter orders based on search query
    const filteredOrders = deliveryPricing?.deliveryPricing?.filter(
      (item) =>
        item.time
          .toString()
          .toLowerCase()
          .includes(searchQuerry.toLowerCase()) ||
        item.price
          .toString()
          .toLowerCase()
          .includes(searchQuerry.toLowerCase()) ||
        item.distance
          .toString()
          .toLowerCase()
          .includes(searchQuerry.toLowerCase()) ||
        item.id.toString().toLowerCase().includes(searchQuerry.toLowerCase())
    );
    if (filteredOrders) {
      setFiltered([...filteredOrders]);
    } else {
      setFiltered([]);
    }
  };

  useEffect(() => {
    if (updateDeliveryPricing) {
      setValue("price", updateDeliveryPricing?.price);
      setValue("time", updateDeliveryPricing?.time);
      setValue("distance", updateDeliveryPricing?.distance);
      setValue("weight", updateDeliveryPricing?.weight);
      setValue(
        "is_free_delivery",
        updateDeliveryPricing?.is_free_delivery ? "true" : "false"
      );
    }
  }, [updateDeliveryPricing]);

  const createDeliveryCharge = async (Data, user) => {
    setSubLoading(true);

    try {
      const tokenStr = user.token;

      const response = await axios.post(BASE_URL + "deliveryPricing", Data, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });

      setSubLoading(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          fetchDeliveryCharges(user);
          reset();
          toast.success("Successfully added!");
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: Not found");
      } else if (response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setSubLoading(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };
  const updateProduct = async (data, id, user) => {
    setSubLoading(true);
    // console.log(productId, "catData updated")
    try {
      const tokenStr = user.token;

      const response = await axios.post(
        BASE_URL + "deliveryPricing/" + id,
        data,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      setSubLoading(false);

      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.dismiss();
          fetchDeliveryCharges(user);
          setUpdateDeliveryPricing(null);
          reset();
          toast.success("Successfully Upadated!");
          return response.data;
        } else if (response.data.status === "error") {
          toast.dismiss();
          toast.error(response.data.message);
        } else {
          toast.dismiss();
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.dismiss();
        toast.error("Request Error: not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.dismiss();
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setSubLoading(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.dismiss();
        toast.error("Error- " + error.response.data.message);
      }
    }
  };
  const submit = (data) => {
    const newData = {};
    newData.price = data.price;
    newData.time = data.time;
    newData.distance = data.distance;
    newData.weight = data.weight;
    newData.is_free_delivery = data.is_free_delivery ? "true" : "false";
    if (updateDeliveryPricing) {
      console.log(newData, updateDeliveryPricing.id, "update delivery");
      updateProduct(newData, updateDeliveryPricing.id, user);
    } else {
      console.log(newData, "new add delivery");
      createDeliveryCharge(newData, user);
    }
  };
  return (
    <div className="mt-24 sm:mt-24 md:mt-0 px-5 sm:px-8 md:px-14 min-h-[calc(100vh_-_210px)]">
      {/* <div className="h-[50vh] max-h-screen  inset-0 fixed w-[100%] bg-white border border-red-700"></div> */}
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-3">
        Delivery Charges
      </div>
      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-2 border ">
        <div className="flex flex-wrap md:flex-nowrap justify-between items-center gap-2">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input autoComplete="off"
              type="text"
              onChange={(e) => setSearchQuerry(e.target.value)}
              id="simple-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
            />
          </div>
        </div>
      </div>
      <div className="-my-2 py-2 sm:-mx-6 sm:px-3 lg:-mx-8 lg:px-8">
        <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
          <table className="min-w-full px-4">
            <thead>
              <tr className="bg-gray-300 dark:bg-gray-600 dark:text-gray-50 border-b border-gray-200 dark:border-gray-700 text-sm leading-4 uppercase tracking-wider whitespace-nowrap">
                <th className="ps-6 px-3 py-4 text-left font-semibold">ID</th>
                <th className="px-3 py-4 text-left font-semibold">Price</th>
                <th className="px-3 py-4 text-left font-semibold">Time</th>
                <th className="px-3 py-4 text-center font-semibold">
                  Distance
                </th>
                <th className="px-3 py-4 text-center font-semibold">Weight</th>
                <th className="px-3 py-4 text-center font-semibold">Is Free</th>
                <th className="px-3 py-4 text-center font-semibold">Action</th>
              </tr>
            </thead>
            <tbody className="bg-white dark:bg-gray-500">
              {!fetchDeliveryChargesPending &&
                filtered?.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      className="border-b border-gray-200 dark:border-gray-600 text-gray-900 dark:text-gray-100"
                    >
                      <td className="ps-6 px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 ">{item?.id}</div>
                      </td>

                      <td className="px-3 py-2 whitespace-nowrap">
                        <div className="text-sm leading-5 capitalize">
                          {item?.price}
                        </div>
                      </td>

                      <td className="px-3 py-4 text-xl ">
                        <div className="text-sm leading-5 capitalize">
                          {item?.time}
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm text-center">
                        <div className="text-sm leading-5 capitalize">
                          {item?.distance} km
                        </div>
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap text-sm text-center">
                        <div className="text-sm leading-5 capitalize">
                          {item?.weight} kg
                        </div>
                      </td>
                      <td className="px-3 py-2 text-center whitespace-nowrap text-sm ">
                        <div className="text-sm leading-5 capitalize">
                          {item?.is_free_delivery ? "Yes" : "No"}
                        </div>
                      </td>
                      <td className="px-3 py-2 text-center whitespace-nowrap text-sm ">
                        <div
                          onClick={() => setUpdateDeliveryPricing(item)}
                          className="text-xl flex justify-center"
                        >
                          <FaEdit className="cursor-pointer" />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              {fetchDeliveryChargesPending && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    colSpan={8}
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-6 bg-gray-200 rounded-lg dark:bg-gray-700 w-6 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </td>
                </tr>
              )}
              {!fetchDeliveryChargesPending && !filtered?.length && (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td colSpan={8} className="p-4 py-2 ">
                    <div className="flex justify-center items-left md:items-center flex-col md:flex-row">
                      <img
                        className="max-h-56 w-56 sm:max-w-[450px] sm:max-h-[450px]"
                        src={NotFoundImg}
                        alt=""
                      />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className="text-xl text-gray-700 dark:text-gray-50 font-semibold mt-10 my-3">
        Add New
      </div>
      <form onSubmit={handleSubmit((data) => submit(data))}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
          <div>
            <label
              htmlFor="price"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
            >
              Price
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="number"
              min={0}
              id="price"
              {...register("price", {
                required: { value: true, message: "Enter Delivery price" },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.price
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Price"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.price?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="time"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
            >
              Time
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="text"
              id="time"
              {...register("time", {
                required: { value: true, message: "Enter Delivery time" },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.time
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Time"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.time?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="distance"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
            >
              Distance
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="number"
              min={0}
              id="distance"
              {...register("distance", {
                required: { value: true, message: "Enter Delivery distance" },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.distance
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Distance"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.distance?.message}
            </small>
          </div>
          <div>
            <label
              htmlFor="weight"
              className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
            >
              Weight
              <span className="text-red-600 text-xs">*</span>
            </label>
            <input autoComplete="off"
              type="number"
              min={0}
              id="weight"
              {...register("weight", {
                required: { value: true, message: "Enter Delivery weight" },
              })}
              className={`border text-sm rounded-lg block w-full p-2.5 ${
                errors.weight
                  ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                  : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
              } `}
              placeholder="Enter Weight"
            />
            <small className="text-sm text-red-600 dark:text-red-500">
              {errors.weight?.message}
            </small>
          </div>
          <div className="flex items-center mr-4">
            <input autoComplete="off"
              id="red-checkbox"
              value="true"
              defaultChecked={
                updateDeliveryPricing && updateDeliveryPricing?.is_free_delivery
              }
              type="checkbox"
              {...register("is_free_delivery")}
              className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 rounded focus:outline-none dark:focus:outline-none dark:bg-gray-700 dark:border-gray-600"
            />
            <label
              htmlFor="red-checkbox"
              className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
            >
              Is Delivery Free
            </label>
          </div>
        </div>
        <div className="flex justify-end"></div>
        <div className="text-right mt-2">
          {!subLoading ? (
            <button
              type="submit"
              style={{
                background: currentColor,
              }}
              className=" text-white  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:opacity-80"
            >
              {!updateDeliveryPricing ? "Add" : "Update"}
            </button>
          ) : (
            <button
              disabled
              style={{
                background: currentColor,
              }}
              type="button"
              className=" text-white  focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:opacity-80 inline-flex items-center"
            >
              <svg
                aria-hidden="true"
                role="status"
                className="inline w-4 h-4 mr-3 text-gray-500 animate-spin dark:text-gray-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="#fff"
                />
              </svg>
              Loading...
            </button>
          )}
        </div>
      </form>
      <MinimumAmount cod_fee={deliveryPricing?.cod_fee} amount={deliveryPricing?.minimum_order_amount} />
    </div>
  );
};

export default DeliveryCharges;
