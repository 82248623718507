/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import { AiOutlineMenu } from "react-icons/ai";
// import { FiShoppingCart } from "react-icons/fi";
// import { BsChatLeft } from "react-icons/bs";
import { RiNotification3Line } from "react-icons/ri";
// import { MdKeyboardArrowDown } from "react-icons/md";
import Button from "./Button";
import avatar from "../data/avatar.jpg";
// import { Cart, Chat, Notification, UserProfile } from ".";
import { UserProfile } from ".";
import { useStateContext } from "../contexts/ContextProvider";
import { FiSettings } from "react-icons/fi";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { FaBell } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const NavButton = ({ title, customFunc, icon, color, dotColor, count }) => (
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
  >
     {count ? <div className="absolute p-2 rounded-full flex items-center justify-center -top-2 -right-0">
      <p className="text-base font-semibold text-gray-800 leading-none">
        {count}
      </p>
    </div> : null}
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
);

const NavButtonWithCount = ({ title, customFunc, icon, color, dotColor, count }) => (
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
  >
     <div className="absolute aspect-square p-1 min-w-[23px] text-center rounded-full bg-gray-600 dark:bg-gray-200 flex items-center justify-center -top-2 -right-0">
      <p className="text-sm font-semibold text-white dark:text-gray-800 leading-none text-center">
        {count || "0"}
      </p>
    </div>
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      />
      {icon}
    </button>
);



const Navbar = () => {
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    handleClick,
    isClicked,
    setScreenSize,
    setThemeSettings,
    screenSize,
  } = useStateContext();

  const { state: ContextState, logout, login } = useContext(AuthContext);

  const { isLoginPending, isLoggedIn, loginError, user } = ContextState;
  const { state: ApiContextState, fetchDashboard } = useContext(ApisContext);
  const { dashboard } = ApiContextState;

  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (screenSize <= 900) {
      setActiveMenu(false);
    } else {
      setActiveMenu(true);
    }
  }, [screenSize]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);


  const signOut = () => {
    // console.log("signout called");
    logout(user);
  }

  return (
    <div className="flex justify-between p-4 md:mr-6 relative">
      <NavButton
        title="Menu"
        customFunc={handleActiveMenu}
        color={currentColor}
        icon={<AiOutlineMenu />}
      />
      <div className="flex">
        <NavButtonWithCount
          title="theme settings"
          color={currentColor}
          count={dashboard?.pending_support_requests || 0}
          customFunc={() => navigate('/customersSupport')}
          icon={<FaBell />}
        />
        <NavButton
          title="theme settings"
          color={currentColor}
          customFunc={() => setThemeSettings(true)}
          icon={<FiSettings />}
        />
        <Button
          onClick={()=>signOut()}
            color="white"
            bgColor={currentColor}
          text="Logout"
          classes={"px-4 py-1.5"}
            borderRadius="10px"
            width="full"
          />
      </div>
    </div>
  );
};

export default Navbar;
