import React, { useContext, useEffect, useState } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { MdOutlineDelete, MdAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import axios from "axios";
import { toast } from "react-toastify";

import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";


import NotFoundImg from "../assets/No data-pana.png";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
// import { BsZoomIn } from "react-icons/bs";
// import { TfiImport, TfiExport } from "react-icons/tfi";

const FlasSale = () => {
  const BASE_URL = window.config.API_URL;
  const navigate = useNavigate();
  const [flashSales, setFlashSales] = useState(null);
  const [flashSalesPending, setFlashSalesPending] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deletesaleId, setDeleteSaleId] = useState(null);
  const { currentColor } = useStateContext();
  const { setPendingFun, state: ContextState, setSelectedProductsIdsSaleFun } = useContext(ApisContext);
  const { state: AuthContextState, setUnAuthorizedFun} =
    useContext(AuthContext);
    const {
      selectedProductsIdsSale
  } = ContextState;
  
  const { user } = AuthContextState;


  const getFlashSales = async (user) => {
    //   console.log(user, "getCategories");
    setFlashSalesPending(true);

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + "flashSale", {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setFlashSalesPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              // console.log("sales", response.data.data);
              setFlashSales(response.data.data);
              //   localStorage.removeItem("user");
              // toast.success("Data successfully Received");
              // navigate("/");
            } else {
              // toast.error("Error data not found");
            }
          } else {
            // toast.error("data not found" + response);
          }
        });
    } catch (error) {
      setFlashSalesPending(false);
      if (error.response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
        setFlashSales(null);
      }
    }
  };
  const changeStatus = async (user, saleId) => {
    setFlashSalesPending(true);

    let tokenStr = user.token;
    try {
      const response = await axios.patch(
        BASE_URL + `flashSaleStatus/${saleId}`,
        null,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      setFlashSalesPending(false);

      if (response.status === 200) {
        if (response.data.Success === true) {
          // console.log("sales", response.data.data);
          toast.success(response.data.message);
          setFlashSales(response.data.data);
          getFlashSales(user);
          // setFlashSales(response.data.data);
          // localStorage.removeItem("user");
          // toast.success("Data successfully Received");
          // navigate("/");
        } else {
          toast.error("Error: Data not found");
        }
      } else {
        // toast.error("Error: Data not found");
      }
    } catch (error) {
      setFlashSalesPending(false);

      if (error.response && error.response.status === 401) {
        toast.error("Error: " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error: ", error.response);
        // toast.error("Error: " + error.response.data.message);
      }
    }
  };

  const handelDeleteFlashSale = (productId) => {
    setDeleteSaleId(productId);
    setVisible(true);
  };
  const accept = () => {
    deleteSale(deletesaleId, user);
  };
  const reject = () => {
    setDeleteSaleId(null);
  };
  const deleteSale = async (productId, user) => {
    setPendingFun(true);
    try {
      const tokenStr = user.token;

      const response = await axios.delete(BASE_URL + `flashSale/${productId}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setPendingFun(false);
      if (response.status === 200) {
        if (response.data.Success === true) {
          toast.success("Sale successfully deleted!");
          setSelectedProductsIdsSaleFun(null);
          getFlashSales(user);
          return response.data;
        } else if (response.data.status === "error") {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.error("Request Error: Sale not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPendingFun(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  useEffect(() => {
    getFlashSales(user);
    // console.log(flashSales, "flashSales");
  }, []);

  return (
    <div className="px-5  sm:px-10 sm:mt-8 mt-24">
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to proceed?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-3">
        Flash Sale
      </div>
          {!flashSales && (
      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-3 border flex flex-col sm:flex-row justify-between items-center gap-2">
        <div className="flex items-center gap-4 w-full sm:w-fit">
        
        </div>
        <div className="flex flex-wrap lg:flex-nowrap items-center gap-3 w-full sm:w-fit">
        
            <Link className="w-full" to={"addSale"}>
            <button
              type="button"
              style={{
                background: currentColor,
              }}
              className="w-full justify-center sm:w-fit focus:outline-none text-white font-medium rounded-lg text-base px-3 py-2.5 flex items-center gap-1 hover:opacity-80"
            >
              <div className="text-xl">
                <MdAdd />
              </div>
              Add Sale
            </button>
          </Link>
        </div>
      </div>
          )}
     
      <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-sm text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
        
              <th scope="col" className="px-3 ps-6 py-3 whitespace-nowrap">
                CAMPAIGN NAME
              </th>
              <th scope="col" className="px-3 py-3">
                Products
              </th>
              <th
                scope="col"
                className="px-3 py-3 whitespace-nowrap text-center"
              >
                PUBLISHED
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                START DATE
              </th>
              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                END DATE
              </th>
              <th scope="col" className="px-3 py-3">
                STATUS
              </th>

              <th scope="col" className="px-3 py-3 pe-5 text-center">
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody>
            {flashSalesPending && <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={11}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                     
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>
                      <div className="h-4 bg-gray-200 rounded-lg dark:bg-gray-700 w-1/5 "></div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>}
            {!flashSalesPending && flashSales && (
              <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                <th
                  scope="row"
                  className="px-3 ps-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {flashSales?.campaign_name}
                </th>
                <td className="px-3 py-4 text-center">
                  {flashSales?.flash_sale_products?.length}
                </td>
                <td className="px-3 py-4 text-center">
                  <label className="relative inline-flex items-center cursor-pointer">
                    <input autoComplete="off"
                      type="checkbox"
                      onChange={() => changeStatus(user, flashSales?.id)}
                      checked={flashSales?.is_active === "1"}
                      className="sr-only peer"
                    />
                    <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                    {/* <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">Green</span> */}
                  </label>
                </td>
                <td className="px-3 py-4 whitespace-nowrap">
                  {flashSales?.start_date}
                </td>
                <td className="px-3 py-4 whitespace-nowrap">
                  {flashSales?.end_date}
                </td>
                <td className="px-3 py-4 whitespace-nowrap">
                  {new Date() >= new Date(flashSales?.end_date) ? (
                    <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                      Expired
                    </span>
                  ) : (
                    <span className="bg-green-100 text-green-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-red-300">
                      Runnig
                    </span>
                  )}
                </td>
                <td className="px-3 py-4">
                  <div className="text-2xl flex justify-center items-center gap-2">
                    <Link
                      to={`editSale/${flashSales?.id}`}
                      className="font-medium text-green-600 dark:text-green-800"
                    >
                      <HiOutlinePencilAlt />
                    </Link>
                    <button
                      onClick={() => handelDeleteFlashSale(flashSales?.id)}
                      className="font-medium text-red-600 dark:text-red-500"
                    >
                      <MdOutlineDelete />
                    </button>
                  </div>
                </td>
              </tr>
            )}
            { !flashSalesPending && (flashSales == null || flashSales == undefined) && <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td
                    colSpan={11}
                   
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                   <div className="flex justify-center h-[calc(100vh_-_250px)] items-left md:items-center flex-col md:flex-row">
                    <img className="max-h-56" src={NotFoundImg} alt="" />
                  </div>
                  </td>
                </tr> }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FlasSale;
