import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useStateContext } from "../contexts/ContextProvider";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ChangeStatusBookRequests = () => {
  const BASE_URL = window.config.API_URL;
  const navigate = useNavigate();
  const {
    changeStatusBookRequests,
    setChangeStatusBookRequests,
    currentColor,
  } = useStateContext();
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;
  const style = {
    backdropFilter: "blur(1px)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  };
  const { setPendingFun, fetchBookRequests } = useContext(ApisContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  useEffect(() => {
    setValue("status", changeStatusBookRequests?.status);
    setValue("message", changeStatusBookRequests?.message);
    setValue("book_id", changeStatusBookRequests?.book_id);
  }, [changeStatusBookRequests]);

  const onSubmit = async (data) => {
    console.log(data);
    const newData = new FormData();
    newData.append("status", data?.status);
    if (data?.book_id) { 
      newData.append("book_id", data?.book_id);
    }
    newData.append("message", data?.message);
    setPendingFun(true);

    try {
      const tokenStr = user.token;

      const response = await axios.post(
        BASE_URL + `request_book/${changeStatusBookRequests?.id}`,
        newData,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      // setPending(false);
      setPendingFun(false);
      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.success("Request successfully Updated!");
          setChangeStatusBookRequests(null);
          navigate("/requestbook");
          fetchBookRequests(user, null, changeStatusBookRequests?.status);
          return response.data;
        } else if (response.data.status === "error") {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.error("Request Error: Request not Updated");
      } else if (response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPendingFun(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.error("Error- " + error.response.data.message);
      }
    }
  };
  return (
    <div
      id="setTimeModal"
      style={style}
      className="fixed flex justify-center items-center top-1 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[100vh] max-h-full"
    >
      <div className="relative w-full max-w-md max-h-full">
        <div className="bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            onClick={() => setChangeStatusBookRequests(null)}
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
              Chnage Status
            </h3>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              <div className="flex flex-col gap-2">
                <div className="px-4">
                  <label
                    htmlFor="status"
                    className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Status
                    <span className="text-red-600 text-xs">*</span>
                  </label>
                  <select
                    name="status"
                    id="status"
                    {...register("status", {
                      required: "Please select a status",
                    })}
                    className="bg-gray-50 border w-full border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-1.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option value="rejected">Rejected</option>
                    <option value="approved">Approved</option>
                    <option value="pending">Pending</option>
                  </select>
                  {errors?.status && (
                    <small className="text-sm text-red-600 dark:text-red-500">
                      {errors?.status?.message}
                    </small>
                  )}
                </div>
                <div className="px-4">
                  <label
                    htmlFor="book_id"
                    className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Book Id
                    <span className="text-red-600 text-xs">*</span>
                  </label>
                  <input
                    autoComplete="off"
                    type="number"
                    id="book_id"
                    {...register("book_id", {
                      required: {
                        value: false,
                        message: "Enter Book ID",
                      },
                    })}
                    className={`border text-sm rounded-lg block w-full p-1.5 ${
                      errors.book_id
                        ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                        : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    } `}
                    placeholder="Book Id"
                  />
                  {errors?.book_id && (
                    <small className="text-sm text-red-600 dark:text-red-500">
                      {errors?.book_id?.message}
                    </small>
                  )}
                </div>
                <div className="px-4">
                  <label
                    htmlFor="message"
                    className="block mb-1 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Message
                    <span className="text-red-600 text-xs">*</span>
                  </label>
                  <input
                    autoComplete="off"
                    type="text"
                    id="message"
                    {...register("message", {
                      required: {
                        value: true,
                        message: "Enter Message",
                      },
                    })}
                    className={`border text-sm rounded-lg block w-full p-1.5 ${
                      errors.message
                        ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                        : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    } `}
                    placeholder="Message"
                  />
                  {errors?.message && (
                    <small className="text-sm text-red-600 dark:text-red-500">
                      {errors?.message?.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="px-4">
                <button
                  type="submit"
                  style={{
                    background: currentColor,
                  }}
                  className="w-full text-white focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeStatusBookRequests;
