import React from "react";
import { useStateContext } from "../../../contexts/ContextProvider";


const SecondLineCard = (props) => {
    // const { currentColor, currentMode } = useStateContext();
    const { currentColor} = useStateContext();
  return (
    <div
      style={{
        borderLeft: `4px solid ${currentColor}`,
      }}
      className="w-full h-full rounded-lg flex items-center dark:shadow-lg-[-24px_12px_32px_-4px rgba(145, 158, 171, 0.16)] shadow-md py-3 p-3 bg-white dark:bg-gray-700 sm:max-w-[270px]"
    >
      <div className="flex gap-3 items-center">
        <div
          className="h-fit rounded-full text-white flex justify-center items-center p-2 text-3xl"
          style={{
            background: currentColor,
          }}
        >
          {<props.icon />}
        </div>
        <div>
          <div className="text-base leading-none dark:text-slate-300">
            {props.title}
          </div>
          <div className="font-bold text-2xl dark:text-slate-300">{props.count}</div>
        </div>
      </div>
    </div>
  );
};

export default SecondLineCard;
