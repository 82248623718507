import React from 'react'

const TopLineCard = (props) => {
  return (
    <div className="w-full rounded-lg flex items-center justify-center shadow-lg pt-6 pb-4 px-3 bg-white dark:bg-gray-700 sm:max-w-[270px]">
    <div className="flex flex-col gap-1 items-center">
      <div className={`h-fit rounded-full ${props.iconColor} drop-shadow flex justify-center items-center text-4xl`}>
      {<props.icon />}
      </div>
      <div className="text-center flex flex-col gap-4">
        <div className="text-base font-semibold leading-none pt-1 dark:text-slate-300">
          {props.title}
        </div>
        <div>
          <div className="font-bold text-2xl dark:text-slate-300">{props.count}</div>
          <div className="mb-2.5 font-medium opacity-60 leading-none text-sm dark:text-slate-300">
            Total
          </div>
          {/*<div className="pt-4">
            <img src={props.img} alt="" />
  </div>*/}
        </div>
      </div>
    </div>
  </div>
  )
}

export default TopLineCard


