import React, { useState, useEffect, useContext } from "react";
import { MdAdd, MdMoreHoriz, MdOutlineDeleteForever } from "react-icons/md";
import { Link } from "react-router-dom";
import cupponPng from "../assets/discount-voucher.png";
import { useStateContext } from "../contexts/ContextProvider";
import axios from "axios";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";

import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import NoDataImg from "../assets/No data-pana.png";

const CupponLoadingCard = () => {
  return (
    <div className="flex space-x-4 animate-pulse">
      <div className="flex-1 py-1 space-y-1.5">
        <div className="w-full h-32 flex justify-between items-center p-10 bg-gray-400 rounded-lg">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
          </div>
          <div>
            <svg
              className="w-12 h-12 text-gray-200 dark:text-gray-600"
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 640 512"
            >
              <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
            </svg>
          </div>
        </div>
        <div className="flex justify-between px-3">
          <div className="w-full space-y-1">
            <div className="h-3 w-4/6 bg-gray-400 rounded"></div>
            <div className="w-5/6 h-2 bg-gray-400 rounded"></div>
          </div>
          <div className="w-5 h-6 bg-gray-400 rounded"></div>
        </div>
      </div>
    </div>
  );
};
const CupponGreenCard = ({ cuppon, handelDeleteCuppon }) => {
  const [cardDropDown, setCardDropDown] = useState(false);
  // console.log(cuppon, "CupponGreenCard", cuppon?.updated_at);
  // console.log(cuppon, "cupponGreen");
  return (
    <div>
      <div className="rounded-md pt-3 p-5 px-6 bg-gradient-to-r from-green-600 via-green-500 to-green-400 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 ">
        <div className="text-white relative text-sm flex justify-between">
          <div className="pt-1">Coins:&nbsp;{cuppon?.required_coin}
          <div className="text-sm capitalize text-center text-white font-medium leading-tight">
            Type:{cuppon?.type === "first_order"? "First Order" : cuppon?.type }
          </div>
          </div>
          {cardDropDown && (
            <div className="right-6 top-2 absolute z-10 bg-white divide-y divide-gray-100 rounded-md shadow w-24 dark:bg-gray-700">
              <ul
                className="py-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownHoverButton"
              >
                <li>
                  <Link
                    to={`editCoupon/${cuppon?.id}`}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Edit
                  </Link>
                </li>
              </ul>
            </div>
          )}
          <div
            onClick={() => setCardDropDown(!cardDropDown)}
            className="text-2xl cursor-pointer"
          >
            <MdMoreHoriz />
          </div>
        </div>
        <div className="flex gap-1 justify-between items-center">
          <div className="mt-5">
            <div className="text-white text-3xl font-bold">
              {cuppon?.discount}% OFF
            </div>
            <div className="text-sm text-white font-medium leading-tight">
              {cuppon?.coupon_code}
            </div>
          </div>
          <div className="opacity-75">
            <img width={70} src={cupponPng} alt="" />
          </div>
        </div>
      </div>
      <div className="mx-3 bg-white dark:bg-gray-700 border dark:border-gray-400 border-t-0 rounded-b-md  py-1 p-3">
        <div className="text-md font-semibold text-gray-600 flex justify-between items-center dark:text-gray-300 leading-none">
          <div>
            {cuppon?.name}
            <div className="text-xs pt-0.5 font-normal text-gray-400 dark:text-gray-400 flex justify-between items-center">
              <div>
                {moment(cuppon?.updated_at).format("MMM D, YYYY hh:mma")}
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              handelDeleteCuppon(cuppon?.id);
            }}
            className="text-red-500 text-xl cursor-pointer"
          >
            <MdOutlineDeleteForever />
          </div>
        </div>
      </div>
    </div>
  );
};
const CupponOrangeCard = ({ cuppon, handelDeleteCuppon }) => {
  const [cardDropDown, setCardDropDown] = useState(false);
  return (
    <div>
      <div className="rounded-md pt-3 p-5 px-6 bg-gradient-to-r from-orange-600 via-orange-400 to-orange-300 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-orange-800 shadow-lg shadow-orange-500/50 dark:shadow-lg dark:shadow-orange-800/80 ">
        <div className="text-white relative text-sm flex justify-between">
          <div className="pt-1">Coins:&nbsp;{cuppon?.required_coin}
          <div className="text-sm capitalize text-center text-white font-medium leading-tight">
            Type:{cuppon?.type === "first_order"? "First Order" : cuppon?.type }
          </div>
          </div>
         
          {cardDropDown && (
            <div className="right-6 top-2 absolute z-10 bg-white divide-y divide-gray-100 rounded-md shadow w-24 dark:bg-gray-700">
              <ul
                className="py-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownHoverButton"
              >
                <li>
                  <Link
                    to={`editCoupon/${cuppon?.id}`}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Edit
                  </Link>
                </li>
              </ul>
            </div>
          )}
          <div
            onClick={() => setCardDropDown(!cardDropDown)}
            className="text-2xl cursor-pointer"
          >
            <MdMoreHoriz />
          </div>
        </div>
        <div className="flex gap-1 justify-between items-center">
          <div className="mt-5">
            <div className="text-white text-3xl font-bold">
              {cuppon?.discount}% OFF
            </div>
            <div className="text-sm text-white font-medium leading-tight">
              {cuppon?.coupon_code}
            </div>
          </div>
          <div className="opacity-75">
            <img width={70} src={cupponPng} alt="" />
          </div>
        </div>
      </div>
      <div className="mx-3 bg-white dark:bg-gray-700 border dark:border-gray-400 border-t-0 rounded-b-md  py-1 p-3">
        <div className="text-md font-semibold text-gray-600 flex justify-between items-center dark:text-gray-300 leading-none">
          <div>
            {cuppon?.name}
            <div className="text-xs pt-0.5 font-normal text-gray-400 dark:text-gray-400 flex justify-between items-center">
              <div>
                {moment(cuppon?.updated_at).format("MMM D, YYYY hh:mma")}
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              handelDeleteCuppon(cuppon?.id);
            }}
            className="text-red-500 text-xl cursor-pointer"
          >
            <MdOutlineDeleteForever />
          </div>
        </div>
      </div>
    </div>
  );
};
const CupponBlueCard = ({ cuppon, handelDeleteCuppon }) => {
  const [cardDropDown, setCardDropDown] = useState(false);
  return (
    <div>
      <div className="rounded-md pt-3 p-5 px-6 bg-gradient-to-r from-cyan-600 via-cyan-500 to-cyan-400 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80">
        <div className="text-white relative text-sm flex justify-between">
          <div className="pt-1">Coins:&nbsp;{cuppon?.required_coin}
          <div className="text-sm capitalize text-center text-white font-medium leading-tight">
            Type:{cuppon?.type === "first_order"? "First Order" : cuppon?.type }
          </div>
          </div>
          {cardDropDown && (
            <div className="right-6 top-2 absolute z-10 bg-white divide-y divide-gray-100 rounded-md shadow w-24 dark:bg-gray-700">
              <ul
                className="py-1 text-sm text-gray-700 dark:text-gray-200"
                aria-labelledby="dropdownHoverButton"
              >
                <li>
                  <Link
                    to={`editCoupon/${cuppon?.id}`}
                    className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  >
                    Edit
                  </Link>
                </li>
              </ul>
            </div>
          )}
          <div
            onClick={() => setCardDropDown(!cardDropDown)}
            className="text-2xl cursor-pointer"
          >
            <MdMoreHoriz />
          </div>
        </div>
        <div className="flex gap-1 justify-between items-center">
          <div className="mt-4">
            <div className="text-white text-3xl font-bold">
              {cuppon?.discount}% OFF
            </div>
            <div className="text-sm text-white font-medium leading-tight">
              {cuppon?.coupon_code}
            </div>
          </div>
          <div className="opacity-75">
            <img width={70} src={cupponPng} alt="" />
          </div>
        </div>
      </div>
      <div className="mx-3 bg-white dark:bg-gray-700 border dark:border-gray-400 border-t-0 rounded-b-md  py-1 p-3">
        <div className="text-md font-semibold text-gray-600 flex justify-between items-center dark:text-gray-300 leading-none">
          <div>
            {cuppon?.name}
            <div className="text-xs pt-0.5 font-normal text-gray-400 dark:text-gray-400 flex justify-between items-center">
              <div>
                {moment(cuppon?.updated_at).format("MMM D, YYYY hh:mma")}
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              handelDeleteCuppon(cuppon?.id);
            }}
            className="text-red-500 text-xl cursor-pointer"
          >
            <MdOutlineDeleteForever />
          </div>
        </div>
      </div>
    </div>
  );
};

const Cuppons = () => {
  const { currentColor } = useStateContext();

  const [deleteProductId, setDeleteProductId] = useState(null);
  const [visible, setVisible] = useState(false);
  const [cuppons, setCuppons] = useState([]);
  const [cupponsPending, setCupponsPending] = useState(null);

  var count = 0;
  const colors = [
    "green",
    "orange",
    "blue",
    "orange",
    "blue",
    "green",
    "blue",
    "green",
    "orange",
  ];
  const BASE_URL = window.config.API_URL;
  const navigate = useNavigate();

  const { state: ContextState, setPendingFun } = useContext(ApisContext);
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;

  useEffect(() => {
    getCuppons(user);
  }, []);
  // console.log(cuppons, "cuppons");
  const getCuppons = async (user) => {
    //   console.log(user, "getCategories");
    setCupponsPending(true);

    let tokenStr = user.token;
    try {
      await axios
        .get(BASE_URL + "coupons", {
          headers: { Authorization: `Bearer ${tokenStr}` },
        })
        .then((response) => {
          //   console.log(response, "Category");
          setCupponsPending(false);
          if (response.status === 200) {
            if (response.data.Success === true) {
              setCuppons(response?.data?.data);

              //   localStorage.removeItem("user");
              // toast.success("Data successfully Received");
              // navigate("/");
            } else {
              // toast.error("Error data not found");
            }
          } else {
            // toast.error("Coupons not found" + response);
          }
        });
    } catch (error) {
      setCupponsPending(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        // toast.error("Error- " + error.response.data.message);
      }
    }
  };
  const deleteCuppon = async (id, user) => {
    setPendingFun(true);
    try {
      const tokenStr = user.token;

      const response = await axios.delete(BASE_URL + `coupons/${id}`, {
        headers: { Authorization: `Bearer ${tokenStr}` },
      });
      setPendingFun(false);
      if (response.status === 200) {
        if (response.data.Success === true) {
          toast.success("Cuppon successfully deleted!");
          getCuppons(user);
          return response.data;
        } else if (response.data.status === "error") {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.error("Request Error: Product not found");
      } else if (response.status === 401) {
        // toast.error("Unauthorized: Please log in again");
      } else {
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPendingFun(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.error("Error- " + error.response.data.message);
      }
    }
  };

  const handelDeleteCuppon = (productId) => {
    setDeleteProductId(productId);
    setVisible(true);
  };
  const accept = () => {
    deleteCuppon(deleteProductId, user);
  };
  const reject = () => {
    setDeleteProductId(null);
  };

  return (
    <div className="mt-24 sm:mt-8 md:mt-5 px-5 sm:px-8 md:px-14 min-h-[calc(100vh_-_250px)]">
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to proceed?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
      <div className="flex flex-col sm:flex-row items-center justify-between">
        <div className="w-full mb-3 sm:mb-0 text-2xl text-gray-700 dark:text-gray-50 font-semibold ">
          Coupons
        </div>
        <div className="w-full sm:w-fit flex flex-col sm:flex-row justify-between items-center gap-2">
          {/*<div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search"
              required
            />
  </div>*/}
          <Link className="w-full whitespace-nowrap" to={"addCoupon"}>
            <button
              type="button"
              style={{
                background: currentColor,
              }}
              className="w-full justify-center sm:w-fit focus:outline-none text-white font-medium rounded-lg text-base px-3 py-2.5 hover:opacity-80 flex items-center gap-1"
            >
              <div className="text-xl">
                <MdAdd />
              </div>
              Add Coupon
            </button>
          </Link>
        </div>
      </div>
      {!cupponsPending && cuppons.length <= 0 && (
        <div className="my-5 flex items-center justify-center h-[calc(100vh_-_200px)]">
          <img src={NoDataImg} className="max-h-56 sm:max-h-60" alt="" />
        </div>
      )}
      <div className="my-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
        {cupponsPending &&
          Array.from({ length: 6 }).map((item, i) => (
            <CupponLoadingCard key={i} />
          ))}

        {!cupponsPending &&
          cuppons?.map((item, i) => {
            if (count === 9) {
              count = 0;
            }
            count++;

            return (
              (colors[count - 1] === "green" && (
                <CupponGreenCard
                  cuppon={item}
                  handelDeleteCuppon={handelDeleteCuppon}
                  key={i}
                />
              )) ||
              (colors[count - 1] === "orange" && (
                <CupponOrangeCard
                  cuppon={item}
                  handelDeleteCuppon={handelDeleteCuppon}
                  key={i}
                />
              )) ||
              (colors[count - 1] === "blue" && (
                <CupponBlueCard
                  cuppon={item}
                  handelDeleteCuppon={handelDeleteCuppon}
                  key={i}
                />
              ))
            );
          })}
      </div>
    </div>
  );
};

export default Cuppons;
