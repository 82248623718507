/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import { useForm } from "react-hook-form";

import { useStateContext } from "../contexts/ContextProvider";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useNavigate } from "react-router-dom";


import { TiDeleteOutline } from "react-icons/ti";

const AddBanner = () => {
  const { currentColor } = useStateContext();
  const navigate = useNavigate();
  const { state: ContextState, createBanner } = useContext(ApisContext);

  const { isLoginPending } = ContextState;
  const { state: AuthContextState } = useContext(AuthContext);

  const { user } = AuthContextState;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [formFile, setFormFile] = useState(null);
  const [file, setFile] = useState([]);
  function handleChange(e) {
    setFormFile(e.target.files[0]);
    const newFiles = Array.from(e.target.files);
    const newFileUrls = newFiles.map((newFile) => URL.createObjectURL(newFile));
    setFile((prevFiles) => [...prevFiles, ...newFileUrls]);
  }

  const Submit = async (banner_data) => {
    
    let formData = new FormData(); //formdata object
    formData.append("image", formFile);
    formData.append("title", banner_data.title);
    formData.append("slug", banner_data.slug);
    formData.append("description", banner_data.subTitle);

    createBanner(formData, user, navigate);
  };

  const validateBanner = (value) => {
    if (value === null) {
      return "Please select a category";
    }
    return true;
  };


  const deleteUploadedImage = (index) => {
   
    setFile([]);
}
  return (
    <div className="mt-24 sm:mt-8 md:mt-5 px-5 sm:px-8 md:px-14">
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-5">
        Add Banner
      </div>
      <form onSubmit={handleSubmit((data) => Submit(data))} noValidate>
        <div className="grid gap-6 mb-6 md:grid-cols-2">
          <div>
          {!file.length > 0 ? (
              <div className="items-center justify-center w-full">
                <label
                  htmlFor="dropzone-file"
                  className={`flex flex-col items-center justify-center w-full h-64 border-2  border-dashed rounded-lg cursor-pointer ${
                    errors.image
                      ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500  dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                      : "border-gray-300 bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  }`}
                >
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <svg
                      aria-hidden="true"
                      className="w-10 h-10 mb-3 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 text-center dark:text-gray-400">
                      <span className="font-semibold">
                        Click to upload Category Icon
                        <span className="text-red-600 text-xs">*</span>
                      </span>{" "}
                      <br />
                      or drag and drop
                    </p>
                  </div>
                  <input autoComplete="off"
                    id="dropzone-file"
                    type="file"
                    {...register("image", {
                      required: {
                        value: true,
                        message: "Category icon required",
                      },
                    })}
                    accept="image/*"
                    onChange={handleChange}
                    className="hidden"
                  />
                </label>
                <small className="text-sm text-red-600 dark:text-red-500">
                  {errors.image?.message}
                </small>
              </div>
            ) : (
              <div>
                {file.map((img, index) => {
                  return index === 0 ? (
                    <div key={index} className="max-h-60 flex justify-center relative">
                      <div
                        onClick={() => deleteUploadedImage(index)}
                        className="absolute text-red-600 top-2 right-2 bg-gray-100 cursor-pointer h-7 w-7 rounded-full flex justify-center items-center text-2xl"
                      >
                        <TiDeleteOutline />
                      </div>
                      <img src={img} className="min-h-[150px] min-w-[150px] object-contain" alt="" />
                    </div>
                  ) : null;
                })}
              </div>
            )}
          </div>
          <div>
            <div className="mb-4">
              <label
                htmlFor="title"
                className="block mb-1.5 text-sm font-medium text-gray-900 dark:text-white"
              >
                Title
                <span className="text-red-600 text-xs">*</span>
              </label>
              <input autoComplete="off"
                type="text"
                id="title"
                className={`border text-sm rounded-lg block w-full p-2.5 ${
                  errors.title
                    ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                    : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                } `}
                placeholder="Enter Title"
                {...register("title", {
                  required: {
                    value: true,
                    message: "Title required",
                  },
                })}
              />
              <small className="text-sm text-red-600 dark:text-red-500">
                {errors.title?.message}
              </small>
            </div>
            <div className="mb-4">
              <label
                htmlFor="slug"
                className="block mb-1.5 text-sm font-medium text-gray-900 dark:text-white"
              >
                Slug
                <span className="text-red-600 text-xs">*</span>
              </label>
              <input autoComplete="off"
                type="text"
                id="slug"
                className={`border text-sm rounded-lg block w-full p-2.5 ${
                  errors.slug
                    ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                    : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                } `}
                placeholder="Enter Slug"
                {...register("slug", {
                  required: {
                    value: true,
                    message: "Slug required",
                  },
                })}
              />
              <small className="text-sm text-red-600 dark:text-red-500">
                {errors.slug?.message}
              </small>
            </div>
            <div className="mb-3">
              <label
                htmlFor="subTitle"
                className="block mb-1.5 text-sm font-medium text-gray-900 dark:text-white"
              >
                Sub Title
                <span className="text-red-600 text-xs">*</span>
              </label>
              <textarea
                type="text"
                id="subTitle"
                rows={2}
                className={`border text-sm rounded-lg block w-full p-2.5 ${
                  errors.subTitle
                    ? "bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500 focus-visible:outline-red-600"
                    : "bg-gray-50 border-gray-300 text-gray-900 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                } `}
                placeholder="Enter Sub Title"
                {...register("subTitle", {
                  required: {
                    value: true,
                    message: "Sub Title required",
                  },
                })}
              ></textarea>
              <small className="text-sm text-red-600 dark:text-red-500">
                {errors.subTitle?.message}
              </small>
            </div>
          </div>
        </div>
        <div className="text-right">
          <button
            type="submit"
            style={{
              background: currentColor,
            }}
            className=" text-white focus:outline-none font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center hover:opacity-80"
          >
            Add Banner
          </button>
        </div>

        {/*<div className="mb-6">
          <label
            htmlFor="success"
            className="block mb-2 text-sm font-medium text-green-700 dark:text-green-500"
          >
            Your name
          </label>
          <input
            type="text"
            id="success"
            className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500"
            placeholder="Success input"
            {...register("name", { required: true })}
          />
          <p className="mt-2 text-sm text-green-600 dark:text-green-500">
            <span className="font-medium">Well done!</span> Some success message.
          </p>
</div> 
<div>
  <label
    htmlFor="error"
    className="block mb-2 text-sm font-medium text-red-700 dark:text-red-500"
  >
    Your name
  </label>
  <input
    type="text"
    id="error"
    className="bg-red-50 border border-red-500 text-red-900 placeholder-red-700 text-sm rounded-lg focus:ring-red-500 dark:bg-gray-700 focus:border-red-500 block w-full p-2.5 dark:bg-gray-800 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500"
    placeholder="Error input"
  />
  <p className="mt-2 text-sm text-red-600 dark:text-red-500">
    <span className="font-medium">Oh, snapp!</span> Some error message.
  </p>
</div>
*/}
      </form>
    </div>
  );
};

export default AddBanner;
