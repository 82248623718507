import React, { useEffect, useContext, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useStateContext } from "../contexts/ContextProvider";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const ChangeStatusRequests = () => {
  const BASE_URL = window.config.API_URL;
  const navigate = useNavigate();
  const { changeStatusRequests, setChangeStatusRequests, currentColor } =
    useStateContext();
  const { state: AuthContextState, setUnAuthorizedFun } =
    useContext(AuthContext);
  const { user } = AuthContextState;
  const style = {
    backdropFilter: "blur(1px)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  };
  const { setPendingFun, fetchRequests } = useContext(ApisContext);
  const {
    register,
    handleSubmit,
      formState: { errors },
    setValue
  } = useForm();


 useEffect(() => {
    setValue('changeStatus', changeStatusRequests?.status)
 }, [changeStatusRequests])

  const onSubmit = async (data) => {
    console.log(data);
    const newData = new FormData();
    newData.append('status', data.changeStatus);
    setPendingFun(true);

    try {
      const tokenStr = user.token;

      const response = await axios.post(
        BASE_URL + `request_support/${changeStatusRequests?.id}`,
        newData,
        {
          headers: { Authorization: `Bearer ${tokenStr}` },
        }
      );

      // setPending(false);
      setPendingFun(false);
      if (response.status === 200) {
        if (response.data.Success === true && response.data.data) {
          toast.success("Request status successfully Updated!");
          setChangeStatusRequests(false);
            navigate("/customerssupport");
            fetchRequests(user);
          return response.data;
        } else if (response.data.status === "error") {
          toast.error(response.data.message);
        } else {
          toast.error(response.data.message + "-" + response.data.data);
        }
      } else if (response.status === 404 || response === null) {
        toast.error("Request Error: Request not Updated");
      } else if (response.status === 401) {
        setUnAuthorizedFun(true);
      } else {
        toast.error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      setPendingFun(false);
      if (error.response.status === 401) {
        // toast.error("Error- " + error.response.data.message);
        setUnAuthorizedFun(true);
      } else {
        console.log("Error- ", error.response);
        toast.error("Error- " + error.response.data.message);
      }
    }
  };
  return (
    <div
      id="setTimeModal"
      style={style}
      className="fixed flex justify-center items-center top-1 left-0 right-0 z-50 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[100vh] max-h-full"
    >
      <div className="relative w-full max-w-md max-h-full">
        <div className="bg-white rounded-lg shadow dark:bg-gray-700">
          <button
            type="button"
            onClick={() => setChangeStatusRequests(false)}
            className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <div className="px-6 py-6 lg:px-8">
            <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white">
              Chnage Status
            </h3>
            <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
              {errors.changeStatus && (
                <small className="text-sm text-red-600 dark:text-red-500">
                  Status is required
                </small>
              )}

              <div className="px-4 grid grid-cols-2 gap-3">
                <div className="flex items-center">
                  <input autoComplete="off"
                    id="pending"
                    type="radio"
                    value="Pending"
                    {...register("changeStatus", { required: true })}
                    className="w-4 h-4 text-gary-600 bg-gray-100 border-gray-300 focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="pending"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Pending
                  </label>
                </div>

                <div className="flex items-center">
                  <input autoComplete="off"
                    id="Complete"
                    type="radio"
                    value="Complete"
                    {...register("changeStatus", { required: true })}
                    className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300 focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                  />
                  <label
                    htmlFor="Complete"
                    className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    Complete
                  </label>
                </div>

                
              </div>
             
              <button
                type="submit"
                style={{
                  background: currentColor,
                }}
                className="w-full text-white focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              >
                Save
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeStatusRequests;
