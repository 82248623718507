/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { MdOutlineDelete, MdAdd, MdDeleteOutline } from "react-icons/md";
import { BsFillEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import AddProduct from "./AddProduct";

import { useStateContext } from "../contexts/ContextProvider";
import { ApisContext } from "../contexts/ApiConterxt";
import { AuthContext } from "../contexts/Auth.Context";
import { set } from "react-hook-form";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import NotFoundImg from "../assets/No data-pana.png";

// import { TfiImport, TfiExport } from "react-icons/tfi";

const Banners = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [visible, setVisible] = useState(false);
  const [deleteBannerId, setDeleteBannerId] = useState(null);
  const { currentColor } = useStateContext();
  const {
    state: ContextState,
    getBanners,
    deleteBanner,
  } = useContext(ApisContext);
  const { banners, bannersPending } = ContextState;
  const { state: AuthContextState } = useContext(AuthContext);

  const { user } = AuthContextState;
  // console.log(banners, "categories");
  useEffect(() => {
    getBanners(user);
  }, []);

  // Filter banners based on search query
  const [filteredBanners, setFilteredBanners] = useState([]);
  const filteredBannersFun = () => {
    setFilteredBanners(
      banners?.filter((item) => {
        const title = item?.title?.toLowerCase();
        const description = item?.description?.toLowerCase();
        const query = searchQuery.toLowerCase();

        return title.includes(query) || description.includes(query);
      })
    );
  };
  useEffect(() => {
    filteredBannersFun();
  }, [banners]);
  
  const handelDeleteBanner = (bannerId) => {
    setDeleteBannerId(bannerId);
    setVisible(true);
  };
  const accept = () => {
    deleteBanner(deleteBannerId, user);
  };
  const reject = () => {
    setDeleteBannerId(null);
  };

  return (
    <div className="px-5  sm:px-10 sm:mt-8 mt-24">
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to proceed?"
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
      <div className="text-2xl text-gray-700 dark:text-gray-50 font-semibold mb-3">
        Banners
      </div>
      <div className="bg-white dark:bg-gray-600 dark:border-gray-800 rounded-lg my-4 p-4 border flex flex-col sm:flex-row justify-between items-center gap-2">
        <div className="flex items-center gap-4 w-full sm:w-fit">
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input autoComplete="off"
              type="text"
              id="simple-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
        <div className="flex flex-wrap lg:flex-nowrap items-center gap-3 w-full sm:w-fit">
          <Link className="w-full" to={"addBanner"}>
            <button
              type="button"
              style={{
                background: currentColor,
              }}
              className="w-full justify-center sm:w-fit focus:outline-none text-white font-medium rounded-lg text-base px-3 py-2.5 hover:opacity-80 flex items-center gap-1"
            >
              <div className="text-xl">
                <MdAdd />
              </div>
              Add Banner
            </button>
          </Link>
        </div>
      </div>

      <div className="align-middle inline-block w-full shadow-lg overflow-x-auto rounded-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-sm text-gray-700 uppercase bg-gray-200 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="ps-6 px-3 py-3">
                Banner
              </th>
              <th scope="col" className="px-3 py-3">
                Title
              </th>

              <th scope="col" className="px-3 py-3 whitespace-nowrap">
                Sub Title
              </th>
              {/*<th scope="col" className="px-3 py-3 text-center">
                PUBLISHED
            </th>*/}
              <th scope="col" className="px-3 py-3 pe-5 text-center">
                ACTIONS
              </th>
            </tr>
          </thead>
          <tbody>
            {bannersPending && (
              <>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={8}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center rounded-lg  h-24 w-40 bg-gray-300 dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={8}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center rounded-lg  h-24 w-40 bg-gray-300 dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={8}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center rounded-lg  h-24 w-40 bg-gray-300 dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={8}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center rounded-lg  h-24 w-40 bg-gray-300 dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th
                    colSpan={8}
                    scope="row"
                    className="px-3 ps-6 py-2 font-semibold text-center text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div
                      role="status"
                      className="animate-pulse flex items-center gap-8"
                    >
                      <div className="flex items-center justify-center rounded-lg  h-24 w-40 bg-gray-300 dark:bg-gray-700">
                        <svg
                          className="w-6 h-6 text-gray-200"
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          fill="currentColor"
                          viewBox="0 0 640 512"
                        >
                          <path d="M480 80C480 35.82 515.8 0 560 0C604.2 0 640 35.82 640 80C640 124.2 604.2 160 560 160C515.8 160 480 124.2 480 80zM0 456.1C0 445.6 2.964 435.3 8.551 426.4L225.3 81.01C231.9 70.42 243.5 64 256 64C268.5 64 280.1 70.42 286.8 81.01L412.7 281.7L460.9 202.7C464.1 196.1 472.2 192 480 192C487.8 192 495 196.1 499.1 202.7L631.1 419.1C636.9 428.6 640 439.7 640 450.9C640 484.6 612.6 512 578.9 512H55.91C25.03 512 .0006 486.1 .0006 456.1L0 456.1z" />
                        </svg>
                      </div>
                      <div className="w-1/6">
                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>
                      <div className="w-1/4">
                        <div className="h-2 bg-gray-200 rounded-md dark:bg-gray-700 w-3/6 mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                        <div className="h-1 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2"></div>
                      </div>

                      <span className="sr-only">Loading...</span>
                    </div>
                  </th>
                </tr>
              </>
            )}
            {!bannersPending &&
              filteredBanners?.map((item, index) => {
             
                return (
                  <tr
                    key={index}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  >
                    <td className="ps-6 px-3 py-2 w-52">
                      <div className="rounded-lg flex w-48 border object-cover">
                        <img
                          className="rounded-lg min-h-24 max-h-28 w-48 border object-cover"
                          src={item?.image}
                          alt=""
                        />
                      </div>
                    </td>
                    <td className="px-3 py-4 font-semibold text-gray-900 dark:text-white">
                      {item?.title}
                    </td>
                    <td className="px-3 py-4 font-medium text-gray-900 dark:text-white">
                      {item?.description}
                    </td>

                    {/* <td className="px-3 py-4 text-center ">
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        value=""
                        className="sr-only peer"
                      />
                      <div className="w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-focus:ring-4 peer-focus:ring-green-300 dark:peer-focus:ring-green-800 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-600"></div>
                      
                    </label>
              </td>*/}
                    <td className="px-3 py-4">
                      <div className="text-2xl flex justify-center items-center gap-2 ">
                        <button
                          onClick={() => handelDeleteBanner(item?.id)}
                          className="font-medium text-red-600 dark:text-red-500 cursor-pointer "
                        >
                          <MdOutlineDelete />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            {!bannersPending && !filteredBanners?.length && (
              <tr>
                <td colSpan={5}>
                  <div className="flex h-[calc(100vh_-_250px)] justify-center items-left md:items-center flex-col md:flex-row">
                    <img className="max-h-56 " src={NotFoundImg} alt="" />
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Banners;
